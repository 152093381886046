import * as React from "react";
import PaymentMethods from "./Shipments/PaymentMethods";
import Insurance from "./Shipments/Insurance";
import TransporterSettings from "./Shipments/Transporters";
import ShipmentTime from "./Shipments/ShipmentTime";
import Zones from "./Shipments/Zones";
import ZonePricing from "./Shipments/ZonePricings";
import CustomCities from "./Shipments/CustomCities";

const PriceSettings = () => {
  return <>
    <PaymentMethods />
    <Insurance />
    <TransporterSettings />
    <ShipmentTime />
    <Zones />
    <ZonePricing />
    <CustomCities />
  </>;
};

export default PriceSettings;
