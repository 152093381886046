import React, { useEffect, useState } from "react";
import {
  descriptionExerpt,
  getInitials,
  getRandomColor,
  valueSummation,
  weightSummation,
} from "../Utilities";
import PackagesIcon from "../../../assets/create-shipment/package-page.svg";
import BoxIcon from "../../../assets/create-shipment/box.svg";
import BoxAddIcon from "../../../assets/create-shipment/box_add.svg";
import { BsPencil, BsTrash } from "react-icons/bs";
import AddItemIcon from "../../../assets/create-shipment/add-item.svg";
import AddPackageItemDialog from "../../../modals/AddPackageItemDialog";
import EditPackageItemDialog from "../../../modals/EditPackageItemDialog";
import { Col, Row } from "react-bootstrap";
import { SelectBox, TextBox } from "../../../components/InputFields";
import { getPackagingURL } from "../../../library/URLs";
import axios from "axios";
import { UserProfileData } from "../../../library/constants";
// import countries from "../../../files/countries.json";
// import { toast } from "react-toastify";

const Package = ({
  nextPage,
  previousPage,
  formData,
  setFormData,
  setCarriers,
  countries,
}) => {
  let packages =
    formData.package_type === "Parcel"
      ? formData?.packages
      : formData?.documents;

  const [containerTypes, setContainerTypes] = useState([]);
  const getPackaging = async () => {
    await axios
      .post(getPackagingURL, UserProfileData())
      .then(function (response) {
        console.log(response);
        if (response["data"]["success"] === true) {
          const packaging = response["data"]["data"].map((pack) => {
            return {
              label: pack?.name,
              value: pack?.name,
              length: pack?.length,
              width: pack?.width,
              height: pack?.height,
            };
          });

          setContainerTypes(packaging);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  useEffect(() => {
    getPackaging();
    setFormData({
      ...formData,
      insurance: "No",
      pickup: "No",
      insurance_fee: 0,
      pickup_fee: 0,
    });
    // eslint-disable-next-line
  }, []);

  const [selectedPackage, setSelectedPackage] = useState(0);
  const [addPackageModal, setAddPackageModal] = useState(false);
  const [editPackageModal, setEditPackageModal] = useState(null);
  // const [showProhibitedItems, setShowProhibitedItems] = useState(false);
  // const [showAdditionalServices, setShowAdditionalServices] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState("");

  // useEffect(() => {
  //   setShowProhibitedItems(true);
  // }, []);

  // Handle Package Item modals
  const openAddItem = () => {
    setAddPackageModal(true);
  };

  const closeAddItem = () => {
    setAddPackageModal(false);
  };

  const openEditItem = (id) => {
    setEditPackageModal(id);
  };

  const closeEditItem = () => {
    setEditPackageModal(null);
  };

  // Add New Package
  const handleAddPackage = () => {
    let packagesCopy = [...packages];

    if(formData.package_type === "Parcel") {
      packagesCopy.push({
        number_of_items: 0,
        package_value: 0,
        package_weight: 0,
        package_length: "1",
        package_width: "1",
        package_height: "1",
        description: "Package",
        package_items: [],
      });
    } else {
      packagesCopy.push({
        number_of_items: 0,
        package_value: 0,
        package_weight: 0,
        package_length: "1",
        package_width: "1",
        package_height: "1",
        description: "Document",
        package_items: [],
      });
    }

    let update_package_name = formData.package_type === "Parcel" ? "packages" : "documents";

    setFormData({
      ...formData,
      [update_package_name]: packagesCopy,
    });
    setSelectedPackage(selectedPackage + 1);
  };

  // Remove a Package
  const handleRemovePackage = () => {
    if (packages?.length < 2) {
      return;
    }

    let packagesCopy = [...packages];
    packagesCopy.splice(selectedPackage, 1);

    let update_package_name = formData.package_type === "Parcel" ? "packages" : "documents";

    setFormData({
      ...formData,
      [update_package_name]: packagesCopy,
    });
    setSelectedPackage(selectedPackage - 1);
    window.scrollTo(0, 0);
  };

  // Add new item to package
  const handleAddPackageItem = (packageObj) => {
    let packagesCopy = [...packages];

    let packageItemCopy = { ...packagesCopy[selectedPackage] };

    packageItemCopy.package_items.push(packageObj);
    packageItemCopy.number_of_items += 1;
    packageItemCopy.package_weight = weightSummation(
      packageItemCopy.package_items
    );
    packageItemCopy.package_value = valueSummation(
      packageItemCopy.package_items
    );

    packagesCopy[selectedPackage] = packageItemCopy;
    let update_package_name = formData.package_type === "Parcel" ? "packages" : "documents";

    setFormData({
      ...formData,
      [update_package_name]: packagesCopy,
    });
    closeAddItem();
  };

  // Remove item from package
  const handleRemovePackageItem = (id) => {
    let packagesCopy = [...packages];

    let packageItemCopy = { ...packagesCopy[selectedPackage] };
    let prevWeight = packageItemCopy?.package_items[id]?.weight;
    let prevValue = packageItemCopy?.package_items[id]?.value;

    packageItemCopy.number_of_items -= 1;
    packageItemCopy.package_weight =
      Math.round(
        (packageItemCopy.package_weight - parseFloat(prevWeight)) * 100
      ) / 100;
    packageItemCopy.package_value =
      Math.round(
        (packageItemCopy.package_value - parseFloat(prevValue)) * 100
      ) / 100;

    packagesCopy[selectedPackage] = packageItemCopy;

    packagesCopy[selectedPackage].package_items.splice(id, 1);

    let update_package_name = formData.package_type === "Parcel" ? "packages" : "documents"

    setFormData({
      ...formData,
      [update_package_name]: packagesCopy,
    });
  };

  // Edit package item
  const handleEditPackageItem = (packageObj) => {
    let packagesCopy = [...packages];

    let packageItemCopy = { ...packagesCopy[selectedPackage] };

    packageItemCopy.package_items[editPackageModal] = packageObj;
    packageItemCopy.package_weight = weightSummation(
      packageItemCopy.package_items
    );
    packageItemCopy.package_value = valueSummation(
      packageItemCopy.package_items
    );

    packagesCopy[selectedPackage] = packageItemCopy;

    let update_package_name = formData.package_type === "Parcel" ? "packages" : "documents"

    setFormData({
      ...formData,
      [update_package_name]: packagesCopy,
    });
    closeEditItem();
  };

  const handlePackageDimensionsInput = (e) => {
    if (selectedContainer !== "") {
      setSelectedContainer("");
    }

    const { name, value } = e.target;
    let packagesCopy = [...packages];

    packagesCopy[selectedPackage][name] = value;

    let update_package_name = formData.package_type === "Parcel" ? "packages" : "documents";

    setFormData({
      ...formData,
      [update_package_name]: packagesCopy,
    });
  };

  const handleSavedContainer = (e) => {
    const { value } = e.target;

    let packagesCopy = [...packages];
    let selected_container = containerTypes?.find(
      (container) => container.value === value
    );

    packagesCopy[selectedPackage]["package_length"] = selected_container.length;
    packagesCopy[selectedPackage]["package_height"] = selected_container.height;
    packagesCopy[selectedPackage]["package_width"] = selected_container.width;

    let update_package_name = formData.package_type === "Parcel" ? "packages" : "documents";

    setFormData({
      ...formData,
      [update_package_name]: packagesCopy,
    });
    setSelectedContainer(value);
  };

  const togglePackageType = () => {
    let package_type =
      formData.package_type === "Parcel" ? "Document" : "Parcel";

    setSelectedPackage(0);
    setFormData({
      ...formData,
      package_type,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    nextPage();
    // calculateRates();
  };

  return (
    <div className="create-shipment-grid">
      <AddPackageItemDialog
        isModalVisible={addPackageModal}
        handleCloseDialog={closeAddItem}
        shipment_type="international"
        onSubmit={handleAddPackageItem}
        insurance={formData?.insurance}
        package_type={formData.package_type}
      />
      <EditPackageItemDialog
        isModalVisible={editPackageModal !== null}
        handleCloseDialog={closeEditItem}
        shipment_type="international"
        onSubmit={handleEditPackageItem}
        packageItem={packages[selectedPackage]?.package_items[editPackageModal]}
        insurance={formData?.insurance}
        package_type={formData.package_type}
      />
      <div className="create-shipment-info">
        <img src={PackagesIcon} alt="icon" />
        <div>
          <div className="create-shipment-info-top">
            <p className="create-shipment-info-title">Package Details</p>
            <p className="create-shipment-info-sub">
              What package(s) do you want to send in this shipment?
            </p>
          </div>
        </div>
      </div>
      <div className="create-shipment-info-form">
        <div className="packages-wrapper">
          <div className="mb-5">
            <div className="rates-toggle-bar" onClick={togglePackageType}>
              <div
                className={`rate-toggle-switch ${
                  formData.package_type !== "Parcel" &&
                  "rate-toggle-switch-state"
                }`}
              ></div>
              <div
                className={`toggle-option ${
                  formData.package_type === "Parcel" && "toggle-option-active"
                }`}
              >
                Parcel
              </div>
              <div
                className={`toggle-option ${
                  formData.package_type === "Document" && "toggle-option-active"
                }`}
              >
                Document
              </div>
            </div>
          </div>
          <div className="packages-wrapper-top">
            <div className="packages-wrapper-top-info">
              <div className="packages-wrapper-top-info-title">
                <img src={BoxIcon} alt="box" />
                Packages
              </div>
              <button
                className="packages-wrapper-top-info-btn-add"
                onClick={handleAddPackage}
              >
                <img src={BoxAddIcon} alt="box" />
                Add Package
              </button>
            </div>
            <ul className="packages-wrapper-packages">
              {packages.map((_, id) => (
                <li
                  key={id}
                  className={` packages-wrapper-packages-package ${
                    selectedPackage === id &&
                    "packages-wrapper-packages-package-active"
                  }`}
                  onClick={() => setSelectedPackage(id)}
                >
                  <span className="d-none d-md-block">Package</span>{" "}
                  <span className="d-md-none">Pck</span> {id + 1}
                </li>
              ))}
            </ul>
          </div>
          <ul className="packages-wrapper-items">
            {packages[selectedPackage]?.package_items?.map(
              (package_item, id) => (
                <li
                  key={id}
                  className={`packages-wrapper-items-item ${
                    id + 1 ===
                      packages[selectedPackage]?.package_items?.length &&
                    "border-0"
                  }`}
                >
                  <div className="packages-wrapper-items-item-grid">
                    <div className="packages-wrapper-items-title">
                      <div
                        style={{
                          backgroundColor: getRandomColor(),
                          color: "#FFF",
                        }}
                        className={`packages-wrapper-items-title-initials`}
                      >
                        {getInitials(package_item.category)}
                      </div>
                      <div className="px-5">
                        <p className="packages-wrapper-items-title-category">
                          {package_item.category}{" "}
                        </p>
                        <p className="packages-wrapper-items-title-description">
                          {descriptionExerpt(package_item.description)}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="packages-wrapper-items-quantity-label">
                        QUANTITY
                      </p>
                      <p className="packages-wrapper-items-quantity">
                        {package_item.quantity}
                      </p>
                    </div>
                    <div className="packages-wrapper-items-btn-wrap">
                      <button
                        className="packages-wrapper-items-btn-edit"
                        onClick={() => openEditItem(id)}
                      >
                        <BsPencil />
                      </button>
                      <button
                        className="packages-wrapper-items-btn-delete"
                        onClick={() => handleRemovePackageItem(id)}
                      >
                        <BsTrash />
                      </button>
                    </div>
                  </div>
                </li>
              )
            )}
            <li
              className="packages-wrapper-items-add-item-wrap"
              onClick={openAddItem}
            >
              <div>
                <div className="packages-wrapper-items-add-item-icon">
                  <img src={AddItemIcon} alt="icon" />
                  <p className="packages-wrapper-items-add-item-icon-text">
                    Add Item
                  </p>
                </div>
                <p className="packages-wrapper-items-add-item-msg">
                  Add a new item to your package
                </p>
              </div>
            </li>
          </ul>
          <div className="packages-wrapper-dimensions">
            <p className="packages-wrapper-dimensions-title">
              Package Dimensions{" "}
              <span className="packages-wrapper-dimensions-title-sub">
                [ie container]
              </span>
            </p>
            <div className="packages-wrapper-dimensions-form">
              <Row>
                <Col sm={12} lg={6}>
                  <p className="packages-wrapper-dimensions-label">
                    Saved Dimensions{" "}
                    <span className="packages-wrapper-dimensions-label-sub">
                      [ie container]
                    </span>
                  </p>
                  <SelectBox
                    name="saved_containers"
                    value={selectedContainer}
                    onChange={handleSavedContainer}
                    placeholder="Select from saved containers"
                    options={containerTypes}
                  />
                </Col>
              </Row>
            </div>
            <div>
              <p className="packages-wrapper-dimensions-label">
                New Dimensions{" "}
                <span className="packages-wrapper-dimensions-label-sub">
                  [ie container]
                </span>
              </p>
              <Row>
                <Col sm={12} lg={4}>
                  <label htmlFor="">Height</label>
                  <TextBox
                    name="package_height"
                    value={packages[selectedPackage].package_height}
                    onChange={handlePackageDimensionsInput}
                    prefix="CM"
                    placeholder="Package height"
                    type="tel"
                  />
                </Col>
                <Col sm={12} lg={4}>
                  <label htmlFor="">Length</label>
                  <TextBox
                    name="package_length"
                    value={packages[selectedPackage].package_length}
                    onChange={handlePackageDimensionsInput}
                    prefix="CM"
                    placeholder="Package length"
                    type="tel"
                  />
                </Col>
                <Col sm={12} lg={4}>
                  <label htmlFor="">Breadth</label>
                  <TextBox
                    name="package_width"
                    value={packages[selectedPackage].package_width}
                    onChange={handlePackageDimensionsInput}
                    prefix="CM"
                    placeholder="Package width"
                    type="tel"
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="packages-wrapper-summary">
            <p className="packages-wrapper-summary-title">Package Summary</p>
            <ul>
              <li className="border-b">
                <p className="packages-wrapper-summary-label">Total Quantity</p>
                <p className="packages-wrapper-summary-value">
                  {packages[selectedPackage]?.number_of_items}
                </p>
              </li>
              <li className="border-b">
                <p className="packages-wrapper-summary-label">Total Value</p>
                <p className="packages-wrapper-summary-value">
                  {packages[selectedPackage]?.package_items?.length > 0
                    ? packages[selectedPackage]?.package_items[0]
                        ?.value_currency
                    : "USD"}{" "}
                  {parseFloat(
                    packages[selectedPackage]?.package_value
                  ).toLocaleString("en-US")}
                </p>
              </li>
              <li>
                <p className="packages-wrapper-summary-label">Total Weight</p>
                <p className="packages-wrapper-summary-value">
                  {packages[selectedPackage]?.package_weight} KG
                </p>
              </li>
            </ul>
            <button
              className="packages-wrapper-summary-delete-btn"
              onClick={handleRemovePackage}
            >
              <BsTrash />
              Delete Package
            </button>
          </div>
          <div className="d-flex justify-content-end gap-2">
            <button
              className="create-shipment-info-form-btn-secondary"
              onClick={previousPage}
            >
              Previous
            </button>
            <button
              className="create-shipment-info-form-btn"
              onClick={handleSubmit}
              // disabled={validatePage()}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Package;
