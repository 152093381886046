import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [openAi, setOpenAi] = useState(false);

  const toggleAiPanel = () => {
    setOpenAi(!openAi);
  };

  const openAiPanel = () => {
    setOpenAi(true);
  };

  // Function to close the sidebar
  const closeAiPanel = () => {
    setOpenAi(false);
  };

  // Provide the state and functions to the context
  return (
    <AppContext.Provider value={{ openAi, toggleAiPanel, openAiPanel, closeAiPanel }}>
      {children}
    </AppContext.Provider>
  );
};