import { Modal } from "react-bootstrap";
import axios from "axios";
import { approveShipmentURL, getAllCarriersUrl } from "../library/URLs";
import { useEffect, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { toast } from "react-toastify";
import { SelectBox } from "../components/InputFields";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const ApproveTerminalShipmentDialog = ({
  isModalVisible,
  handleCloseDialog,
  shipmentID,
  fetchShipmentInfo,
  shipmentType,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [switchProcessor, setSwitchProcessor] = useState(false);
  const [allCarriers, setAllCarriers] = useState([]);
  const [processor, setProcessor] = useState();

  useEffect(() => {
    if (isModalVisible) {
      getAllCarriers();
    };
    // eslint-disable-next-line
  }, [isModalVisible]);

  const getAllCarriers = () => {
    axios.post(getAllCarriersUrl, { ...UserProfileData() }).then((response) => {
      if (response["data"]["status"] === false) {
        toast.error(response["data"]["message"]);
        return;
      } else {
        let carriers = response["data"]["data"]
          ?.filter(
            (carrier) =>
              carrier.shipment_type === shipmentType &&
              carrier.status === "Active"
          )
          .map((carrier) => {
            return {
              label: carrier.masked_name,
              value: carrier.id,
            };
          });

        setAllCarriers(carriers);
      }
    });
  };

  const approveShipment = (e) => {
    const formValues = {};
    formValues["shipment_id"] = shipmentID;
    formValues["switch_processor"] = switchProcessor ? "Yes" : "No";

    if(switchProcessor) {
      formValues["shipment_processor"] = processor;
    }

    const newData = { ...formValues, ...UserProfileData() };

    setErrorVisibility(false);
    setIsSubmitting(true);

    axios
      .post(approveShipmentURL, newData)
      .then(function (response) {
        setIsSubmitting(false);

        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          toast.success("Shipment has now been approved for processing");

          setSuccessVisibility(false);
          // fetchShipmentInfo();
          window.location.reload();
          handleCloseDialog();
        } else {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  const handleProcessor = (e) => {
    const { value } = e.target;

    setProcessor(value);
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">APPROVE SHIPMENT</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>You are about to approve this shipment</p>
        <p>This means the shipment would be processed for delivery.</p>
        <p>Would you like to continue?</p>
        <div className="d-flex align-items-center my-3">
          <input
            type="checkbox"
            name=""
            checked={switchProcessor}
            onChange={() => setSwitchProcessor(!switchProcessor)}
            id=""
          />
          Use a different processor?
        </div>
        {switchProcessor && (
          <div className="border-top py-3">
            <label htmlFor="" className="mb-1">
              Please select processor to handle this shipment.
            </label>
            <SelectBox
              value={processor}
              name="processor"
              onChange={handleProcessor}
              options={allCarriers}
              placeholder="Select processor"
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button mx-3" onClick={handleCloseDialog}>
          CANCEL
        </button>
        <SubmitButton
          onClick={approveShipment}
          type="submit"
          disabled={isSubmitting}
          className="btn-primary"
        >
          {isSubmitting === false ? (
            <span className="button-text">APPROVE SHIPMENT</span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ApproveTerminalShipmentDialog;
