import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  getAPICarriersUrl,
  getApiCarrierAccountsUrl,
  addLocalCarrierTypeUrl,
  getAllCustomerGroupsUrl,
  getAllZonesProfileUrl,
  getCarrierShippingPlansUrl,
} from "../library/URLs";
import { useEffect, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import {
  MultiSelectBox,
  SelectBox,
  TextBox,
  TextFieldBox,
  UploadImgField,
  UploadImgField2,
} from "../components/InputFields";
import { BsChevronDown, BsChevronUp, BsX } from "react-icons/bs";
import { SessionManagement } from "../library/SessionManagement";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const AddLocalCarrierType = ({
  isModalVisible,
  handleCloseDialog,
  shipment_type,
}) => {
  const companyLogoURL = SessionManagement.getCompanyLogoURL();
  const companyName = SessionManagement.getCompanyName();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiCarriers, setApiCarriers] = useState([]);
  const [carrierAccounts, setCarrierAccounts] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [customerGroups, setCustomerGroups] = useState([]);
  const [zoneProfiles, setZoneProfiles] = useState([]);
  const [carrierShipOpt, setCarrierShipOpt] = useState([]);
  const [openShippingPlan, setOpenShippingPlan] = useState(null);

  const [carrierForm, setCarrierForm] = useState({
    calculation_connection_type: "",
    api_type: "",
    api_account_id: "",
    transit_time: "",
    display_name: "",
    pricing_type: "",
    zone_profile_id: "",
    additional_info: "",
    visibility_type: "All",
    image_url: companyLogoURL,
    groups: [],
  });
  const [logoFile, setLogoFile] = useState(companyName);

  const getCarrierAccounts = async (carrier_id) => {
    const formValues = {
      ...UserProfileData(),
      carrier: carrier_id,
    };
    setFetching(true);

    await axios
      .post(getApiCarrierAccountsUrl, formValues)
      .then((res) => {
        setFetching(false);
        if (res?.data?.success) {
          let carrier_accounts = res?.data?.data?.map((carrier) => {
            return {
              label: carrier?.name,
              value: carrier?.id,
            };
          });

          setCarrierAccounts(carrier_accounts);
        }
      })
      .catch((err) => {
        setFetching(false);
      });
  };

  const fetchAllZoneProfiles = async () => {
    await axios
      .post(getAllZonesProfileUrl, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          let all_profiles = res?.data?.data?.map((profile) => {
            return {
              label: profile?.name,
              value: profile?.id,
            };
          });
          setZoneProfiles(all_profiles);
        }
      })
      .catch((err) => {});
  };

  const getCustomerGroups = async () => {
    const formValues = {
      ...UserProfileData(),
    };

    await axios
      .post(getAllCustomerGroupsUrl, formValues)
      .then((res) => {
        setFetching(false);
        if (res?.data?.success) {
          let groups = [{ label: "None", value: "" }];

          res?.data?.data?.forEach((group) => {
            groups.push({
              label: group?.name,
              value: group?.id,
            });
          });
          setCustomerGroups(groups);
        }
      })
      .catch((err) => {});
  };

  const fetchApiCarriers = async () => {
    await axios
      .post(getAPICarriersUrl, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          if (shipment_type) {
            let api_carriers = res?.data?.data
              ?.filter(
                (carrier) => carrier[shipment_type?.toLowerCase()] === "Yes"
              )
              ?.map((carrier) => {
                return {
                  label: carrier?.name,
                  value: carrier?.id,
                  img_url: carrier?.image_url,
                  multiple_shipment_plans: carrier.multiple_shipment_plans
                };
              });

            setApiCarriers(api_carriers);
          }
        }
      })
      .catch((err) => {});
  };

  const fetchCarrierShippingPlans = async (api_carrier_id) => {
    const formValues = { ...UserProfileData(), api_carrier_id };

    await axios
      .post(getCarrierShippingPlansUrl, formValues)
      .then((res) => {
        if (res.data.success) {
          let shipping_options = res.data.data
            .filter(
              (ship_plan) => ship_plan[shipment_type?.toLowerCase()] === "Yes"
            )
            ?.map((ship_plan) => {
              return {
                label: ship_plan.name,
                value: ship_plan.name,
              };
            });

          setCarrierShipOpt(shipping_options);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (shipment_type) {
      setCarrierForm({
        ...carrierForm,
        shipment_type,
      });
      fetchApiCarriers();
      getCustomerGroups();
      fetchAllZoneProfiles();
    }
    // eslint-disable-next-line
  }, [shipment_type]);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "api_type") {
      let api_type = apiCarriers?.find((api) => `${api?.value}` === value);
      setCarrierForm({
        ...carrierForm,
        [name]: value,
        image_url: api_type?.img_url,
        multiple_shipment_plans: api_type.multiple_shipment_plans
      });
      setLogoFile(apiCarriers?.find((api) => `${api?.value}` === value)?.label);
      getCarrierAccounts(value);
      fetchCarrierShippingPlans(value);
    } else {
      setCarrierForm({
        ...carrierForm,
        [name]: value,
      });
    }
  };

  const connection_type_options = [
    {
      label: "Using my delivery agents. e.g. riders and drivers",
      value: "Self",
    },
    { label: "Using third party logistics (3PL) APIs ", value: "API" },
  ];

  const onSubmit = (e) => {
    e.preventDefault();

    const formdata = {
      ...UserProfileData(),
      ...carrierForm,
      carrier: carrierForm?.api_type,
      group_id: carrierForm.groups?.map((group) => group.value).join("|"),
    };
    setIsSubmitting(true);

    axios
      .post(addLocalCarrierTypeUrl, formdata)
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.success) {
          window.scrollTo(0, 0);

          setSuccessVisibility(true);
          setSuccessMessage("Carrier added successfully!");

          setTimeout(() => {
            setSuccessVisibility(false);
          }, 3000);
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(res?.data?.message);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const handleUploadLogo = (url) => {
    setCarrierForm({
      ...carrierForm,
      image_url: url,
    });
  };

  const visibility_options = [
    { label: "Admins and Users", value: "All" },
    { label: "Admins only", value: "Admin" },
    { label: "Users only", value: "User" },
  ];

  const pricing_options = [
    { label: "By Distance", value: "Distance" },
    { label: "By Zone", value: "Zone" },
    { label: "Use Flat Fee", value: "Flat Fee" },
  ];

  const handleSelectCustomerGroup = (value) => {
    if (value.length > 0) {
      if (value[value.length - 1]?.value === "") {
        setCarrierForm({
          ...carrierForm,
          groups: [{ label: "None", value: "" }],
        });
      } else {
        setCarrierForm({
          ...carrierForm,
          groups: value.filter((group) => group.value !== ""),
        });
      }
    } else {
      setCarrierForm({
        ...carrierForm,
        groups: value,
      });
    }
  };

  const checkIfMultiCarriers = () => {
    // This function checks if selected API allows multiple carriers.

    // Conditions to be met:
    // 1. Check if calculate connection type is API
    // 2. Check if the selected API returns multiple sub carrier rates.

    let check = false;

    if (carrierForm.calculation_connection_type === "API") {
      if (carrierForm?.multiple_shipment_plans === "Yes") {
        check = true;
      }
    }

    return check;
  };

  const handleMultiPlanInput = (e, id) => {
    // goal: update input fields relating to multi carrier plans
    // steps:
    // 1. get the id, value and name of the focused input.
    // 2. create a copy of the index object within the shipping_plans list.
    // 3. update the object and replace the current list with the modified copied list.

    const { name, value } = e.target;

    let shipping_plans_copy = [...carrierForm?.shipping_plans];

    shipping_plans_copy[id] = {
      ...shipping_plans_copy[id],
      [name]: value,
    };

    setCarrierForm({
      ...carrierForm,
      shipping_plans: [...shipping_plans_copy],
    });
  };

  const handleMultiUploadLogo = (url, id) => {
    // goal: update image for each carrier plans for multi carriers.

    let shipping_plans_copy = [...carrierForm?.shipping_plans];

    let updated_obj = {
      ...shipping_plans_copy[id],
      image_url: url,
    };

    shipping_plans_copy[id] = updated_obj;

    setCarrierForm({
      ...carrierForm,
      shipping_plans: [...shipping_plans_copy],
    });
  };

  const handleSelectCarrierOptions = (value) => {
    // Update selected_shipping_options field which adjusts shipping_plans field.
    // Checks involved:
    // 1. If carrier is removed: remove option with similar name from shipping_plans.
    // 2. If carrier is added: append option to shipping_plans with necessary fields.
    // Steps involved includes:
    // 1. update selected_shipping_options
    // 2. perform check from checks above

    let selected_shipping_options = [];

    // let shipping_plans = [...carrierForm?.shipping_plans];
    value?.forEach((plan) => {
      let current_plan = carrierForm.shipping_plans?.find(
        (opt) => opt["3pl_plan_name"] === plan?.value
      );
      if (current_plan) {
        let update_obj = {
          "3pl_plan_name": current_plan["3pl_plan_name"],
          transit_time: current_plan?.transit_time,
          display_name: current_plan?.display_name,
          additional_info: current_plan?.additional_info,
          image_url: current_plan?.image_url,
          minimum_weight: current_plan?.minimum_weight,
          maximum_weight: current_plan?.maximum_weight,
        };
        selected_shipping_options.push(update_obj);
      } else {
        let update_obj = {
          "3pl_plan_name": plan.value,
          transit_time: "",
          display_name: "",
          additional_info: "",
          image_url: carrierForm.image_url,
          minimum_weight: "",
          maximum_weight: "",
        };
        selected_shipping_options.push(update_obj);
      }
    });

    setCarrierForm({
      ...carrierForm,
      shipping_plans: selected_shipping_options,
      selected_shipping_options: value,
    });
  };

  const handleSelectedShippingPlan = (plan) => {
    if (openShippingPlan === plan) {
      setOpenShippingPlan(null);
    } else {
      setOpenShippingPlan(plan);
    }
  };

  return (
    <Modal size="lg" show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">ADD LOCAL CARRIER</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>Fill the details below to add a carrier type</p>
        <div className="query-result-container">
          <Row className="mb-2 gy-4">
            {/* <Col xs={12} lg={8}>
              <div className="">
                <label htmlFor="Description" className="mb-1">
                  Display Name
                </label>
                <TextBox
                  type="text"
                  name="display_name"
                  value={carrierForm.display_name}
                  onChange={handleInput}
                  placeholder="Enter carrier display name"
                />
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div>
                <label className="mb-1" htmlFor="Description">
                  Transit Time
                </label>
                <TextBox
                  name="transit_time"
                  type="text"
                  value={carrierForm.transit_time}
                  onChange={handleInput}
                  placeholder="E.g: 2 to 3 working days"
                />
              </div>
            </Col> */}
            <Col
              xs={12}
              lg={carrierForm.calculation_connection_type === "Self" ? 6 : 12}
            >
              <div>
                <label htmlFor="Description" className="mb-1">
                  How do you want your shipment booked?
                </label>
                <SelectBox
                  name="calculation_connection_type"
                  value={carrierForm?.calculation_connection_type}
                  options={connection_type_options}
                  onChange={handleInput}
                  placeholder="Enter connection type"
                />
              </div>
            </Col>
            {carrierForm.calculation_connection_type === "Self" && (
              <Col md={6}>
                <div>
                  <label htmlFor="Description" className="mb-1">
                    How do you want to set pricing?
                  </label>
                  <SelectBox
                    name="pricing_type"
                    value={carrierForm?.pricing_type}
                    options={pricing_options}
                    onChange={handleInput}
                    placeholder="Select pricing type"
                  />
                </div>
              </Col>
            )}

            {carrierForm?.pricing_type === "Zone" && (
              <Col xs={12} lg={12}>
                <div className="">
                  <label htmlFor="Description" className="mb-1">
                    Select Zone Profile of your Choice
                  </label>
                  <SelectBox
                    name="zone_profile_id"
                    value={carrierForm.zone_profile_id}
                    options={zoneProfiles}
                    onChange={handleInput}
                    placeholder="Select zone profile"
                  />
                </div>
              </Col>
            )}

            {carrierForm?.calculation_connection_type === "API" && (
              <Col md={6}>
                <div>
                  <label htmlFor="Description" className="mb-1">
                    Select API to use
                  </label>
                  <SelectBox
                    name="api_type"
                    value={carrierForm?.api_type}
                    options={apiCarriers}
                    onChange={handleInput}
                    placeholder="Select API"
                  />
                </div>
              </Col>
            )}
            {carrierForm?.calculation_connection_type === "API" && (
              <Col md={6}>
                <div>
                  <label htmlFor="Description" className="mb-1">
                    Select API account to use
                  </label>
                  <div className="d-flex gap-2 align-items-center">
                    {fetching && (
                      <span className="spinner-border spinner-grow-sm" />
                    )}
                    <SelectBox
                      name="api_account_id"
                      value={carrierForm?.api_account_id}
                      options={carrierAccounts}
                      onChange={handleInput}
                      placeholder="Select api account"
                    />
                  </div>
                </div>
              </Col>
            )}

            {checkIfMultiCarriers() && (
              <Row className="mx-0 p-0 gy-4 my-4">
                <Col xs={12}>
                  <div>
                    <label htmlFor="Description" className="mb-1">
                      Select shipping plans to use
                    </label>
                    <MultiSelectBox
                      name="selected_shipping_options"
                      value={carrierForm?.selected_shipping_options}
                      options={carrierShipOpt}
                      onChange={(value) => handleSelectCarrierOptions(value)}
                      placeholder="Select shipping plan"
                    />
                  </div>
                </Col>
                {carrierForm.shipping_plans?.map((plan, id) => (
                  <div key={id} className="carrier-type-accordion">
                    <div
                      className="carrier-type-header"
                      onClick={() =>
                        handleSelectedShippingPlan(plan["3pl_plan_name"])
                      }
                    >
                      <p className="m-0">{plan["3pl_plan_name"]} </p>
                      {openShippingPlan === plan["3pl_plan_name"] ? (
                        <BsChevronUp />
                      ) : (
                        <BsChevronDown />
                      )}
                    </div>
                    <div
                      className={`carrier-type-body ${
                        openShippingPlan === plan["3pl_plan_name"]
                          ? "carrier-type-body-open"
                          : "carrier-type-body-close"
                      }`}
                    >
                      <Row className="m-0 p-0 gy-4">
                        <Col xs={12} lg={6}>
                          <div className="">
                            <label htmlFor="Description" className="mb-1">
                              Plan Name
                            </label>
                            <TextBox
                              type="text"
                              name="3pl_plan_name"
                              value={plan["3pl_plan_name"]}
                              onChange={(e) => handleMultiPlanInput(e, id)}
                              disabled={true}
                              placeholder="Enter carrier display name"
                            />
                          </div>
                        </Col>

                        <Col xs={12} lg={6}>
                          <div className="">
                            <label htmlFor="Description" className="mb-1">
                              Provide a custom name for this plan
                            </label>
                            <TextBox
                              type="text"
                              name="display_name"
                              value={plan?.display_name}
                              onChange={(e) => handleMultiPlanInput(e, id)}
                              placeholder="Enter carrier display name"
                            />
                          </div>
                        </Col>

                        <Col xs={12} lg={6}>
                          <div>
                            <label className="mb-1" htmlFor="Description">
                              Transit Time
                            </label>
                            <TextBox
                              name="transit_time"
                              type="text"
                              value={plan.transit_time}
                              onChange={(e) => handleMultiPlanInput(e, id)}
                              placeholder="E.g: 2 to 3 working days"
                            />
                          </div>
                        </Col>

                        <Col xs={12} lg={6}>
                          <div className="">
                            <label htmlFor="package img" className="mb-1">
                              Upload Carrier Logo
                            </label>
                            <UploadImgField2
                              handleInput={handleMultiUploadLogo}
                              fileName={plan["3pl_plan_name"]}
                              setFileName={() => {}}
                              imageUrl={plan?.image_url}
                              id={id}
                            />
                          </div>
                        </Col>

                        <Col xs={12}>
                          <div className="mb-2">
                            <label htmlFor="Description" className="mb-1">
                              Additional Information
                            </label>
                            <TextFieldBox
                              name="additional_info"
                              value={plan?.additional_info}
                              onChange={(e) => handleMultiPlanInput(e, id)}
                              placeholder="Enter additional information"
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ))}
              </Row>
            )}

            {/* For Display name and transit name for non multi shipments */}
            {!checkIfMultiCarriers() && (
              <Row className="mt-4 mx-0 p-0">
                <Col xs={12} lg={8}>
                  <div className="">
                    <label htmlFor="Description" className="mb-1">
                      Display Name
                    </label>
                    <TextBox
                      type="text"
                      name="display_name"
                      value={carrierForm.display_name}
                      onChange={handleInput}
                      placeholder="Enter carrier display name"
                    />
                  </div>
                </Col>

                <Col xs={12} lg={4}>
                  <div>
                    <label className="mb-1" htmlFor="Description">
                      Transit Time
                    </label>
                    <TextBox
                      name="transit_time"
                      type="text"
                      value={carrierForm.transit_time}
                      onChange={handleInput}
                      placeholder="E.g: 2 to 3 working days"
                    />
                  </div>
                </Col>
              </Row>
            )}

            {/* Upload logo and provide additional information for non multi shipments */}

            {!checkIfMultiCarriers() && (
              <Row className="mx-0 mb-4 p-0 gy-4">
                <Col xs={12}>
                  <div className="">
                    <label htmlFor="package img" className="mb-0">
                      Upload Carrier Logo
                    </label>
                    <UploadImgField
                      handleInput={handleUploadLogo}
                      fileName={logoFile}
                      setFileName={setLogoFile}
                      imageUrl={carrierForm?.image_url}
                    />
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="mb-2">
                    <label htmlFor="Description" className="mb-1">
                      Additional Information
                    </label>
                    <TextFieldBox
                      name="additional_info"
                      value={carrierForm.additional_info}
                      onChange={handleInput}
                      placeholder="Enter additional information"
                    />
                  </div>
                </Col>
              </Row>
            )}
            <Col md={6}>
              <div>
                <label htmlFor="Description" className="mb-1">
                  Who should see this pricing?
                </label>
                <SelectBox
                  name="visibility_type"
                  value={carrierForm?.visibility_type}
                  options={visibility_options}
                  onChange={handleInput}
                  placeholder="Select options"
                />
              </div>
            </Col>
            <Col xs={6}>
              <div>
                <label htmlFor="Description" className="mb-1">
                  Which group is eligible to this carrier?
                </label>
                <MultiSelectBox
                  name="selected_shipping_options"
                  value={carrierForm?.groups}
                  options={customerGroups}
                  onChange={(value) => handleSelectCustomerGroup(value)}
                  placeholder="Select shipping plan"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton onClick={onSubmit} type="submit" className="btn-primary">
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              Proceed
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddLocalCarrierType;
