import "../../styles/home.scss";
import "../../styles/reports.scss";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserProfileData } from "../../library/constants";
import { getShipmentsURL, getUserReportsURL } from "../../library/URLs";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import dataIcon from "../../images/no-data.png";
import Group from "../../assets/customers/group.svg";
import { SelectBox } from "../../components/InputFields";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import ExportIcon from "../../assets/shipments/file_save.svg";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const CustomerReports = () => {
  const navigator = useNavigate();

  const [reportType, setReportType] = useState("");
  const [reportTime, setReportTime] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const [isOptionSelected, setIsOptionSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [isRetrievingMore, setIsRetrievingMore] = useState(false);

  const [shipments, setShipments] = useState([]);

  const [openExport, setOpenExport] = useState(null);
  const open = Boolean(openExport);
  const handleClickExport = (event) => {
    setOpenExport(event.currentTarget);
  };
  const handleClose = () => {
    setOpenExport(null);
  };

  const data = {
    limit: 1000,
    offset: currentOffset,
    report_type: reportType,
    report_time: reportTime,
    status: "All",
  };

  const newData = { ...data, ...UserProfileData() };

  const getReports = () => {
    if (reportTime !== "" && reportType !== "") {
      setIsLoading(true);
      setCurrentOffset(0);
      setIsOptionSelected(reportType + reportTime);

      getCustomerReports();
    }
  };

  const getCustomerReports = () => {
    axios
      .post(getUserReportsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        setIsRetrievingMore(false);
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const newShipments = response["data"]["data"];

        setShipments(newShipments);
      });
  };

  const onChangeReportType = (event) => {
    setReportType(event.target.value);
    setShipments([]);
  };

  const onChangeReportTime = (event) => {
    setReportTime(event.target.value);
    setShipments([]);
  };

  const handleClick = (customerID) => {
    navigator("/customer/" + customerID);
  };

  const exportToCSV = () => {
    if (shipments.length === 0) return;

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent += "Sender Name,Number of Shipments,Period" + "\r\n";

    shipments.forEach(function (shipment) {
      let row;
      if (reportType === "shipments per customer") {
        row =
          shipment.user +
          "," +
          shipment.shipments_number +
          "," +
          shipment.period;
      } else if (reportType === "revenue per customer") {
        row =
          shipment.user + "," + shipment.shipments_fee + "," + shipment.period;
      }

      csvContent += row + "\r\n";
    });

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "customer-reports.csv");
    document.body.appendChild(link);

    link.click();
  };

  const report_options = [
    { label: "Total Shipments Per Customer", value: "shipments per customer" },
    { label: "Total Revenue Per Customer", value: "revenue per customer" },
  ];

  const report_date_options = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "This Week", value: "this week" },
    { label: "This Month", value: "this month" },
    { label: "Custom Date", value: "custom" },
  ];

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <div className="container shipments-container">
            <div
              className="card border-0 shadow p-4 min-vh-100"
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="d-flex align-items-center mb-4 flex-row gap-2 ">
                <img src={Group} alt="group" />
                <h5 className="mb-2">Analysis Report</h5>
              </div>
              <div className="d-flex gap-3 mb-md-0 flex-column flex-md-row justify-content-between">
                <div className="d-flex flex-column mb-4 flex-md-row gap-3 gap-md-0 gap-1 gap-xl-3">
                  {/* <select
                    onChange={onChangeReportType}
                    name="pickup_detail_id"
                    className="form-select input-query"
                  >
                    <option value="">Choose report</option>
                    <option value="shipments per customer">
                      Total Shipments Per Customer
                    </option>
                    <option value="revenue per customer">
                      Total Revenue Per Customer
                    </option>
                  </select> */}
                  <SelectBox
                    onChange={onChangeReportType}
                    name="pickup_detail_id"
                    style={{ padding: "8px 20px", width: "190px" }}
                    placeholder="Choose Report"
                    options={report_options}
                  />

                  {/* <select
                    onChange={onChangeReportTime}
                    name="pickup_detail_id"
                    className="form-select input-query"
                  >
                    <option value="">Choose date</option>
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="this week">This Week</option>
                    <option value="this month">This Month</option>
                    <option value="custom">Custom Date</option>
                  </select> */}
                  <SelectBox
                    onChange={onChangeReportTime}
                    name="pickup_detail_id"
                    style={{ padding: "8px 20px", width: "190px" }}
                    placeholder="Choose date"
                    options={report_date_options}
                  />

                  <button
                    onClick={getReports}
                    className="confirm-button btn-primary"
                  >
                    {/* <BiIcons.BiSearch style={{fontSize:20}}/> */}
                    {isLoading ? (
                      <LoadingSpinner className="spinner-border spinner-grow-sm" />
                    ) : (
                      "Generate"
                    )}
                  </button>
                </div>

                <div>
                  <button
                    className="feature-btn"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClickExport}
                  >
                    <img src={ExportIcon} alt="export" />
                    Export
                    {open ? <BsChevronUp /> : <BsChevronDown />}
                  </button>
                  <Menu
                    id="basic-menu"
                    anchorEl={openExport}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        exportToCSV();
                        handleClose();
                      }}
                      style={{ fontSize: 12 }}
                    >
                      Export as .csv
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: 12 }}
                      onClick={() => {
                        // exportToXlsx();
                        handleClose();
                      }}
                    >
                      Export as .xlsx
                    </MenuItem>
                  </Menu>
                </div>
              </div>

              <div className="table-contain">
                <table className="shipments-table w-100">
                  <thead>
                    <tr>
                      <th scope="col">Sender</th>
                      <th scope="col">
                        {reportType === "revenue per customer"
                          ? "Amount spent"
                          : "Number of Shipments"}
                      </th>
                      <th scope="col">Period</th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {shipments.map((shipment) => {
                        return (
                          <tr
                            key={shipment.user_id}
                            onClick={() => handleClick(shipment.id)}
                          >
                            <td>{shipment.user}</td>
                            <td>
                              {reportType === "revenue per customer" && "₦"}
                              {reportType === "shipments per customer"
                                ? parseInt(
                                    shipment.shipments_number
                                  ).toLocaleString("en-US")
                                : parseFloat(
                                    shipment.shipments_fee
                                  ).toLocaleString("en-US")}
                            </td>
                            {/*<td>*/}
                            {/*  ₦*/}
                            {/*  {parseFloat(shipment.total_amount).toLocaleString(*/}
                            {/*    "en"*/}
                            {/*  )}*/}
                            {/*</td>*/}
                            <td>{shipment.period}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
              {isOptionSelected !== "" ? null : (
                <LoadingContainer>
                  <div>
                    <img
                      style={{ width: 50, height: 50, marginBottom: 10 }}
                      src={dataIcon}
                      alt=""
                    />
                    <p>No option has been selected</p>
                  </div>
                </LoadingContainer>
              )}
              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerReports;
