import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import SubscriptionInactive from "../components/SubscriptionInactive";
import AdminIcon from "../assets/admins/group.svg";
import { Col, Row } from "react-bootstrap";
import "../styles/wallet.scss";
import ReactApexChart from "react-apexcharts";
import EMVChip from "../assets/wallet/emv-chip.png";
import Pattern from "../assets/wallet/rb_2016.png";
import { FaCopy } from "react-icons/fa";
import EmptyScreen from "../components/EmptyScreen";
import { GoPrimitiveDot } from "react-icons/go";
import moment from "moment";
import Pagination from "../components/Pagination";
import TransactionIcon from "../assets/sidebar/Transactions.svg";
import { UserProfileData } from "../library/constants";
import axios from "axios";
import {
  createOperatorWalletUrl,
  getAppInfoUrl,
  getWalletBalanceUrl,
  getWalletDetailsUrl,
  getWalletTransactionsUrl,
} from "../library/URLs";
import { toast } from "react-toastify";
import LoadingScreen from "../components/LoadingScreen";

const Wallet = () => {
  // State management

  const [totalPages, setTotalPages] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [walletBalance, setWalletBalance] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [walletDetails, setWalletDetails] = useState({
    account_number: "",
    account_name: "",
    bank_name: ""
  });

  const fetchWalletBalance = async () => {
    const formValues = {
      ...UserProfileData(),
    };
    setIsLoading(true);

    await axios
      .post(getWalletBalanceUrl, formValues)
      .then((response) => {
        setIsLoading(false);

        if (response["data"]["successful"] === false) {
          toast.error("Could not fetch wallet balance");
          return;
        }

        if (response["data"]["data"] === false) {
          setWalletBalance("0");
          return;
        }

        setWalletBalance(response["data"]["data"]["balance"]);
      })
      .catch(() => {});
  };

  const fetchWalletDetails = async () => {
    const formValues = {
      ...UserProfileData(),
    };

    await axios
      .post(getWalletDetailsUrl, formValues)
      .then((response) => {

        if (response["data"]["successful"] === false) {
          toast.error("Could not fetch wallet details");
          return;
        }

        if (response["data"]["data"] === false) {
          createOperatorWallet();
          return;
        }

        let details = response["data"]["data"]
      
        setWalletDetails({
          account_number: details?.account_number,
          account_name: details?.account_name,
          bank_name: details?.bank
        });
        
      })
      .catch(() => {});
  };

  const fetchTransactions = async () => {
    const formValues = {
      ...UserProfileData(),
    };
    setTransactionsLoading(true);

    await axios
      .post(getWalletTransactionsUrl, formValues)
      .then((response) => {
        setTransactionsLoading(false);

        if (response["data"]["successful"] === false) {
          toast.error("Could not fetch transactions");
          return;
        }

        setTransactions(response["data"]["data"]);
        setTotalPages(response["data"]["data"]?.length);
      })
      .catch(() => {});
  };

  const getAppInfo = async () => {
    try {
      const formValues = {
        ...UserProfileData(),
      };
  
      const response = await axios.post(getAppInfoUrl, formValues);
      console.log("pfa ", response?.data?.success);
  
      if (!(response.data.success)) {
        toast.error("Failed to get app info");
        return null; // Explicitly return null for error cases
      }
  
      return response.data.data;
    } catch (error) {
      console.log("app info error, ", error);
      toast.error("Could not return app info!");
      return null;
    }
  };

  const createOperatorWallet = async () => {
    const app_info = await getAppInfo();

    const fullname_array = app_info?.contact_person?.split(" ");
    const formValues = {
      ...UserProfileData(),
      first_name: fullname_array[0],
      last_name: fullname_array[1],
      email_address: app_info?.email,
      phone_number: app_info?.support_phone,
      account_type: app_info?.operator_type,
    };

    await axios.post(createOperatorWalletUrl, formValues).then((response) => {
      if (response["data"]["successful"] === false) {
        toast.error(" Could not create account");
        return;
      }

      let details = response["data"]["data"]
      setWalletDetails({
        account_number: details?.account_number,
        account_name: details?.account_name,
        bank_name: details?.bank
      });
      // fetchWalletBalance();
    });
  };

  useEffect(() => {
    fetchWalletBalance();
    fetchTransactions();
    fetchWalletDetails();
    // eslint-disable-next-line
  }, []);

  // End state management
  const series = [
    {
      name: "Amount Spent",
      data: [20000, 10000, 5000, 45000, 12000, 7500, 24000],
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    xaxis: {
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      position: "top",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#0F973D",
            colorTo: "#E7F6EC",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      // labels: {
      //   show: false,
      //   formatter: function (val) {
      //     return val + "%";
      //   }
      // }
    },
    fill: {
      colors: ["#15AB68"],
    },
    title: {
      text: "Total Spent This Week",
      floating: true,
      offsetY: 330,
      align: "center",
      style: {
        color: "#444",
      },
    },
  };

  const paginate = (num) => {
    setCurrentPage(num);
    // navigator(`/transactions?page=${num}`);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
    // navigator(`/transactions?page=${currentPage + 1}`);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
    // navigator(`/transactions?page=${currentPage - 1}`);
  };

  const copyAccountDetails = () => {
    navigator.clipboard.writeText(`
      ${walletDetails?.account_name}
      ${walletDetails?.account_number}
      ${walletDetails?.bank_name}
    `);

    toast.success("Wallet details copied!");
  };

  return (
    <div className="home">
      <Sidebar />

      <div className="main">
        <Navbar />

        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="container-fluid shipments-container min-vh-100">
            <div
              className="card border-0 p-4"
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={AdminIcon} alt="group" />
                  <h5 className="mb-1">Wallet</h5>
                </div>
              </div>
              <Row>
                {isLoading ? (
                  <LoadingScreen />
                ) : (
                  <div className="virtual-card-contain">
                    <div className="">
                      <div className="virtual-card">
                        <img
                          src={Pattern}
                          alt="pattern"
                          className="virtual-card-pattern"
                        />
                        <div className="bg-dark-overlay"></div>
                        <div className="virtual-info">
                          <div className="pt-2">
                            <img
                              src={EMVChip}
                              alt="chip"
                              style={{ width: 50, height: 42 }}
                            />
                          </div>
                          <div className=" pb-2 pt-4">
                            <div>
                              <p
                                className="text-center mb-0"
                                style={{ fontSize: 12 }}
                              >
                                Available Balance
                              </p>
                              <p className="available-balance">
                                {/* Available balance:  */}
                                <span className="available-balance-fee">
                                  ₦{" "}
                                  {parseFloat(
                                    walletBalance
                                  )?.toLocaleString("en-US")}
                                </span>
                              </p>
                              <p className="text-center mb-3">
                                {walletDetails?.account_name ?? "....."}
                              </p>
                              <div className="d-flex justify-content-between align-items-end">
                                <div>
                                  <p>{walletDetails?.account_number ?? "..."} </p>
                                  <p>{walletDetails?.bank_name ?? "..."}</p>
                                </div>
                                <div className="account-copy" onClick={copyAccountDetails}>
                                  <FaCopy /> Copy
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pb-3 pt-3 d-flex gap-3 justify-content-center">
                        <button className="btn-secondary">
                          Top up via Paystack
                        </button>
                        <button className="btn-primary">
                          Top up via Flutterwave
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Row>
              {/* <Row>
                <Col xs={12} lg={8} >
                  <div className=" w-100 p-2 gap-3 g-2 wallet-top-cards">
                    <div className="col-md-6 w-100 card-item col-xl-4">
                      <div className="w-100">
                        <div className="mb-4">
                          <p className="card-title">Total Spent</p>
                          <p className="card-sub">Sum total of revenue spent</p>
                        </div>
                        <div className="d-flex justify-content-center gap-3">
                          <p className="card-data">
                            &#8358; 200, 00
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 w-100 card-item col-xl-4">
                      <div className="w-100">
                        <div className="mb-4">
                          <p className="card-title">Total Funded</p>
                          <p className="card-sub">Sum total of revenue spent</p>
                        </div>
                        <div className="d-flex justify-content-center gap-3">
                          <p className="card-data">
                            &#8358; 200, 00
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 w-100 card-item col-xl-4">
                      <div className="w-100">
                        <div className="mb-4">
                          <p className="card-title">Balance Available</p>
                          <p className="card-sub">Sum total of revenue spent</p>
                        </div>
                        <div className="d-flex justify-content-center gap-3">
                          <p className="card-data">
                            &#8358; 200, 00
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} lg={4} >
                  
                </Col>
              </Row> */}
              {/* <div className='mt-3' >
                <ReactApexChart options={options} series={series} type="bar" height={350} />
              </div> */}
            </div>

            <div
              className="card border-0 p-4 mt-4"
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={TransactionIcon} alt="group" />
                  <h5 className="mb-1">Transactions</h5>
                </div>
              </div>

              {/* Transaction Table */}
              <div className="table-contain w-100">
                {transactionsLoading ? (
                  <LoadingScreen />
                ) : (
                  <table className="shipments-table w-100">
                    <thead>
                      <tr>
                        <th scope="col">Description</th>
                        <th scope="col">Balance Before</th>
                        <th scope="col">Balance After</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Payment Type</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    {
                      <tbody>
                        {transactions
                          .slice((currentPage - 1) * 20, currentPage * 20)
                          ?.map((transaction) => {
                            const dateTime = moment(transaction.date_time);
                            const timestamp =
                              dateTime.format("ddd MMM Do, h:mm A");

                            const color =
                              transaction.type === "Credit" ? "green" : "red";
                            return (
                              <tr key={transaction.id}>
                                <td>{transaction.description}</td>
                                <td>
                                  ₦
                                  {parseFloat(
                                    transaction.balance_before
                                  ).toLocaleString("en")}
                                </td>
                                <td>
                                  ₦
                                  {parseFloat(
                                    transaction.balance_after
                                  ).toLocaleString("en")}
                                </td>
                                <td>
                                  ₦
                                  {parseFloat(
                                    transaction.amount
                                  ).toLocaleString("en")}
                                </td>
                                <td>
                                  <span className="d-flex align-items-center gap-1">
                                    <GoPrimitiveDot style={{ color: color }} />
                                    {transaction.type}
                                  </span>
                                </td>
                                <td>{timestamp}</td>
                              </tr>
                            );
                          })}

                      </tbody>
                    }
                  </table>
                )}

              </div>
              {transactions.length < 1 && !isLoading && (
                  <div className="w-100 d-flex justify-content-center">
                    <EmptyScreen message={"No transaction placed!"} />
                  </div>
                )}

              {!isLoading && transactions.length > 0 && (
                <Pagination
                  total_pages={Math.ceil(transactions?.length / 20)}
                  paginate={paginate}
                  nextPage={nextPage}
                  prevPage={prevPage}
                  current_page={currentPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
