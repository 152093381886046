"../styles/home.scss";
import { useEffect, useState } from "react"
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import FilterIcon from "../assets/reports/Sort.svg";

export const FilterDropdown = ({
  filterOptions01,
  filterSelections01=[],
  handleSelection01,
  filterOptions02=null,
  filterSelections02,
  handleSelection02
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"; // Prevent page scrolling
    } else {
      document.body.style.overflow = ""; // Restore default behavior
    }

    return () => {
      document.body.style.overflow = ""; // Cleanup on unmount
    };
  }, [open]);

  const toggleDropdown = () => {
    setOpen(!open);
  };

  const onSelect01 = (value) => {
    handleSelection01(value);

    // toggleDropdown();
  };

  const onSelect02 = (value) => {
    handleSelection02(value);

    // toggleDropdown();
  };

  return (
    <div className="dropdown-contain" >
      <button
        className="feature-btn"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={toggleDropdown}
      >
        <img src={FilterIcon} alt="export" />
        Filter
        {open ? <BsChevronUp /> : <BsChevronDown />}
      </button>
      {
        open && (
          <div className="dropdown-backdrop" onClick={toggleDropdown} ></div>
        )
      }
      <div className={`${open ? "dropdown-list-active" : "dropdown-list-inactive"}`} >
        <div className="dropdown-list-contain" >
          <ul className="dropdown-list" >
            {
              filterOptions01.map((opt, id) => (
                <li key={id} className="dropdown-list-item" >
                  {opt.label}
                  <input 
                    type="checkbox"
                    checked={filterSelections01.includes(opt.value)}
                    onChange={() => onSelect01(opt.value)}
                  />
                </li>
              ))
            }
          </ul>
          {
            filterOptions02 && (
              <ul className="dropdown-list border-top pt-3 mt-2" >
                {
                  filterOptions02.map((opt, id) => (
                    <li key={id} className="dropdown-list-item" >
                      {opt.label}
                      <input  
                        type="checkbox" 
                        checked={filterSelections02.includes(opt.value)}
                        onChange={() => onSelect02(opt.value)}
                      />
                    </li>
                  ))
                }
              </ul>
            )
          }
        </div>
      </div>
    </div>
  )
}