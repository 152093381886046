import "../styles/home.scss";
import "../styles/single-shipment.scss";
import "../styles/components.scss";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import ShipmentTimeline from "./Widgets/ShipmentTimeline";
import { useEffect, useState } from "react";
import ShipmentInformation from "./Widgets/ShipmentInformation";
import {
  getShipmentDetailsURL,
  getShipmentLevelsUrl,
  getShipmentTrackingDetailsUrl,
} from "../library/URLs";
import axios from "axios";
import { UserProfileData } from "../library/constants";
import {
  ErrorNotification,
  SuccessNotification,
} from "../components/Notifications";
import ShipmentPath from "./Widgets/ShipmentPath";
import DHLShipmentContainer from "./Widgets/DHLShipmentContainer";
import RouteShipmentDialog from "../modals/RouteShipmentDialog";
import CancelShipmentDialog from "../modals/CancelShipmentDialog";
import ReRouteShipmentDialog from "../modals/ReRouteShipmentDialog";
import moment from "moment";
import ApproveTerminalShipmentDialog from "../modals/ApproveTerminalShipmentDialog";
import * as React from "react";
import styled from "styled-components";
import { SessionManagement } from "../library/SessionManagement";
import SubscriptionInactive from "../components/SubscriptionInactive";
import UpdateShipmentStatusDialog from "../modals/UpdateShipmentStatusDialog";
import {
  BsChevronLeft,
  BsChevronRight,
  BsThreeDotsVertical,
} from "react-icons/bs";
import ShipmentIcon from "../assets/single-shipment/overview.svg";
import StatusIcon from "../assets/single-shipment/data-icon.svg";
import SenderIcon from "../assets/single-shipment/person_apron.svg";
import ReceiverIcon from "../assets/single-shipment/person.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Tooltip } from "@mui/material";
import InsureShipmentDialog from "../modals/InsureShipmentDialog";
import { customerAddress } from "../library/utilities";
import AddTrackingDetailsDialog from "../modals/AddTrackingDetailsDialog";
import {
  MenuDropdown,
  MenuDropdownItem,
  MenuOption,
} from "../components/MenuDropdown";
import EditShipmentDetailsDialog from "../modals/EditShipmentDetailsDialog";

const LoadingContainer = styled.div`
  height: 155px;
  text-align: center;
  display: flex;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const SingleShipment = () => {
  const [showApproveShipmentDialog, setShowApproveShipmentDialog] =
    useState(false);

  const [isRetrievingDetails, setIsRetrievingDetails] = useState(true);

  const params = useParams();
  const navigator = useNavigate();
  const shipmentID = params["shipmentID"];

  const [
    isShowRouteShipmentDialogVisible,
    setShowRouteShipmentDialogVisibility,
  ] = useState(false);
  const [
    isShowReRouteShipmentDialogVisible,
    setShowReRouteShipmentDialogVisibility,
  ] = useState(false);
  const [
    isShowCancelShipmentDialogVisible,
    setShowCancelShipmentDialogVisibility,
  ] = useState(false);
  const [
    isShowUpdateShipmentStatusDialogVisible,
    setShowUpdateShipmentStatusDialogVisibility,
  ] = useState(false);

  const [shipmentTime, setShipmentTime] = useState("");
  const [pickupLocation, setPickupLocation] = useState("");
  const [dropoffLocation, setDropoffLocation] = useState("");
  const [senderID, setSenderID] = useState("");
  const [senderType, setSenderType] = useState("");
  const [senderName, setSenderName] = useState("");
  const [senderPhoneNumber, setSenderPhoneNumber] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [status, setStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [statusClass, setStatusClass] = useState("");
  const [description, setDescription] = useState("");
  const [station, setStation] = useState("");
  const [shipmentNumber, setShipmentNumber] = useState("");
  const [shipmentType, setShipmentType] = useState("");
  const [dropoffCoordinates, setDropoffCoordinates] = useState("");
  const [pickupCoordinates, setPickupCoordinates] = useState("");
  const [approvedBy, setApprovedBy] = useState("");
  const [weight, setWeight] = useState("");
  const [riderName, setRiderName] = useState("");
  const [itemValue, setItemValue] = useState("");
  const [itemValueCurrency, setItemValueCurrency] = useState("");
  const [currency, setCurrency] = useState("");
  const [pickupFee, setPickupFee] = useState("0");
  const [requestedPickup, setRequestedPickup] = useState("No");
  const [pickupRequestStatus, setPickupRequestStatus] = useState("Not Picked");
  const [shipmentPlan, setShipmentPlan] = useState("");
  const [isDiscrepancy, setIsDiscrepancy] = useState("");
  const [weightDiscrepancy, setWeightDiscrepancy] = useState("");
  const [insurance, setInsurance] = useState("");
  const [shipmentLevels, setShipmentLevels] = useState([]);
  const [multiLevel, setMultiLevel] = useState("No");
  const [processorCreationType, setProcessorCreationType] = useState("");
  const [transporterStatus, setTransporterStatus] = useState("");
  const [senderInfo, setSenderInfo] = useState({
    country: "",
    state: "",
    city: "",
    postcode: "",
    sender_address_1: "",
    sender_address_2: ""
  });
  const [customerInfo, setCustomerInfo] = useState(null);

  const [receiverInfo, setReceiverInfo] = useState({
    country: "",
    state: "",
    city: "",
    postcode: "",
    receiver_address_1: "",
    receiver_address_2: ""
  });

  const [dhlProcessed, setDHLProcessed] = useState("");
  const [processor, setProcessor] = useState("");
  const [waybillURL, setWaybillURL] = useState("");
  const [invoiceURL, setInvoiceURL] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [openMore, setOpenMore] = useState(null);
  const [insureShipment, setInsureShipment] = useState(false);
  const [trackingNumbers, setTrackingNumbers] = useState([]);
  const [trackDetails, setTrackDetails] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [allowManualStatusUpdate, setAllowManualStatusUpdate] = useState("No");

  const toggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const toggleRouteShipmentDialog = (type) => {
    type === "open"
      ? setShowRouteShipmentDialogVisibility(true)
      : setShowRouteShipmentDialogVisibility(false);
  };

  const toggleReRouteShipmentDialog = (type) => {
    type === "open"
      ? setShowReRouteShipmentDialogVisibility(true)
      : setShowReRouteShipmentDialogVisibility(false);
  };

  const toggleCancelShipmentDialog = (type) => {
    type === "open"
      ? setShowCancelShipmentDialogVisibility(true)
      : setShowCancelShipmentDialogVisibility(false);
  };

  const toggleUpdateShipmentDialog = (type) => {
    type === "open"
      ? setShowUpdateShipmentStatusDialogVisibility(true)
      : setShowUpdateShipmentStatusDialogVisibility(false);
  };

  const openEditDialog = () => {
    setShowEditDialog(true);
  };

  const closeEditDialog = () => {
    setShowEditDialog(false);
  };

  const data = {
    shipment_id: shipmentID,
  };

  const newData = { ...data, ...UserProfileData() };

  const toggleApproveTerminalShipment = (type) => {
    type === "open"
      ? setShowApproveShipmentDialog(true)
      : setShowApproveShipmentDialog(false);
  };

  const goToSender = () => {
    if (senderType === "Customer") {
      navigator("/customer/" + senderID);
    } else if (senderType === "Admin") {
      navigator("/admin/" + senderID);
    }
  };

  const reCreateShipment = () => {
    // navigator("/create-shipment/" + shipmentID);
    navigator(`/create-shipment/?shipmentID=${shipmentID}`);
  };

  const fetchShipmentLevels = () => {
    axios
      .post(getShipmentLevelsUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        const shipmentDetails = response["data"];

        const levels = shipmentDetails["data"];
        setShipmentLevels(levels);
      });
  };

  const fetchTrackingNumbers = () => {
    axios
      .post(getShipmentTrackingDetailsUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response["data"]["success"] === false) return;

        const tracking_numbers = response["data"]["data"];
        setTrackingNumbers(tracking_numbers);
      });
  };

  const fetchShipmentInfo = () => {
    axios
      .post(getShipmentDetailsURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        setIsRetrievingDetails(false);
        if (response["data"]["success"] === false) return;

        const shipmentDetails = response["data"];

        const sender = shipmentDetails["data"]["user_id"];
        const senderType = sender.toString().split("-")[0];
        const senderID = sender.toString().split("-")[1];

        const senderName = shipmentDetails["data"]["sender_name"];
        const senderPhoneNumber = shipmentDetails["data"]["sender_phone_no"];
        const receiverName = shipmentDetails["data"]["receiver_name"];
        const receiverPhoneNumber =
          shipmentDetails["data"]["receiver_phone_no"];
        const amount = shipmentDetails["data"]["total_amount"];
        const shippingCurrency = shipmentDetails["data"]["currency"];
        const paymentMethod = shipmentDetails["data"]["payment_method"];
        const pickupLocation = customerAddress(
          shipmentDetails["data"]["sender_address_1"],
          shipmentDetails["data"]["sender_address_2"],
          shipmentDetails["data"]["sender_city"],
          shipmentDetails["data"]["sender_state"],
          shipmentDetails["data"]["sender_country"],
          shipmentDetails["data"]["sender_postcode"]
        );

        const dropoffLocation = customerAddress(
          shipmentDetails["data"]["receiver_address_1"],
          shipmentDetails["data"]["receiver_address_2"],
          shipmentDetails["data"]["receiver_city"],
          shipmentDetails["data"]["receiver_state"],
          shipmentDetails["data"]["receiver_country"],
          shipmentDetails["data"]["receiver_postcode"]
        );
        const status = shipmentDetails["data"]["status"];
        const paymentStatus = shipmentDetails["data"]["paid_status"];
        const description = shipmentDetails["data"]["item_name"];
        const riderName = shipmentDetails["data"]["rider_name"];
        const itemValue = shipmentDetails["data"]["item_value"];
        const itemValueCurrency =
          shipmentDetails["data"]["item_value_currency"];
        const weight = shipmentDetails["data"]["item_weight"];
        const shipmentStation =
          shipmentDetails["data"]["processing_station_name"];
        const shipmentType = shipmentDetails["data"]["shipment_type"];
        const senderEmail = shipmentDetails["data"]["sender_email"];
        const receiverEmail = shipmentDetails["data"]["receiver_email"];
        const shipmentNumber = shipmentDetails["data"]["shipment_no"];
        const dropoffCoordinates =
          shipmentDetails["data"]["drop_off_latitude"] +
          "," +
          shipmentDetails["data"]["drop_off_longitude"];
        const pickupCoordinates =
          shipmentDetails["data"]["pick_up_latitude"] +
          "," +
          shipmentDetails["data"]["pick_up_longitude"];

        const approvedBy = shipmentDetails["data"]["approved_by"];

        const dhlProcessed = shipmentDetails["data"]["dhl_processed"];
        const processor = shipmentDetails["data"]["processor"];
        const waybillURL = shipmentDetails["data"]["pdf_document"];
        const invoiceURL = shipmentDetails["data"]["invoice_url"];
        const trackingNumber =
          shipmentDetails["data"]["shipment_tracking_number"];

        const timestamp = shipmentDetails["data"]["date_time"];
        const shipmentTime = moment(timestamp).format("ddd MMM Do, h:mm A");
        const requested_pickup = shipmentDetails["data"]["request_pickup"];
        const pickup_fee = shipmentDetails["data"]["pickup_fee"];
        const allow_manual_status_update = shipmentDetails["data"]["allow_manual_status_update"];
        const requested_pickup_status =
          shipmentDetails["data"]["pickup_request_status"];
        const customer_info = shipmentDetails["data"]["shipment_customer"];

        setShipmentTime(shipmentTime);

        const statusClass = status.toString().replaceAll(" ", "");
        setStatusClass(statusClass);

        if (processor === "TerminalAfrica") {
          setPickupCoordinates("6.596413,3.353785");
          setDropoffCoordinates("6.614775,3.356861");
        } else {
          setPickupCoordinates(pickupLocation);
          setDropoffCoordinates(dropoffLocation);
        }

        const is_discrepancy = shipmentDetails["data"]["is_discrepancy"];
        const weight_discrepancy = shipmentDetails["data"]["dhl_weight"];
        const insurance = shipmentDetails["data"]["insurance"];
        const shipmentPlan = shipmentDetails["data"]["shipment_plan"];
        const processorCreationType =
          shipmentDetails["data"]["processor_creation_type"];
        const transporter_status = shipmentDetails["data"]["transporter_status"];

        setSenderID(senderID);
        setSenderType(senderType);
        setSenderName(senderName);
        setSenderPhoneNumber(senderPhoneNumber);
        setReceiverName(receiverName);
        setAmount(amount);
        setReceiverPhoneNumber(receiverPhoneNumber);
        setSenderEmail(senderEmail);
        setReceiverEmail(receiverEmail);
        setPaymentMethod(paymentMethod);

        console.log("Payment status is " + paymentStatus);
        setStatus(status);
        setPaymentStatus(paymentStatus);
        setDescription(description);
        setShipmentType(shipmentType);
        setApprovedBy(approvedBy);
        setPickupLocation(pickupLocation);
        setDropoffLocation(dropoffLocation);
        setShipmentNumber(shipmentNumber);
        setRiderName(riderName);
        setItemValue(itemValue === undefined ? "" : itemValue);
        setItemValueCurrency(
          itemValueCurrency === undefined ? "" : itemValueCurrency
        );
        setWeight(weight === undefined ? "" : weight);
        setDHLProcessed(dhlProcessed);
        setProcessor(processor);
        setWaybillURL(waybillURL);
        setInvoiceURL(invoiceURL);
        setTrackingNumber(trackingNumber);
        setStation(shipmentStation);
        setCurrency(shippingCurrency === undefined ? "" : shippingCurrency);
        setPickupFee(pickup_fee ?? "0");
        setRequestedPickup(requested_pickup);
        setPickupRequestStatus(requested_pickup_status);
        setShipmentPlan(shipmentPlan);
        setIsDiscrepancy(is_discrepancy);
        setWeightDiscrepancy(weight_discrepancy);
        setInsurance(insurance);
        setProcessorCreationType(processorCreationType);
        setSenderInfo({
          country: shipmentDetails["data"]["sender_country"],
          state: shipmentDetails["data"]["sender_state"],
          city: shipmentDetails["data"]["sender_city"],
          postcode: shipmentDetails["data"]["sender_postcode"],
          sender_address_1: shipmentDetails["data"]["sender_address_1"],
          sender_address_2: shipmentDetails["data"]["sender_address_2"]
        });

        setReceiverInfo({
          country: shipmentDetails["data"]["receiver_country"],
          state: shipmentDetails["data"]["receiver_state"],
          city: shipmentDetails["data"]["receiver_city"],
          postcode: shipmentDetails["data"]["receiver_postcode"],
          receiver_address_1: shipmentDetails["data"]["receiver_address_1"],
          receiver_address_2: shipmentDetails["data"]["receiver_address_2"]
        });
        setCustomerInfo(customer_info);
        setTransporterStatus(transporter_status);
        setAllowManualStatusUpdate(allow_manual_status_update);
      });
  };

  useEffect(() => {
    fetchShipmentInfo();
    fetchShipmentLevels();
    fetchTrackingNumbers();
    // eslint-disable-next-line
  }, []);

  const open = Boolean(openMore);
  const handleClickMore = (event) => {
    setOpenMore(event.currentTarget);
  };
  const handleCloseMore = () => {
    setOpenMore(null);
  };

  const openInsureShipment = () => {
    setInsureShipment(true);
  };

  const closeInsureShipment = () => {
    setInsureShipment(false);
  };

  const openAddTrackDetails = () => {
    setTrackDetails(true);
  };

  const closeAddTrackDetails = () => {
    setTrackDetails(false);
  };

  const fetchInfos = () => {
    fetchShipmentInfo();
    fetchTrackingNumbers();
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <ApproveTerminalShipmentDialog
            shipmentID={shipmentID}
            isModalVisible={showApproveShipmentDialog}
            handleCloseDialog={(e) => toggleApproveTerminalShipment("close")}
            fetchShipmentInfo={fetchInfos}
            shipmentType={shipmentType}
          />

          <RouteShipmentDialog
            shipmentID={shipmentID}
            multiLevel={multiLevel}
            isModalVisible={isShowRouteShipmentDialogVisible}
            handleCloseDialog={(e) => toggleRouteShipmentDialog("close")}
          />

          <ReRouteShipmentDialog
            shipmentID={shipmentID}
            isModalVisible={isShowReRouteShipmentDialogVisible}
            handleCloseDialog={(e) => toggleReRouteShipmentDialog("close")}
          />

          <CancelShipmentDialog
            shipmentID={shipmentID}
            isModalVisible={isShowCancelShipmentDialogVisible}
            handleCloseDialog={(e) => toggleCancelShipmentDialog("close")}
            fetchShipmentInfo={fetchShipmentInfo}
          />

          <UpdateShipmentStatusDialog
            shipmentID={shipmentID}
            isModalVisible={isShowUpdateShipmentStatusDialogVisible}
            handleCloseDialog={(e) => toggleUpdateShipmentDialog("close")}
            fetchShipmentInfo={fetchShipmentInfo}
            status={status}
          />

          <InsureShipmentDialog
            isModalVisible={insureShipment}
            handleCloseDialog={closeInsureShipment}
            shipment_id={shipmentID}
            shipment_type={shipmentType}
            item_value={itemValue}
            reloadShipment={fetchShipmentInfo}
            currency={itemValueCurrency}
            waybillURL={trackingNumbers[0]?.waybill_document_url}
          />

          <AddTrackingDetailsDialog
            isModalVisible={trackDetails}
            handleCloseDialog={closeAddTrackDetails}
            shipmentID={shipmentID}
            reload={fetchTrackingNumbers}
          />

          <EditShipmentDetailsDialog
            shipment_number={shipmentNumber}
            isModalVisible={showEditDialog}
            handleCloseDialog={closeEditDialog}
            sender_name={senderName}
            sender_email={senderEmail}
            sender_phone={senderPhoneNumber}
            receiver_name={receiverName}
            receiver_email={receiverEmail}
            receiver_phone={receiverPhoneNumber}
            sender_country={senderInfo.country}
            sender_state={senderInfo.state}
            sender_city={senderInfo.city}
            sender_postcode={senderInfo.postcode}
            receiver_country={receiverInfo.country}
            receiver_state={receiverInfo.state}
            receiver_city={receiverInfo.city}
            receiver_postcode={receiverInfo.postcode}
            sender_address_1={senderInfo.sender_address_1}
            sender_address_2={senderInfo.sender_address_2}
            receiver_address_1={receiverInfo.receiver_address_1}
            receiver_address_2={receiverInfo.receiver_address_2}
          />

          <div className="container-fluid">
            <div className="navigation-contain">
              <button className="back-btn" onClick={() => navigator(-1)}>
                <BsChevronLeft />
              </button>
              <ul className="nav-options">
                <li>Shipments</li>
                <li className="nav-options-arrow">
                  <BsChevronRight />{" "}
                </li>
                <li className="nav-options-active">Shipment Details</li>
              </ul>
            </div>
            {/* Status Info Section */}
            <div className="shipment-section-wrap">
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={ShipmentIcon} alt="group" />
                  <h5 className="m-0">Shipment Detail</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3">
                  <Tooltip title="More Options" placement="top">
                    <MenuDropdown toggledropdown={toggleDropdown}>
                      <button
                        className="more-btn"
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClickMore}
                      >
                        <BsThreeDotsVertical />
                      </button>
                      <MenuOption
                        open={openDropdown}
                        toggledropdown={toggleDropdown}
                      >
                        <MenuDropdownItem
                          onClick={() => {
                            setOpenDropdown(false);
                            reCreateShipment();
                          }}
                        >
                          Recreate Shipment
                        </MenuDropdownItem>
                        {(status === "Pending Approval") && (
                          <MenuDropdownItem
                            onClick={() => {
                              setOpenDropdown(false);
                              openEditDialog();
                            }}
                          >
                            Edit Shipment
                          </MenuDropdownItem>
                        )}
                        {status !== "Cancelled" &&
                          status !== "Pending Approval" && (
                            <MenuDropdownItem
                              onClick={() => {
                                setOpenDropdown(false);
                                openInsureShipment();
                              }}
                            >
                              Insure Shipment
                            </MenuDropdownItem>
                          )}
                        <MenuDropdownItem
                          onClick={() => {
                            setOpenDropdown(false);
                            openAddTrackDetails();
                          }}
                        >
                          Add Tracking Details
                        </MenuDropdownItem>
                      </MenuOption>
                    </MenuDropdown>
                  </Tooltip>
                </div>
              </div>
              <div className="status-contain">
                <div className="status-icon">
                  <img src={StatusIcon} alt="icon" />
                </div>
                <div className="d-flex justify-content-between align-items-end">
                  <div>
                    <p className="status-text">
                      Shipment ID{" "}
                      <span className="status-text-bold status-text-bold-two">
                        {shipmentNumber}
                      </span>
                    </p>
                    <p className="status-text">
                      Booking Date{" "}
                      <span className="status-text-bold">{shipmentTime}</span>
                    </p>
                  </div>
                  <div className={`status ${statusClass}`}>{status}</div>
                </div>
              </div>

              <div className="d-flex flex-row mx-3 my-4 justify-content-between">
                {status === "Pending Approval" ? (
                  shipmentLevels?.length > 1 ? (
                    shipmentLevels[0]?.shipment_type === "Local" &&
                    shipmentLevels[0]?.approved === "No" ? (
                      <div className="d-flex gap-2 me-2">
                        <button
                          className="btn-primary"
                          onClick={(e) => {
                            toggleRouteShipmentDialog("open");
                            setMultiLevel("Yes");
                          }}
                        >
                          Route Shipment
                        </button>

                        {status === "Pending Approval" && (
                          <button
                            className="btn-primary"
                            onClick={(e) =>
                              toggleApproveTerminalShipment("open")
                            }
                          >
                            Approve Shipment
                          </button>
                        )}
                        
                        {status !== "Cancelled" && status !== "Delivered" && (
                          <button
                            onClick={(e) => toggleCancelShipmentDialog("open")}
                            className="btn-error"
                          >
                            Cancel Shipment
                          </button>
                        )}
                      </div>
                    ) : (
                      <div className="d-flex gap-2 me-2">
                        {shipmentLevels[0]?.shipment_type === "Local" && (
                          <button
                            className="btn-primary"
                            onClick={(e) => toggleReRouteShipmentDialog("open")}
                          >
                            Re-route Shipment
                          </button>
                        )}
                        {status === "Pending Approval" && (
                          <button
                            className="btn-primary"
                            onClick={(e) =>
                              toggleApproveTerminalShipment("open")
                            }
                          >
                            Approve Shipment
                          </button>
                        )}
                        {status !== "Cancelled" && status !== "Delivered" && (
                          <button
                            onClick={(e) => toggleCancelShipmentDialog("open")}
                            className="btn-error"
                          >
                            Cancel Shipment
                          </button>
                        )}
                      </div>
                    )
                  ) : shipmentType === "Local" &&
                    (processor === "Self" || processor === "") ? (
                    <div className="d-flex gap-2 me-2">
                      <button
                        className="btn-primary"
                        onClick={(e) => toggleRouteShipmentDialog("open")}
                      >
                        Route Shipment
                      </button>
                      {status !== "Cancelled" && status !== "Delivered" && (
                        <button
                          onClick={(e) => toggleCancelShipmentDialog("open")}
                          className="btn-error"
                        >
                          Cancel Shipment
                        </button>
                      )}
                    </div>
                  ) : shipmentType !== "Local" ||
                    processorCreationType === "API" ? (
                    <div className="d-flex gap-2 me-2">
                      <button
                        className="btn-primary"
                        onClick={(e) => toggleApproveTerminalShipment("open")}
                      >
                        Approve Shipment
                      </button>
                      {status !== "Cancelled" && status !== "Delivered" && (
                        <button
                          onClick={(e) => toggleCancelShipmentDialog("open")}
                          className="btn-error"
                        >
                          Cancel Shipment
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="d-flex gap-2 me-2">
                      <button
                        className="btn-primary"
                        onClick={(e) => toggleRouteShipmentDialog("open")}
                      >
                        Route Shipment
                      </button>
                      {status !== "Cancelled" && status !== "Delivered" && (
                        <button
                          onClick={(e) => toggleCancelShipmentDialog("open")}
                          className="btn-error"
                        >
                          Cancel Shipment
                        </button>
                      )}
                    </div>
                  )
                ) : (processor === "Self" || processor === "") &&
                  (transporterStatus === "Pending Pickup" ||
                    transporterStatus === "Going To Pickup" ||
                    transporterStatus === "Picked Up" ||
                    transporterStatus === "Going To Deliver") ? (
                  <div className="d-flex gap-2 me-2">
                    <button
                      style={{ marginRight: 15 }}
                      className="btn-primary"
                      onClick={(e) => toggleReRouteShipmentDialog("open")}
                    >
                      Re-route Shipment
                    </button>
                    {status !== "Cancelled" && status !== "Delivered" && (
                      <button
                        onClick={(e) => toggleCancelShipmentDialog("open")}
                        className="btn-error"
                      >
                        Cancel Shipment
                      </button>
                    )}
                  </div>
                ) : status !== "Cancelled" && status !== "Delivered" ? (
                  <button
                    onClick={(e) => toggleCancelShipmentDialog("open")}
                    className="btn-error me-2"
                  >
                    Cancel Shipment
                  </button>
                ) : (
                  <div></div>
                )}

                <div className="d-flex flex-row align-items-md-center gap-3">
                  {allowManualStatusUpdate === "Yes" &&
                    status !== "Delivered" && (
                      <button
                        style={{ marginLeft: 15 }}
                        onClick={(e) => toggleUpdateShipmentDialog("open")}
                        className="btn-primary"
                      >
                        Update Status
                      </button>
                    )}
                </div>
              </div>
            </div>

            {/* Address Information */}
            <div className="shipment-section-wrap">
              <div className="row mt-4">
                <div className="col-xl-6 col-md-12">
                  {isRetrievingDetails === true ? (
                    <LoadingContainer>
                      <LoadingSpinner className="spinner-border spinner-grow-sm" />
                    </LoadingContainer>
                  ) : (
                    <div className="section-wrap-secondary">
                      <div className="top-banner">
                        <img src={SenderIcon} alt="icon" />
                        <p className="m-0">Sender Details</p>
                      </div>
                      <div className="shipper-container">
                        <ul>
                          <li>
                            <div>
                              <p>Name</p>
                            </div>
                            <div>
                              <p
                              // onClick={(e) => goToSender()}
                              >
                                {senderName}
                              </p>
                            </div>
                          </li>
                          <li>
                            <div>
                              <p>Phone Number</p>
                            </div>
                            <div>
                              <p>{senderPhoneNumber}</p>
                            </div>
                          </li>
                          <li>
                            <div>
                              <p>Email</p>
                            </div>
                            <div>
                              <p>{senderEmail}</p>
                            </div>
                          </li>
                          <li>
                            <div>
                              <p>Pickup Location</p>
                            </div>
                            <div>
                              <p>{pickupLocation}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-xl-6 col-md-12">
                  {isRetrievingDetails === true ? (
                    <LoadingContainer>
                      <LoadingSpinner className="spinner-border spinner-grow-sm" />
                    </LoadingContainer>
                  ) : (
                    <div className="section-wrap-secondary">
                      <div className="top-banner">
                        <img src={ReceiverIcon} alt="icon" />
                        <p className="m-0">Receiver Details</p>
                      </div>
                      <div className="shipper-container">
                        <ul>
                          <li>
                            <div>
                              <p>Name</p>
                            </div>
                            <div>
                              <p
                              // onClick={(e) => goToSender()}
                              >
                                {receiverName}
                              </p>
                            </div>
                          </li>
                          <li>
                            <div>
                              <p>Phone Number</p>
                            </div>
                            <div>
                              <p>{receiverPhoneNumber}</p>
                            </div>
                          </li>
                          <li>
                            <div>
                              <p>Email</p>
                            </div>
                            <div>
                              <p>{receiverEmail}</p>
                            </div>
                          </li>
                          <li>
                            <div>
                              <p>Dropoff Location</p>
                            </div>
                            <div>
                              <p>{dropoffLocation}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-4 gy-5">
                <div className="col-md-12">
                  <ShipmentInformation
                    isRetrieving={isRetrievingDetails}
                    amount={amount}
                    currency={currency}
                    paymentStatus={paymentStatus}
                    description={description}
                    approvedBy={approvedBy}
                    station={station}
                    weight={weight}
                    processor={processor}
                    itemValue={itemValue}
                    itemValueCurrency={itemValueCurrency}
                    paymentMethod={paymentMethod}
                    shipmentID={shipmentID}
                    shipmentNumber={shipmentNumber}
                    pickupFee={pickupFee}
                    requestedPickup={requestedPickup}
                    pickupRequestStatus={pickupRequestStatus}
                    shipmentType={shipmentType}
                    shipmentPlan={shipmentPlan}
                    isDiscrepancy={isDiscrepancy}
                    weightDiscrepancy={weightDiscrepancy}
                    insurance={insurance}
                    customerInfo={customerInfo}
                    riderName={riderName}
                    transporterStatus={transporterStatus}
                  />
                </div>
                <div className="col-xl-6 col-md-12">
                  {
                    // <ShipmentPath
                    //     pickupCoordinates={pickupCoordinates}
                    //     dropoffCoordinates={dropoffCoordinates}
                    // />
                    <DHLShipmentContainer
                      processor={processor}
                      dhlProcessed={dhlProcessed}
                      waybillURL={waybillURL}
                      trackingNumber={trackingNumber}
                      trackingNumbers={trackingNumbers}
                      invoiceURL={invoiceURL}
                      status={status}
                    />
                  }
                </div>
                <ShipmentTimeline shipmentID={shipmentID} />
              </div>
            </div>

            {/* Timeline */}
            {/* <div className="shipment-section-wrap">
              <div className="row mt-4">
                <div className="col-md-6"></div>
                <ShipmentTimeline shipmentID={shipmentID} />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleShipment;
