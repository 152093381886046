import "../../styles/login.scss";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { generateTransactionID } from "../../library/utilities";
import axios from "axios";
import {
  getStates2Url,
  // loginURL,
  // registerURL,
  getStatesURL,
  validateRegisterFormUrl,
} from "../../library/URLs";
// import { SessionManagement } from "../../library/SessionManagement";
import { ErrorNotification } from "../../components/Notifications";
import ParcelflowLogo from "../../assets/Parcelflow-Logo.svg";
import AccountDetailsIcon from "../../assets/auth/details.svg";
import ServicesIcon from "../../assets/auth/services.svg";
import OperationsIcon from "../../assets/auth/operations.svg";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import countries from "../../files/countries.json";
import { UserProfileData } from "../../library/constants";
import OtpInput from "react-otp-input";

const PageHeader = styled.h4`
  font-family: Prompt, sans-serif;
  font-size: 32px;
  line-height: 28.8px;
  margin-bottom: 8px;
  color: #000;

  @media (max-width: 556px) {
    font-size: 2.4rem;
  }
`;

const PageSubheader = styled.h4`
  font-size: 14px;
  color: #667185;
  margin-bottom: 48px;
  font-weight: normal;
`;
const VerifyOTP = ({ prevPage, nextPage }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otp, setOtp] = useState("");
  const [formData, setFormData] = useState({
    phone: "",
    password: "",
    confirm_password: "",
    state: "",
    country: "",
  });

  const [isError, setErrorVisibility] = useState(false);
  const [captchToken, setCaptchaToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const form = useRef(null);

  const clearErrorMessage = () => {
    setErrorVisibility(false);
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    clearErrorMessage();
    if (formData.password !== formData.confirm_password) {
      setErrorMessage("Ensure your password matches confirm password!");
      setErrorVisibility(true);
      window.scrollTo(0, 0);
      return;
    }

    const formValues = {
      email: formData.email,
      phone_number: formData.phone,
      token: captchToken,
      transaction_id: generateTransactionID(15),
    };
    // localStorage.setItem("register_company", JSON.stringify(formValues))

    setIsSubmitting(true);

    nextPage();
    // console.log("register log ", formData);
  };

  const country_options = countries?.map((cntry) => {
    return {
      label: cntry?.name,
      value: cntry?.name,
    };
  });

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePhoneInput = (code) => {
    setFormData({
      ...formData,
      phone: code,
    });
  };

  return (
    <div>
      <div className="Auth-Wrap">
        <div className="Auth-Left"></div>
        <div className="Auth-Left-Fixed">
          <div>
            {/* <div className="logo-contain mb-4">
                <img src={ParcelflowLogo} alt="logo" />
              </div> */}
            <div className="auth-track-contain">
              <div className="progress-row">
                <div className="d-flex flex-column align-items-center">
                  <div className="progress-icon progress-icon-active">
                    <img src={AccountDetailsIcon} alt="icon" />
                  </div>
                  <div className="progress-line progress-line-active"></div>
                </div>
                <div className="progress-detail">
                  <p className="progress-detail-title">Account details</p>
                  <p className="progress-detail-desc">
                    Provide your details to begin account creation.
                  </p>
                </div>
              </div>

              <div className="progress-row">
                <div className="d-flex flex-column align-items-center">
                  <div className="progress-icon">
                    <img src={ServicesIcon} alt="icon" />
                  </div>
                  <div className="progress-line"></div>
                </div>
                <div className="progress-detail">
                  <p className="progress-detail-title">Interested service(s)</p>
                  <p className="progress-detail-desc">
                    Which of Parcelflow&apos;s service(s) interests you?
                  </p>
                </div>
              </div>

              <div className="progress-row">
                <div className="d-flex flex-column align-items-center">
                  <div className="progress-icon">
                    <img src={OperationsIcon} alt="icon" />
                  </div>
                </div>
                <div className="progress-detail">
                  <p className="progress-detail-title">Operations</p>
                  <p className="progress-detail-desc">
                    Further information on services.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="logo-contain">
              <p className="text-redirect">
                Already have an account? <Link to="/">Log In</Link>
              </p>
            </div> */}
        </div>
        <div className="Auth-Right">
          <div className="Auth-form">
            {isError === true ? (
              <ErrorNotification message={errorMessage}>
                This is a notification
              </ErrorNotification>
            ) : null}

            <div className="pb-5" >
              <PageHeader className="page-header feature-header">
                Verify Phone Number
              </PageHeader>
              <PageSubheader>
                Provide the code sent to your registered whatsapp phone number.
              </PageSubheader>
              <div>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  inputStyle={"inputField"}
                  containerStyle={"enclose"}
                  renderSeparator={<span className="ml-4"></span>}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
            </div>
            <div className="d-flex pt-5 flex-row my-5 gap-4 justify-content-end">
              <button
                type="button"
                className="btn-secondary"
                onClick={prevPage}
              >
                <BsChevronLeft />
              </button>
              <button
                type="submit"
                onClick={handleSubmit}
                className="login-button btn-primary rounded"
              >
                {isSubmitting === false ? (
                  <span className="button-text d-flex align-items-center justify-content-center">
                    Proceed <BsChevronRight />
                  </span>
                ) : (
                  <span className="spinner-border spinner-grow-sm" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOTP;
