import { Modal } from "react-bootstrap";
import {BiX} from "react-icons/bi";
import * as React from "react";


const PackageItemsDialog = ({ packages, isModalVisible, handleCloseDialog }) => {

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog} size="lg">
      <Modal.Header>
        <h5 style={{marginTop:10}} className="header">LIST OF PACKAGE ITEMS</h5>
          <BiX style={{fontSize:25, cursor:"pointer"}} onClick={handleCloseDialog}/>
      </Modal.Header>
      <Modal.Body>

        <div className="state-dialog-body">
          <ul className="order-item-list" >
            <li className="order-item-row" >
                <div className="order-item-grid" >
                    <p className="order-item-header first-order" >
                        Name
                    </p>
                    <p className="order-item-header first-order" >
                        Description
                    </p>
                    <p className="order-item-header" >
                        Weight
                    </p>
                    <p className="order-item-header" >
                        Quantity
                    </p>
                    <p className="order-item-header" >
                        Unit Value
                    </p>
                </div>
            </li>
            {
                packages?.length < 1 ? (
                    <p className="text-center py-4" >No items available</p>
                ) : packages.map((packageItem, id) => (
                    <li key={id} className="order-item-row w-100" >
                        <div className="order-item-grid">
                            <p className="order-item-body first-order">
                                {packageItem.name}
                            </p>
                            <p className="order-item-body  first-order" >
                                {packageItem.description}
                            </p>
                            <p className="order-item-body " >
                                {packageItem.weight}KG
                            </p>
                            <p className="order-item-body " >
                                {packageItem.quantity}
                            </p>
                            <p className="order-item-body ps-1">
                                {packageItem.value ? 
                                <span> {packageItem?.value_currency==="USD"?"$":"₦"}{packageItem.value}</span> : "Nil"}
                            </p>
                        </div>
                    </li>
                ))
            }
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PackageItemsDialog;
