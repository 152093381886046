import React, { useEffect, useState } from "react";
import { BsChevronDoubleRight } from "react-icons/bs";
import SenderIcon from "../../../assets/create-shipment/sender.svg";
import SenderActiveIcon from "../../../assets/create-shipment/sender-active.svg";
import ReceiverIcon from "../../../assets/create-shipment/receiver.svg";
import ReceiverActiveIcon from "../../../assets/create-shipment/receiver-active.svg";
import PackageIcon from "../../../assets/create-shipment/package.svg";
import PackageActiveIcon from "../../../assets/create-shipment/package-active.svg";
import PaymentIcon from "../../../assets/create-shipment/payment.svg";
import PaymentActiveIcon from "../../../assets/create-shipment/payment-active.svg";
import ConfirmIcon from "../../../assets/create-shipment/confirm.svg";
import ConfirmActiveIcon from "../../../assets/create-shipment/confirm-active.svg";
import Sender from "./Sender";
import Receiver from "./Receiver";
import Package from "./Package";
import Payment from "./Payment";
import Confirm from "./Confirm";
import countries from "../../../files/countries.json";
import Pricing from "./Pricing";

const InterstateShipment = ({ shipmentInfo }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [carriers, setCarriers] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const [formData, setFormData] = useState({
    sender_details: {
      fullname: "",
      email: "",
      phone: "",
      address_1: "",
      address_2: "",
      country: "Nigeria",
      state: "",
      city: "",
      postal_code: "",
      map_locator: "",
      coordinates: "",
      selected_address: ""
    },
    receiver_details: {
      fullname: "",
      email: "",
      phone: "",
      address_1: "",
      address_2: "",
      country: "Nigeria",
      state: "",
      city: "",
      postal_code: "",
      map_locator: "",
      coordinates: "",
      selected_address: ""
    },
    package_type: "Parcel",
    packages: [
      {
        number_of_items: 0,
        package_value: 0,
        package_weight: 0,
        package_length: "1",
        package_width: "1",
        package_height: "1",
        description: "Package",
        package_items: []
      }
    ],
    use_customer_account: "No",
    carrier_detail: {},
    amount_pickup: "",
    amount: "",
    cost_price: "",
    processor: "",
    pickup: "No",
    customer_wallet_id: "",
    pickup_fee: 0,
    insurance_fee: 0,
    additional_services: [],
    insurance: "No",
    payment_method: "",
    transporter: "",
    term: false
  });

  useEffect(() => {

    if(shipmentInfo) {
      let sender_country = shipmentInfo?.sender_country?.length < 3 ? 
      countries?.find(country => country.code === shipmentInfo?.sender_country)?.name : shipmentInfo?.sender_country;

      let receiver_country = shipmentInfo?.receiver_country?.length < 3 ? 
      countries?.find(country => country.code === shipmentInfo?.receiver_country)?.name : shipmentInfo?.receiver_country;

      let recreate_shipment = {
        ...formData,
        sender_details: {
          ...formData.sender_details,
          fullname: shipmentInfo?.sender_name,
          email: shipmentInfo?.sender_email,
          phone: shipmentInfo?.sender_phone_no,
          address_1: shipmentInfo?.sender_address_1,
          address_2: shipmentInfo?.sender_address_2,
          country: sender_country,
          state: shipmentInfo?.sender_state,
          city: shipmentInfo?.sender_city,
          postal_code: shipmentInfo?.sender_postcode,
        },
        receiver_details: {
          ...formData?.receiver_details,
          fullname: shipmentInfo?.receiver_name,
          email: shipmentInfo?.receiver_email,
          phone: shipmentInfo?.receiver_phone_no,
          address_1: shipmentInfo?.receiver_address_1,
          address_2: shipmentInfo?.receiver_address_2,
          country: receiver_country,
          state: shipmentInfo?.receiver_state,
          city: shipmentInfo?.receiver_city,
          postal_code: shipmentInfo?.receiver_postcode,
        },
        packages: shipmentInfo?.packages
      };

      setFormData(recreate_shipment);
    };
    // eslint-disable-next-line
  }, [shipmentInfo]);

  const nextPage = () => {
    if(currentPage < 5) {
      setCurrentPage(currentPage+1);
    }
  };

  const previousPage = () => {
    if(currentPage > 0) {
      setCurrentPage(currentPage-1);
    }
  };

  const handleTabSelect = (id) => {
    if(currentPage > id) {
      setCurrentPage(id)
    }
  };

  return (
    <div>
      <ul className="create-shipment-form-tabs">
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 0 && "create-shipment-form-tabs-option-active"
          } ${
            currentPage > 0 ? "cursor-pointer" : "cursor-not-allowed	"
          }`}
          onClick={()=>handleTabSelect(0)}
        >
          {
            currentPage === 0 ? (
              <img src={SenderActiveIcon} alt="icon" />
            ) : (
              <img src={SenderIcon} alt="icon" />
            )
          }
          Sender
        </li>
        <li
          className={`create-shipment-form-tabs-arrow ${
            currentPage === 0 && "create-shipment-form-tabs-arrow-active"
          }`}
        >
          <BsChevronDoubleRight />
        </li>
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 1 && "create-shipment-form-tabs-option-active"
          } ${
            currentPage > 1 ? "cursor-pointer" : "cursor-not-allowed	"
          }`}
          onClick={()=>handleTabSelect(1)}
        >
          {
            currentPage === 1 ? (
              <img src={ReceiverActiveIcon} alt="icon" />
            ) : (
              <img src={ReceiverIcon} alt="icon" />
            )
          }
          Receiver
        </li>
        <li
          className={`create-shipment-form-tabs-arrow ${
            currentPage === 1 && "create-shipment-form-tabs-arrow-active"
          }`}
        >
          <BsChevronDoubleRight />
        </li>
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 2 && "create-shipment-form-tabs-option-active"
          } ${
            currentPage > 2 ? "cursor-pointer" : "cursor-not-allowed	"
          }`}
          onClick={()=>handleTabSelect(2)}
        >
          {
            currentPage === 2 ? (
              <img src={PackageActiveIcon} alt="icon" />
            ) : (
              <img src={PackageIcon} alt="icon" />
            )
          }
          Package
        </li>
        <li
          className={`create-shipment-form-tabs-arrow ${
            currentPage === 2 && "create-shipment-form-tabs-arrow-active"
          }`}
        >
          <BsChevronDoubleRight />
        </li>
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 3 && "create-shipment-form-tabs-option-active"
          } ${
            currentPage > 3 ? "cursor-pointer" : "cursor-not-allowed	"
          }`}
          onClick={()=>handleTabSelect(3)}
        >
          {
            currentPage === 3 ? (
              <img src={PaymentActiveIcon} alt="icon" />
            ) : (
              <img src={PaymentIcon} alt="icon" />
            )
          }
          Payment
        </li>
        <li
          className={`create-shipment-form-tabs-arrow ${
            currentPage === 3 && "create-shipment-form-tabs-arrow-active"
          }`}
        >
          <BsChevronDoubleRight />
        </li>
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 4 && "create-shipment-form-tabs-option-active"
          } ${
            currentPage > 4 ? "cursor-pointer" : "cursor-not-allowed	"
          }`}
          onClick={()=>handleTabSelect(4)}
        >
          {
            currentPage === 4 ? (
              <img src={PaymentActiveIcon} alt="icon" />
            ) : (
              <img src={PaymentIcon} alt="icon" />
            )
          }
          Pricing
        </li>
        <li
          className={`create-shipment-form-tabs-arrow ${
            currentPage === 4 && "create-shipment-form-tabs-arrow-active"
          }`}
        >
          <BsChevronDoubleRight />
        </li>
        <li
          className={`create-shipment-form-tabs-option ${
            currentPage === 5 && "create-shipment-form-tabs-option-active"
          } ${
            currentPage > 5 ? "cursor-pointer" : "cursor-not-allowed	"
          }`}
          onClick={()=>handleTabSelect(5)}
        >
          {
            currentPage === 5 ? (
              <img src={ConfirmActiveIcon} alt="icon" />
            ) : (
              <img src={ConfirmIcon} alt="icon" />
            )
          }
          Confirm
        </li>
      </ul>
      <div>
        {
          currentPage === 0 ? (
            <Sender nextPage={nextPage} formData={formData} setFormData={setFormData} />
          ) : currentPage === 1 ? (
            <Receiver nextPage={nextPage} previousPage={previousPage} formData={formData} setFormData={setFormData} />
          ) : currentPage === 2 ? (
            <Package 
              nextPage={nextPage} 
              previousPage={previousPage} 
              formData={formData} 
              setFormData={setFormData} 
              setCarriers={setCarriers} 
            />
          ) : currentPage === 3 ? (
            <Payment 
              nextPage={nextPage} 
              previousPage={previousPage} 
              formData={formData} 
              setFormData={setFormData}
              setCarriers={setCarriers}
            />
          ) : currentPage === 4 ? (
            <Pricing 
              nextPage={nextPage} 
              previousPage={previousPage} 
              formData={formData} 
              setFormData={setFormData}
              carriers={carriers}
            />
          ) : currentPage === 5 ? (
            <Confirm previousPage={previousPage} formData={formData} setFormData={setFormData} />
          ) : null
        }
      </div>
    </div>
  );
};

export default InterstateShipment;
