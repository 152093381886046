import "../../../styles/home.scss";
import "../../../styles/reports.scss";
import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";
import * as React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserProfileData } from "../../../library/constants";
import { getCustomersReportUrl } from "../../../library/URLs";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";
import dataIcon from "../../../images/no-data.png";
import * as ExcelJS from "exceljs";
import { SelectBox, TextBox } from "../../../components/InputFields";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import ExportIcon from "../../../assets/shipments/file_save.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import ReportIcon from "../../../assets/reports/ReportIcon.svg";
import { FilterDropdown } from "../../../components/Button";
import Contract from "../../../assets/customers/workspace_premium.svg";
import Regular from "../../../assets/customers/military_tech.svg";
import Individual from "../../../assets/customers/individual.svg";
import Business from "../../../assets/customers/user-group.svg";
import Logistics from "../../../assets/customers/truck.svg";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const CustomerInfo = () => {
  const navigator = useNavigate();

  const [reportTime, setReportTime] = useState("");

  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRetrievingMore, setIsRetrievingMore] = useState(false);

  const [memberTypeFilter, setMemberTypeFilter] = useState([]);
  const [accountTypeFilter, setAccountTypeFilter] = useState([]);

  const [customers, setCustomers] = useState([]);

  const [openExport, setOpenExport] = useState(null);
  const open = Boolean(openExport);
  const handleClickExport = (event) => {
    setOpenExport(event.currentTarget);
  };
  const handleClose = () => {
    setOpenExport(null);
  };

  const fetchCustomerReport = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const reportData = {
      ...UserProfileData(),
      // "type": "Today||Week||Month||Year||Custom",
      start_date: reportStartDate,
      end_date: reportEndDate,
      date_type: reportTime,
      member_type: memberTypeFilter,
      account_type: accountTypeFilter,
      mode: "web",
    };
    
    axios
      .post(getCustomersReportUrl, reportData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsRetrievingMore(false);
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const newCustomers = response["data"]["data"];

        setIsOptionSelected(true);
        setCustomers(newCustomers);


        // if (currentOffset === 0){
        //   setShipments(newShipments);
        // } else {
        //   setShipments([...shipments, ...newShipments]);
        // }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onChangeReportTime = (event) => {
    setReportTime(event.target.value);
  };

  const handleClick = (customerID) => {
    navigator("/customer/" + customerID);
  };


  const exportToXlsx = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Report");
    sheet.properties.defaultRowHeight = 40;

    sheet.getRow(1).border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      // left: { style: "thick", color: { argb: "074B8A" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      right: { style: "thin", color: { argb: "074B8A" } },
    };

    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "008561" },
    };

    sheet.getRow(1).font = {
      // name: "Comic Sans MS",
      // family: 4,
      size: 16,
      bold: true,
      color: { argb: "000000" },
    };

    sheet.columns = [
      {
        header: "S/N",
        key: "sn",
        width: 5,
      },
      {
        header: "CUSTOMER ID",
        key: "id",
        width: 30,
      },
      {
        header: "FIRST NAME",
        key: "first_name",
        width: 40,
      },
      {
        header: "LAST NAME",
        key: "last_name",
        width: 40,
      },
      {
        header: "REFERRAL CODE",
        key: "ref_code",
        width: 40,
      },
      {
        header: "EMAIL",
        key: "email",
        width: 40,
      },
      {
        header: "PHONE NUMBER",
        key: "phone",
        width: 40,
      },
      { header: "COUNTRY", key: "country", width: 40 },
      { header: "MEMBER TYPE", key: "member_type", width: 40 },
      {
        header: "ACCOUNT TYPE",
        key: "account_type",
        width: 60,
      },
      {
        header: "DATE JOINED",
        key: "date_time",
        width: 60,
      },
    ];

    sheet.insertRow(1, [
      "",
      "",
      "",
      `
    CUSTOMER REPORT
    `,
    ]);
    const first_row = sheet.getRow(1);
    first_row.height = 120;
    first_row.font = {
      size: 18,
      bold: true,
      color: { argb: "074B8A" },
    };
    first_row.border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      left: { style: "thick", color: { argb: "FFFFFF" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      // right: { style: "thick", color: { argb: "074B8A" } },
    };
    first_row.alignment = { horizontal: "center" };

    // const result = toDataURL(User?.company_logo_url);
    // const result = User?.company_logo_url;

    // worksheet.addImage(imageId2, {
    //   tl: { col: 1.5, row: 1.5 },
    //   br: { col: 3.5, row: 5.5 }
    // });
    customers?.forEach((customer, id) => {
      sheet.addRow({
        sn: id + 1,
        id: `${customer.id ?? "N/A"}`,
        date_time: `${customer?.date_time ?? "N/A"}`,
        first_name: `${customer?.first_name ?? "N/A"}`,
        last_name: `${customer?.last_name ?? "N/A"}`,
        phone: `${customer?.phone ?? "N/A"}`,
        email: `${customer?.email ?? "N/A"}`,
        ref_code: `${customer?.ref_code ?? "N/A"}`,
        member_type: `${customer?.member_type ?? "N/A"}`,
        account_type: `${customer?.account_type ?? "N?A"}`,
        country: `${customer?.country ?? "N/A"}`,
      });
    });
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `Customer_Report.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const exportToCSV = () => {
    if (customers.length === 0) return;

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent +=
      "CUSTOMER ID, FIRST NAME, LAST NAME, REFERRAL CODE, EMAIL, PHONE NUMBER, COUNTRY, MEMBER TYPE, ACCOUNT TYPE, DATE JOINED" +
      "\r\n";

    customers.forEach(function (customer) {
      let id = customer.id;

      let first_name = customer.first_name ?? "";
      let last_name = customer?.last_name ?? "";
      let ref_code = customer.ref_code ?? "N/A";
      let email = customer.email;
      let phone = customer.phone;
      let country = customer.country ?? "N/A";
      let member_type = customer.member_type ?? "N/A";
      let account_type = customer.account_type ?? "N/A";
      let date_time = customer.date_time ?? "N/A";

      const row =
        id +
        "," +
        first_name +
        "," +
        last_name +
        "," +
        ref_code +
        "," +
        email +
        "," +
        phone +
        "," +
        country +
        "," +
        member_type +
        "," +
        account_type +
        "," +
        date_time;

      csvContent += row + "\r\n";
    });

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "customer-report.csv");
    document.body.appendChild(link);

    link.click();
  };

  const [reportStartDate, setReportStartDate] = useState("");
  const [reportEndDate, setReportEndDate] = useState("");

  const date_options = [
    { label: "All Time", value: "All" },
    { label: "Today", value: "Today" },
    { label: "Yesterday", value: "Yesterday" },
    { label: "This Week", value: "Week" },
    { label: "This Month", value: "Month" },
    { label: "Custom Date", value: "Custom" },
  ];

  const shortenTexts = (text) => {
    if (text) {
      if (text?.length > 32) {
        return `${text?.slice(0, 32)}...`;
      } else {
        return text;
      }
    }
  };

  const member_options = [
    { label: "All", value: "All" },
    { label: "Contract", value: "Contract" },
    { label: "Regular", value: "Regular" }
  ];

  const account_options = [
    { label: "All", value: "All" },
    { label: "Individual", value: "Individual" },
    { label: "3P Logistics", value: "3P Logistics" },
    { label: "Business", value: "Business" }
  ];

  const handleMemberTypeFilter = (value) => {
    let member_type_filter_copy = [...memberTypeFilter];

    if(value === "All") {
      if(memberTypeFilter.length < member_options.length) {
        member_type_filter_copy.push("All", "Contract", "Regular");
        setMemberTypeFilter(member_type_filter_copy);
      } else {
        setMemberTypeFilter([]);
      }
    } else {
      if(memberTypeFilter.includes(value)) {
        let update_list = member_type_filter_copy.filter(val => val !== value && val !== "All");
        setMemberTypeFilter(update_list);
      } else {
        member_type_filter_copy.push(value);
        if(member_type_filter_copy.length >= member_options.length - 1) {
          member_type_filter_copy.push("All");
        }
        setMemberTypeFilter(member_type_filter_copy);
      }
    }
  };

  const handleAccountTypeFilter = (value) => {
    let account_type_filter_copy = [...accountTypeFilter];

    if(value === "All") {
      if(accountTypeFilter.length < account_options.length) {
        account_type_filter_copy.push("All", "Individual", "3P Logistics", "Business");
        setAccountTypeFilter(account_type_filter_copy);
      } else {
        setAccountTypeFilter([]);
      }
    } else {
      if(accountTypeFilter.includes(value)) {
        let update_list = account_type_filter_copy.filter(val => val !== value && val !== "All");
        setAccountTypeFilter(update_list);
      } else {
        account_type_filter_copy.push(value);
        if(account_type_filter_copy.length >= account_options.length - 1) {
          account_type_filter_copy.push("All");
        }
        setAccountTypeFilter(account_type_filter_copy);
      }
    }
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <div className="container shipments-container">
            <div
              className="card border-0 p-4 min-vh-100"
              style={{ background: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={ReportIcon} alt="group" />
                  <h5 className="m-0">Customers Report</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3"></div>
              </div>
              <div className="d-flex gap-3 mb-3 align-items-end flex-column flex-md-row justify-content-between">
                <div className="d-flex flex-column flex-md-row align-items-md-end gap-3">
                  <FilterDropdown 
                    filterOptions01={member_options}
                    handleSelection01={handleMemberTypeFilter}
                    filterSelections01={memberTypeFilter}
                    filterOptions02={account_options}
                    handleSelection02={handleAccountTypeFilter}
                    filterSelections02={accountTypeFilter}
                  />

                  <SelectBox
                    name="pickup_detail_id"
                    onChange={onChangeReportTime}
                    options={date_options}
                    style={{ padding: "8px 20px", width: "190px" }}
                    value={reportTime}
                    placeholder="Select Date"
                  />

                  {reportTime === "Custom" && (
                    <div className="d-md-flex d-grid col gap-3">
                      <div>
                        <label className="form-label" htmlFor="startdate">
                          Start Date
                        </label>
                        <TextBox
                          type="date"
                          style={{ padding: "8px 20px" }}
                          value={reportStartDate}
                          onChange={(e) => setReportStartDate(e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="form-label" htmlFor="startdate">
                          End Date
                        </label>
                        <TextBox
                          type="date"
                          style={{ padding: "8px 20px" }}
                          value={reportEndDate}
                          onChange={(e) => setReportEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                  )}

                  <button
                    onClick={fetchCustomerReport}
                    className=" btn-primary"
                  >
                    {
                      isLoading ? (
                        <LoadingSpinner className="spinner-border spinner-grow-sm" />
                      ) : (
                        "Generate"
                      )
                      // (
                      //   <BiIcons.BiSearch style={{ fontSize: 20 }} />
                      // )
                    }
                  </button>
                </div>

                <div>
                  <button
                    className="feature-btn"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClickExport}
                  >
                    <img src={ExportIcon} alt="export" />
                    Export
                    {open ? <BsChevronUp /> : <BsChevronDown />}
                  </button>
                  <Menu
                    id="basic-menu"
                    anchorEl={openExport}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        exportToCSV();
                        handleClose();
                      }}
                      style={{ fontSize: 12 }}
                    >
                      Export as .csv
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: 12 }}
                      onClick={() => {
                        exportToXlsx();
                        handleClose();
                      }}
                    >
                      Export as .xlsx
                    </MenuItem>
                  </Menu>
                </div>
              </div>

              <div className="table-contain">
                <div
                  className=""
                  style={{
                    width: "100%",
                    overflowX: "auto",
                    overflowY: "hidden",
                  }}
                >
                  <table className="shipments-table w-100">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Customer Type</th>
                        <th scope="col">Customer Level</th>
                        <th scope="col">Date Joined</th>
                      </tr>
                    </thead>
                    {isLoading === true ? null : (
                      <tbody>
                        {customers.map((customer) => {
                          const dateTime = moment(customer.date_time);
                          const timestamp =
                            dateTime.format("YYYY MMM DD, h:mm A");

                          return (
                            <tr
                              key={customer.id}
                              // onClick={() => handleClick(shipment.id)}
                            >
                              <td
                                onClick={() => handleClick(customer.id)}
                                // onContextMenu={() => openNewTab(shipment.id)}
                                className="p-0"
                              >
                                <Link
                                  to={`/customer/${customer.id}`}
                                  style={{
                                    padding: "20px 10px 20px 10px",
                                    color: "#475367",
                                  }}
                                >
                                  <Tooltip
                                    title={`${customer.fullname}`}
                                    placement="top"
                                  >
                                    <span>{shortenTexts(customer.fullname)}</span>
                                  </Tooltip>
                                </Link>
                              </td>
                              <td className="p-0">
                                <Link
                                  to={`/customer/${customer.id}`}
                                  style={{
                                    padding: "20px 10px 20px 10px",
                                    color: "#475367",
                                  }}
                                >
                                  <Tooltip
                                    title={`${customer?.email}`}
                                    placement="top"
                                  >
                                    <span>
                                      {shortenTexts(customer?.email)}
                                    </span>
                                  </Tooltip>
                                </Link>
                              </td>
                              <td
                                onClick={() => handleClick(customer.id)}
                                // onContextMenu={() => openNewTab(shipment.id)}
                                className="p-0"
                              >
                                <Link
                                  to={`/customer/${customer.id}`}
                                  style={{
                                    padding: "20px 10px 20px 10px",
                                    color: "#475367",
                                  }}
                                >
                                  {customer.phone}
                                </Link>
                              </td>
                              <td className="p-0">
                                <Link
                                  to={`/customer/${customer.id}`}
                                  style={{
                                    padding: "20px 10px 20px 10px",
                                    color: "#475367",
                                  }}
                                >
                                  <span
                                    style={{ whiteSpace: "nowrap" }}
                                    className="d-flex align-items-center gap-1"
                                  >
                                    {customer.account_type === "Individual" ? (
                                      <img src={Individual} alt="individual" />
                                    ) : customer.account_type === "Business" ? (
                                      <img src={Business} alt="business" />
                                    ) : (
                                      <img src={Logistics} alt="3pl" />
                                    )}
                                    {customer.account_type}
                                  </span>
                                </Link>
                              </td>
                              <td className="p-0">
                                <Link
                                  to={`/customer/${customer.id}`}
                                  style={{
                                    padding: "20px 10px 20px 10px",
                                    color: "#475367",
                                  }}
                                >
                                  <span className="d-flex align-items-center gap-1">
                                {customer.member_type === "Contract" ? (
                                  <img src={Contract} alt="contract" />
                                ) : (
                                  <img src={Regular} alt="Regular" />
                                )}
                                {customer.member_type}
                              </span>
                                </Link>
                              </td>
                              <td className="p-0">
                                <Link
                                  to={`/customer/${customer.id}`}
                                  style={{
                                    padding: "20px 10px 20px 10px",
                                    color: "#475367",
                                  }}
                                >
                                  {timestamp}
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              {isRetrievingMore === false ||
              isLoading === true ? null : (
                <div style={{ textAlign: "center", marginTop: 40 }}>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />{" "}
                  Retrieving More..
                </div>
              )}
              {isOptionSelected ? null : (
                <LoadingContainer>
                  <div>
                    <img
                      style={{ width: 50, height: 50, marginBottom: 10 }}
                      src={dataIcon}
                      alt=""
                    />
                    <p>No option has been selected</p>
                  </div>
                </LoadingContainer>
              )}
              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
