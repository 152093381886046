import { Modal } from "react-bootstrap";
import axios from "axios";
import { deleteCustomCityUrl } from "../library/URLs";
import { useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { BsTrash, BsX } from "react-icons/bs";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const DeleteCustomCityDialog = ({
  isModalVisible,
  handleCloseDialog,
  cityID,
  onSubmit = () => {},
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const deleteCity = (e) => {
    const formValues = {};
    formValues["city_id"] = `${cityID}`;

    const newData = { ...formValues, ...UserProfileData() };

    setErrorVisibility(false);
    setIsSubmitting(true);

    axios
      .post(deleteCustomCityUrl, newData)
      .then(function (response) {
        setIsSubmitting(false);
        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage("Custom city deleted.");
          onSubmit();

          setTimeout(() => {
            setSuccessVisibility(false);
            handleCloseDialog(true);
          }, 3000);
        } else {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">DELETE CUSTOM CITY</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>You are about to delete this city</p>
        <p>
          This means you won't be able to select this city within the platform.
        </p>
        <p>Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-secondary mx-3"
          onClick={(e) => handleCloseDialog(false)}
        >
          <BsX /> CANCEL
        </button>
        <SubmitButton
          onClick={deleteCity}
          type="submit"
          className="confirm-button btn-primary"
        >
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              <BsTrash /> DELETE CITY
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteCustomCityDialog;
