import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  getAPICarriersUrl,
  updateInterstateCarrierTypeUrl,
  uploadRateDocumentUrl,
  getApiCarrierAccountsUrl,
  getCarrierShippingPlansUrl,
  getAllCustomerGroupsUrl,
  getAssignedParcelflowRatesUrl,
} from "../library/URLs";
import { useEffect, useRef, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import {
  MultiSelectBox,
  SelectBox,
  TextBox,
  TextFieldBox,
  UploadImgField,
  UploadImgField2,
} from "../components/InputFields";
import { BsChevronDown, BsChevronUp, BsX } from "react-icons/bs";
import countries from "../files/countries.json";
import currencies from "../files/currencies.json";
import { Tooltip } from "@mui/material";
import { IoMdHelpCircle } from "react-icons/io";
import RatesDocumentDialog from "./RateDocumentsDialog";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { SessionManagement } from "../library/SessionManagement";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const UpdateInterstateCarrierTypeDialog = ({
  isModalVisible,
  handleCloseDialog,
  carrier_details,
}) => {
  const companyLogoURL = SessionManagement.getCompanyLogoURL();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiCarriers, setApiCarriers] = useState([]);
  const [carrierAccounts, setCarrierAccounts] = useState([]);
  const [showRateCard, setShowRateCard] = useState(false);
  const [parcelflowRates, setParcelflowRates] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [carrierForm, setCarrierForm] = useState({
    shipment_type: "",
    carrier: "",
    connection_type: "",
    calculation_connection_type: "",
    creation_connection_type: "",
    operator_details: {
      display_name: "",
      transit_time: "",
      image_url: companyLogoURL,
      additional_info: "",
    },
    api_credentials: {
      api_type: "",
      api_account_id: "",
      multiple_shipment_plans: "No"
    },
    pricing_type: {
      type: "Default",
      rate_file_name: "",
    },
    manual_zoning_type: "",
    api_account_id: "",
    parcelflow_rate_id: "",
    visibility_type: "All",
    manual_update: "No",
    per_kg_countries: [],
    countries_select: [],
    shipping_plans: [],
    groups: [],
  });
  const [logoFile, setLogoFile] = useState("");
  const [rateDocFile, setRateDocFile] = useState("");

  const [carrierShipOpt, setCarrierShipOpt] = useState([]);

  const [openShippingPlan, setOpenShippingPlan] = useState(null);

  const [openPerKgCountry, setOpenPerKgCountry] = useState(null);

  const [isAPILoading, setIsAPILoading] = useState(false);

  const [customerGroups, setCustomerGroups] = useState([]);

  const rateDocInputRef = useRef();

  useEffect(() => {
    if (customerGroups.length > 1) {
      if (carrier_details?.user_groups && carrier_details?.user_groups !== "") {
        let selected_groups_id = carrier_details?.user_groups?.split("|");
        let selected_groups = customerGroups
          ?.filter((group) => selected_groups_id?.includes(`${group?.value}`))
          ?.map((group) => {
            return {
              label: group?.label,
              value: group?.value,
            };
          });
        setCarrierForm({
          ...carrierForm,
          groups: selected_groups,
        });
      } else {
        setCarrierForm({
          ...carrierForm,
          groups: [],
        });
      }
    }
    // eslint-disable-next-line
  }, [customerGroups]);

  useEffect(() => {
    if (carrier_details) {
      setCarrierForm({
        ...carrierForm,
        carrier_id: carrier_details?.id,
        shipment_type: carrier_details?.shipment_type,
        carrier: carrier_details?.carrier,
        // parcelflow_rate_id: carrier_details?.parcelflow_rate_id,
        calculation_connection_type:
          carrier_details?.calculation_connection_type,
        creation_connection_type: carrier_details?.creation_connection_type,
        api_credentials: {
          api_type: carrier_details?.api_type,
          api_account_id: carrier_details?.api_account_id !== ""
          ? parseInt(carrier_details?.api_account_id)
          : carrier_details?.api_account_id,
          multiple_shipment_plans: carrier_details?.multiple_shipment_plans
        },
        operator_details: {
          display_name: carrier_details?.masked_name,
          transit_time: carrier_details?.transit_time,
          image_url: carrier_details?.image_url,
          additional_info: carrier_details?.additional_info,
        },
        pricing_type: {
          type: carrier_details?.pricing_type,
          rate_file_name: carrier_details?.rate_file_name,
        },
        manual_zoning_type: carrier_details?.manual_zoning_type,
        manual_update: carrier_details?.manual_update,
        shipping_plans: carrier_details?.shipping_plans,
        visibility_type: carrier_details?.visibility_type,
        selected_shipping_options: carrier_details?.shipping_plans
          ? carrier_details?.shipping_plans?.map((plan) => {
              return {
                label: plan["3pl_plan_name"],
                value: plan["3pl_plan_name"],
              };
            })
          : [],
        per_kg_countries: carrier_details
          ? carrier_details?.per_kg_countries?.map((carrier) => {
              return {
                countries: carrier?.country,
                price: carrier?.price_per_kg,
                minimum_weight: carrier?.minimum_weight,
                maximum_weight: carrier?.maximum_weight,
                currency: carrier?.currency,
              };
            })
          : [],
        countries_select: carrier_details
          ? carrier_details?.per_kg_countries?.map((carrier) => {
              return {
                label: carrier?.country,
                value: carrier?.country,
              };
            })
          : [],
        group_id: carrier_details?.group_id,
      });

      if (carrier_details?.api_type) {
        getCarrierAccounts(carrier_details?.api_type);
      }

      if (carrier_details?.image_url) {
        setLogoFile(carrier_details?.masked_name);
      }

      if (carrier_details?.rate_file_name) {
        setRateDocFile(carrier_details?.masked_name);
      }

      fetchApiCarriers();
      getCustomerGroups();
      fetchParcelflowRates();
    }
    // eslint-disable-next-line
  }, [carrier_details]);

  const getCarrierAccounts = async (carrier_id) => {
    const formValues = {
      ...UserProfileData(),
      carrier: carrier_id,
    };
    setFetching(true);

    await axios
      .post(getApiCarrierAccountsUrl, formValues)
      .then((res) => {
        setFetching(false);
        if (res?.data?.success) {
          let carrier_accounts = res?.data?.data?.map((carrier) => {
            return {
              label: carrier?.name,
              value: carrier?.id,
            };
          });
          // console.log("apd ", carrier_accounts);
          setCarrierAccounts(carrier_accounts);
        }
      })
      .catch((err) => {
        setFetching(false);
      });
  };

  const fetchCarrierShippingPlans = async (api_carrier_id) => {
    const formValues = { ...UserProfileData(), api_carrier_id };

    await axios
      .post(getCarrierShippingPlansUrl, formValues)
      .then((res) => {
        if (res.data.success) {
          let shipping_options = res.data.data
            .filter(
              (ship_plan) =>
                ship_plan[carrierForm?.shipment_type?.toLowerCase()] === "Yes"
            )
            ?.map((ship_plan) => {
              return {
                label: ship_plan.name,
                value: ship_plan.name,
              };
            });

          setCarrierShipOpt(shipping_options);
        }
      })
      .catch((err) => {});
  };

  const fetchParcelflowRates = async () => {
    const formValues = {
      ...UserProfileData(),
      shipment_type:
      carrier_details?.shipment_type === "International"
          ? "Export"
          : carrier_details?.shipment_type,
    };

    await axios
      .post(getAssignedParcelflowRatesUrl, formValues)
      .then((res) => {
        if (res?.data?.success) {

          let rates = res?.data?.data?.map((rate) => {
            return {
              label: `${rate?.name} (${rate?.date_created})`,
              value: rate?.id
            }
          })
          setParcelflowRates(rates);
        }
      })
      .catch((err) => {});
  };

  const fetchApiCarriers = async () => {
    setIsAPILoading(true);
    await axios
      .post(getAPICarriersUrl, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          setIsAPILoading(false);
          if (carrier_details?.shipment_type) {
            let api_carriers = res?.data?.data
              ?.filter(
                (carrier) =>
                  carrier[carrier_details?.shipment_type?.toLowerCase()] ===
                  "Yes"
              )
              ?.map((carrier) => {
                return {
                  label: carrier?.name,
                  value: `${carrier?.id}`,
                  img_url: carrier.image_url,
                  multiple_shipment_plans: carrier.multiple_shipment_plans,
                };
              });
            setApiCarriers(api_carriers);
          }
        }
      })
      .catch((err) => {
        setIsAPILoading(false);
      });
  };

  const [uploadingRateDoc, setUploadingRateDoc] = useState(false);

  function trimObjectKeys(obj) {
    const trimmedObject = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const trimmedKey = key.trim();
        trimmedObject[trimmedKey] = obj[key];
      }
    }
    return trimmedObject;
  }

  const validateAramex = (data) => {
    if (data?.length < 1) {
      toast.error("Document is empty!");
      return false;
    } else {
      const requiredKeys = [
        "KG",
        "Zone 1",
        "Zone 2",
        "Zone 3",
        "Zone 4",
        "Zone 5",
        "Zone 6",
        "Zone 7",
        "Zone 8",
      ];
      let validate = requiredKeys.every((key) => key in data[0]);

      if (validate) {
        return validate;
      } else {
        toast.error("Please upload a valid document!");
        return validate;
      }
    }
  };

  const validateDHL = (data) => {
    if (data?.length < 1) {
      toast.error("Document is empty!");
      return false;
    } else {
      const requiredKeys = [
        "KG",
        "Zone 1",
        "Zone 2",
        "Zone 3",
        "Zone 4",
        "Zone 5",
        "Zone 6",
        "Zone 7",
        "Zone 8",
      ];
      let validate = requiredKeys.every((key) => key in data[0]);

      if (validate) {
        return validate;
      } else {
        toast.error("Please upload a valid document!");
        return validate;
      }
    }
  };

  const validateFedex = (data) => {
    if (data?.length < 1) {
      toast.error("Document is empty!");
      return false;
    } else {
      const requiredKeys = [
        "KG",
        "Zone A",
        "Zone B",
        "Zone C",
        "Zone D",
        "Zone E",
        "Zone F",
        "Zone G",
        "Zone H",
        "Zone I",
        "Zone J",
      ];
      let validate = requiredKeys.every((key) => key in data[0]);

      if (validate) {
        return validate;
      } else {
        toast.error("Please upload a valid document!");
        return validate;
      }
    }
  };

  const validateUPS = (data) => {
    if (data?.length < 1) {
      toast.error("Document is empty!");
      return false;
    } else {
      const requiredKeys = [
        "KG",
        "Zone 1",
        "Zone 2",
        "Zone 3",
        "Zone 4",
        "Zone 5",
        "Zone 6",
        "Zone 7",
        "Zone 8",
        "Zone 9",
      ];
      let validate = requiredKeys.every((key) => key in data[0]);

      if (validate) {
        return validate;
      } else {
        toast.error("Please upload a valid document!");
        return validate;
      }
    }
  };

  const uploadRateDocument = (file) => {
    const docFormData = new FormData();
    docFormData.append("document", file);

    if (!carrierForm.manual_zoning_type) {
      toast.error("Please select how your countries are zoned!");
      return;
    }

    const reader = new FileReader();

    let validate_document = false;

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet);

      const trimmedArrayOfObjects = excelData.map(trimObjectKeys);
      console.log("ieoa", trimmedArrayOfObjects);

      if (carrierForm.manual_zoning_type === "Aramex") {
        validate_document = validateAramex(trimmedArrayOfObjects);
      } else if (carrierForm.manual_zoning_type === "DHL") {
        validate_document = validateDHL(trimmedArrayOfObjects);
      } else if (carrierForm.manual_zoning_type === "Fedex") {
        validate_document = validateFedex(trimmedArrayOfObjects);
      } else if (carrierForm.manual_zoning_type === "UPS") {
        validate_document = validateUPS(trimmedArrayOfObjects);
      } else {
        toast.error("Select a valid method your countries are zoned!");
        return;
      }

      // If validation fails, exit the entire function
      //

      // Continue processing the data here...
      // console.log("Validation passed");
    };

    reader.readAsBinaryString(file);

    // Wrap remaining asynchronous logic in the onload event
    reader.onloadend = () => {
      if (validate_document) {
        setUploadingRateDoc(true);

        axios
          .post(uploadRateDocumentUrl, docFormData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
            setUploadingRateDoc(false);
            setRateDocFile(file?.name);
            setCarrierForm({
              ...carrierForm,
              pricing_type: {
                ...carrierForm?.pricing_type,
                rate_file_name: response?.data,
              }
            });
          })
          .catch(function (error) {
            console.error(error);
            return error;
          });
      }
    };
  };

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "calculation_connection_type") {
      let updated_data = {};
      if (value === "Parcelflow") {
        updated_data = {
          ...carrierForm,
          creation_connection_type: "Parcelflow",
          [name]: value,
        };
      } else if (value === "Self") {
        updated_data = {
          ...carrierForm,
          creation_connection_type: "Custom",
          [name]: value,
        };
      } else {
        updated_data = {
          ...carrierForm,
          [name]: value,
        };
      }
      setCarrierForm(updated_data);
    } else {
      setCarrierForm({
        ...carrierForm,
        [name]: value,
      });
    }
  };

  const handleApiCredInputs = (e) => {
    const { name, value } = e.target;

    if (name === "api_type") {
      let api_type = apiCarriers?.find((api) => `${api?.value}` === value);
      let updated_data = {
        ...carrierForm,
        api_credentials: {
          ...carrierForm.api_credentials,
          [name]: value,
          multiple_shipment_plans: api_type.multiple_shipment_plans,
        },
        operator_details: {
          ...carrierForm.operator_details,
          image_url: api_type.img_url,
          multiple_shipment_plans: api_type.multiple_shipment_plans,
        }
      };
      setCarrierForm(updated_data);
      setLogoFile(api_type?.label);
      getCarrierAccounts(value);
      fetchCarrierShippingPlans(value);
    } else {
      setCarrierForm({
        ...carrierForm,
        api_credentials: {
          ...carrierForm.api_credentials,
          [name]: value
        }
      });
    }
  };

  const handlePricingTypeInput = (e) => {
    const { name, value } = e.target;

    setCarrierForm({
      ...carrierForm,
      pricing_type: {
        ...carrierForm.pricing_type,
        [name]: value
      }
    });
  };

  const handleOperatorInput = (e) => {
    const { name, value } = e.target;

    setCarrierForm({
      ...carrierForm,
      operator_details: {
        ...carrierForm?.operator_details,
        [name]: value
      }
    });
  };

  const triggerRateDocInput = () => {
    rateDocInputRef.current.click();
  };

  const handleRateDocInput = (e) => {
    const { files } = e.target;
    uploadRateDocument(files[0]);
  };

  const calculate_connection_type_options = [
    { label: "API", value: "API" },
    { label: "Use Parcelflow Rate", value: "Parcelflow" },
  ];

  if (carrier_details?.shipment_type !== "Interstate") {
    calculate_connection_type_options.push(
      { label: "Custom", value: "Custom" },
      { label: "Using Excel Spreadsheet", value: "Manual" },
      { label: "Use Parcelflow Rate", value: "Parcelflow" }
    );
  } else {
    calculate_connection_type_options.push(
      {
        label: "Using my delivery agents. e.g. riders and drivers",
        value: "Self",
      },
      { label: "Use Parcelflow Rate", value: "Parcelflow" }
    );
  }

  const create_connection_type_options = [
    { label: "API", value: "API" },
    { label: "Custom", value: "Custom" },
    { label: "Use Parcelflow Rate", value: "Parcelflow" },
  ];

  // const pricing_type_options = [
  //   { label: "Default", value: "Default" },
  //   { label: "Per KG", value: "Per KG" },
  // ];

  const country_options = countries.map((country) => {
    return {
      label: country.name,
      value: country.name,
    };
  });

  const currency_options = currencies
    .filter(
      (currency) =>
        currency.cc === "USD" ||
        currency.cc === "NGN" ||
        currency.cc === "GBP" ||
        currency.cc === "EUR"
    )
    ?.map((currency) => {
      return {
        label: `${currency.name} - ${currency.cc}`,
        value: currency.cc,
      };
    });

  // const addWeightRate = () => {
  //   const per_kg_copy = [...carrierForm.per_kg_countries];

  //   per_kg_copy.push({
  //     countries: "",
  //     minimum_weight: "",
  //     maximum_weight: "",
  //     price: "",
  //     currency: "NGN",
  //   });

  //   setCarrierForm({
  //     ...carrierForm,
  //     per_kg_countries: per_kg_copy,
  //   });
  // };

  // const removeWeightRate = () => {
  //   if (carrierForm.per_kg_countries.length < 2) {
  //     return;
  //   }

  //   const per_kg_copy = [...carrierForm.per_kg_countries];
  //   per_kg_copy.pop();
  //   setCarrierForm({
  //     ...carrierForm,
  //     per_kg_countries: per_kg_copy,
  //   });
  // };

  const handleWeightRateInput = (e, id) => {
    const { name, value } = e.target;
    const per_kg_copy = [...carrierForm.per_kg_countries];

    per_kg_copy[id] = {
      ...per_kg_copy[id],
      [name]: value,
    };

    setCarrierForm({
      ...carrierForm,
      per_kg_countries: per_kg_copy,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formdata = {
      ...UserProfileData(),
      ...carrierForm,
      carrier: carrierForm?.api_credentials?.api_type,
      pricing_type: {
        ...carrierForm?.pricing_type,
        type: carrierForm?.calculation_connection_type === "Custom"
        ? carrierForm?.pricing_type?.type
        : "Default"
      },
      group_id: carrierForm.groups?.map((group) => group.value).join("|"),
    };
    setIsSubmitting(true);

    axios
      .post(updateInterstateCarrierTypeUrl, formdata)
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.success) {
          window.scrollTo(0, 0);

          setSuccessVisibility(true);
          setSuccessMessage("Carrier updated successfully!");

          setTimeout(() => {
            setSuccessVisibility(false);
          }, 3000);
          setTimeout(() => {
            window.location.reload();
          }, 4000);
        } else {
          window.scrollTo(0, 0);
          setErrorVisibility(true);
          setErrorMessage(res?.data?.message);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const handleUploadLogo = (url) => {
    setCarrierForm({
      ...carrierForm,
      operator_details: {
        ...carrierForm.operator_details,
        image_url: url,
      }
    });
  };

  const zoning_options = [
    { label: "Aramex Country Zoning", value: "Aramex" },
    { label: "DHL Country Zoning", value: "DHL" },
    { label: "FedEx Country Zoning", value: "Fedex" },
    { label: "UPS Country Zoning", value: "UPS" },
  ];

  const openRateCards = () => {
    setShowRateCard(true);
  };

  const closeRateCards = () => {
    setShowRateCard(false);
  };

  const checkIfMultiCarriers = () => {
    // This function checks if selected API allows multiple carriers.

    // Conditions to be met:
    // 1. Check if calculate connection type is API
    // 2. Check if the selected API returns multiple sub carrier rates.

    let check = false;

    if (carrierForm.calculation_connection_type === "API") {
      if (carrierForm?.multiple_shipment_plans === "Yes") {
        check = true;
      }
    }

    return check;
  };

  const getCustomerGroups = async () => {
    const formValues = {
      ...UserProfileData(),
    };

    await axios
      .post(getAllCustomerGroupsUrl, formValues)
      .then((res) => {
        setFetching(false);
        if (res?.data?.success) {
          let groups = [{ label: "None", value: "" }];

          res?.data?.data?.forEach((group) => {
            groups.push({
              label: group?.name,
              value: group?.id,
            });
          });
          setCustomerGroups(groups);
        }
      })
      .catch((err) => {});
  };

  const handleSelectCarrierOptions = (value) => {
    // Update selected_shipping_options field which adjusts shipping_plans field.
    // Checks involved:
    // 1. If carrier is removed: remove option with similar name from shipping_plans.
    // 2. If carrier is added: append option to shipping_plans with necessary fields.
    // Steps involved includes:
    // 1. update selected_shipping_options
    // 2. perform check from checks above

    let selected_shipping_options = [];

    // let shipping_plans = [...carrierForm?.shipping_plans];
    value?.forEach((plan) => {
      let current_plan = carrierForm.shipping_plans?.find(
        (opt) => opt["3pl_plan_name"] === plan?.value
      );
      if (current_plan) {
        let update_obj = {
          "3pl_plan_name": current_plan["3pl_plan_name"],
          transit_time: current_plan?.transit_time,
          display_name: current_plan?.display_name,
          additional_info: current_plan?.additional_info,
          image_url: current_plan?.image_url,
          minimum_weight: current_plan?.minimum_weight,
          maximum_weight: current_plan?.maximum_weight,
        };
        selected_shipping_options.push(update_obj);
      } else {
        let update_obj = {
          "3pl_plan_name": plan.value,
          transit_time: "",
          display_name: "",
          additional_info: "",
          image_url: carrierForm.image_url,
          minimum_weight: "",
          maximum_weight: "",
        };
        selected_shipping_options.push(update_obj);
      }
    });

    setCarrierForm({
      ...carrierForm,
      shipping_plans: selected_shipping_options,
      selected_shipping_options: value,
    });
  };

  const handleMultiPlanInput = (e, id) => {
    // goal: update input fields relating to multi carrier plans
    // steps:
    // 1. get the id, value and name of the focused input.
    // 2. create a copy of the index object within the shipping_plans list.
    // 3. update the object and replace the current list with the modified copied list.

    const { name, value } = e.target;

    let shipping_plans_copy = [...carrierForm?.shipping_plans];

    shipping_plans_copy[id] = {
      ...shipping_plans_copy[id],
      [name]: value,
    };

    setCarrierForm({
      ...carrierForm,
      shipping_plans: [...shipping_plans_copy],
    });
  };

  const handleMultiUploadLogo = (url, id) => {
    // goal: update image for each carrier plans for multi carriers.

    let shipping_plans_copy = [...carrierForm?.shipping_plans];

    let updated_obj = {
      ...shipping_plans_copy[id],
      image_url: url,
    };

    shipping_plans_copy[id] = updated_obj;

    setCarrierForm({
      ...carrierForm,
      shipping_plans: [...shipping_plans_copy],
    });
  };

  const handleSelectCountry = (value) => {
    let selected_countries = [];

    // let shipping_plans = [...carrierForm?.shipping_plans];
    value?.forEach((country) => {
      let current_country = carrierForm.per_kg_countries?.find(
        (opt) => opt?.countries === country?.value
      );
      if (current_country) {
        let update_obj = {
          countries: current_country?.countries,
          minimum_weight: current_country?.minimum_weight,
          maximum_weight: current_country?.maximum_weight,
          price: current_country?.price,
          currency: current_country?.currency,
        };
        selected_countries.push(update_obj);
      } else {
        let update_obj = {
          countries: country?.value,
          minimum_weight: "",
          maximum_weight: "",
          price: "",
          currency: "NGN",
        };
        selected_countries.push(update_obj);
      }
    });

    setCarrierForm({
      ...carrierForm,
      per_kg_countries: selected_countries,
      countries_select: value,
    });
  };

  const handleSelectedShippingPlan = (plan) => {
    if (openShippingPlan === plan) {
      setOpenShippingPlan(null);
    } else {
      setOpenShippingPlan(plan);
    }
  };

  const handlePerKgCountry = (plan) => {
    if (openPerKgCountry === plan) {
      setOpenPerKgCountry(null);
    } else {
      setOpenPerKgCountry(plan);
    }
  };

  // const parcelflow_carriers = ["DHL"];

  const visibility_options = [
    { label: "Admins and Users", value: "All" },
    { label: "Admins only", value: "Admin" },
    { label: "Users only", value: "User" },
  ];

  const handleSelectCustomerGroup = (value) => {
    if (value.length > 0) {
      if (value[value.length - 1]?.value === "") {
        setCarrierForm({
          ...carrierForm,
          groups: [{ label: "None", value: "" }],
        });
      } else {
        setCarrierForm({
          ...carrierForm,
          groups: value.filter((group) => group.value !== ""),
        });
      }
    } else {
      setCarrierForm({
        ...carrierForm,
        groups: value,
      });
    }
  };

  const pricing_type_options = [
    {label: "Country Per KG", value: "Per KG"},
    {label: "Country Per Zone", value: "Per Zone"}
  ];

  return (
    <Modal size="lg" show={isModalVisible} onHide={handleCloseDialog}>
      <RatesDocumentDialog
        isModalVisible={showRateCard}
        handleCloseDialog={closeRateCards}
      />
      <Modal.Header>
        <h5 className="header">UPDATE CARRIER</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        {isAPILoading ? (
          <div className="d-flex my-4 justify-content-center">
            <span className="spinner-border spinner-grow-sm" />
          </div>
        ) : (
          <div className="w-100">
            <p>Fill the details below to update carrier type</p>
            <div className="query-result-container">
              <Row className="mb-2 gy-4">
                {/* How to calculate and create rate */}
                <Col md={6}>
                  <div>
                    <label htmlFor="Description" className="mb-1">
                      How do you want your rates calculated?
                    </label>
                    <SelectBox
                      name="calculation_connection_type"
                      value={carrierForm?.calculation_connection_type}
                      options={calculate_connection_type_options}
                      onChange={handleInput}
                      placeholder="Enter connection type"
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <div>
                    <label htmlFor="Description" className="mb-1">
                      How should shipments be created?
                    </label>
                    <SelectBox
                      name="creation_connection_type"
                      value={carrierForm?.creation_connection_type}
                      options={create_connection_type_options}
                      onChange={handleInput}
                      placeholder="Enter connection type"
                    />
                  </div>
                </Col>

                {/* If selection is API */}
                {(carrierForm?.creation_connection_type === "API" ||
                  carrierForm?.calculation_connection_type === "API") && (
                  <Col md={6}>
                    <div>
                      <label htmlFor="Description" className="mb-1">
                        Select API to use
                      </label>
                      <SelectBox
                        name="api_type"
                        value={carrierForm?.api_credentials?.api_type}
                        options={apiCarriers}
                        onChange={handleApiCredInputs}
                        placeholder="Select API"
                      />
                    </div>
                  </Col>
                )}

{(carrierForm?.creation_connection_type === "API" ||
                  carrierForm?.calculation_connection_type === "API") && (
                  <Col md={6}>
                    <div>
                      <label htmlFor="Description" className="mb-1">
                        Select API account to use
                      </label>
                      <div className="d-flex gap-2 align-items-center">
                        {fetching && (
                          <span className="spinner-border spinner-grow-sm" />
                        )}
                        <SelectBox
                          name="api_account_id"
                          value={carrierForm?.api_credentials?.api_account_id}
                          options={carrierAccounts}
                          onChange={handleApiCredInputs}
                          placeholder="Select api account"
                        />
                      </div>
                    </div>
                  </Col>
                )}

                {carrierForm?.creation_connection_type === "Parcelflow" && (
                  <Col md={12}>
                    <div>
                      <label htmlFor="Description" className="mb-1">
                        Select Parcelflow Rate to Use
                      </label>
                      <SelectBox
                        name="parcelflow_rate_id"
                        value={carrierForm?.parcelflow_rate_id}
                        options={parcelflowRates}
                        onChange={handleInput}
                        placeholder="Select rate"
                      />
                    </div>
                  </Col>
                )}

                {checkIfMultiCarriers() && (
                  <Row className="mx-0 p-0 gy-4 my-4">
                    <Col xs={12}>
                      <div>
                        <label htmlFor="Description" className="mb-1">
                          Select shipping plans to use
                        </label>
                        <MultiSelectBox
                          name="selected_shipping_options"
                          value={carrierForm?.selected_shipping_options}
                          options={carrierShipOpt}
                          onChange={(value) =>
                            handleSelectCarrierOptions(value)
                          }
                          placeholder="Select shipping plan"
                        />
                      </div>
                    </Col>
                    {carrierForm.shipping_plans?.map((plan, id) => (
                      <div key={id} className="carrier-type-accordion">
                        <div
                          className="carrier-type-header"
                          onClick={() =>
                            handleSelectedShippingPlan(plan["3pl_plan_name"])
                          }
                        >
                          <p className="m-0">{plan["3pl_plan_name"]} </p>
                          {openShippingPlan === plan["3pl_plan_name"] ? (
                            <BsChevronUp />
                          ) : (
                            <BsChevronDown />
                          )}
                        </div>
                        <div
                          className={`carrier-type-body ${
                            openShippingPlan === plan["3pl_plan_name"]
                              ? "carrier-type-body-open"
                              : "carrier-type-body-close"
                          }`}
                        >
                          <Row className="m-0 p-0 gy-4">
                            <Col xs={12} lg={6}>
                              <div className="">
                                <label htmlFor="Description" className="mb-1">
                                  Plan Name
                                </label>
                                <TextBox
                                  type="text"
                                  name="3pl_plan_name"
                                  value={plan["3pl_plan_name"]}
                                  onChange={(e) => handleMultiPlanInput(e, id)}
                                  disabled={true}
                                  placeholder="Enter carrier display name"
                                />
                              </div>
                            </Col>

                            <Col xs={12} lg={6}>
                              <div className="">
                                <label htmlFor="Description" className="mb-1">
                                  Provide a custom name for this plan
                                </label>
                                <TextBox
                                  type="text"
                                  name="display_name"
                                  value={plan?.display_name}
                                  onChange={(e) => handleMultiPlanInput(e, id)}
                                  placeholder="Enter carrier display name"
                                />
                              </div>
                            </Col>

                            <Col xs={12} lg={6}>
                              <div>
                                <label className="mb-1" htmlFor="Description">
                                  Transit Time
                                </label>
                                <TextBox
                                  name="transit_time"
                                  type="text"
                                  value={plan.transit_time}
                                  onChange={(e) => handleMultiPlanInput(e, id)}
                                  placeholder="E.g: 2 to 3 working days"
                                />
                              </div>
                            </Col>

                            <Col xs={12} lg={6}>
                              <div className="">
                                <label htmlFor="package img" className="mb-1">
                                  Upload Carrier Logo
                                </label>
                                <UploadImgField2
                                  handleInput={handleMultiUploadLogo}
                                  fileName={plan["3pl_plan_name"]}
                                  setFileName={() => {}}
                                  imageUrl={plan?.image_url}
                                  id={id}
                                />
                              </div>
                            </Col>

                            <Col xs={12}>
                              <div className="mb-2">
                                <label htmlFor="Description" className="mb-1">
                                  Additional Information
                                </label>
                                <TextFieldBox
                                  name="additional_info"
                                  value={plan?.additional_info}
                                  onChange={(e) => handleMultiPlanInput(e, id)}
                                  placeholder="Enter additional information"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                  </Row>
                )}

                {/* For Display name and transit name for non multi shipments */}
                {!checkIfMultiCarriers() && (
                  <Row className="my-4 mx-0 p-0">
                    <Col xs={12} lg={8}>
                      <div className="">
                        <label htmlFor="Description" className="mb-1">
                          Display Name
                        </label>
                        <TextBox
                          type="text"
                          name="display_name"
                          value={carrierForm.operator_details.display_name}
                          onChange={handleOperatorInput}
                          placeholder="Enter carrier display name"
                        />
                      </div>
                    </Col>

                    <Col xs={12} lg={4}>
                      <div>
                        <label className="mb-1" htmlFor="Description">
                          Transit Time
                        </label>
                        <TextBox
                          name="transit_time"
                          type="text"
                          value={carrierForm.operator_details.transit_time}
                          onChange={handleOperatorInput}
                          placeholder="E.g: 2 to 3 working days"
                        />
                      </div>
                    </Col>
                  </Row>
                )}

                {/* If calculation connection type is manual */}
                {carrierForm.calculation_connection_type === "Manual" && (
                  <Col md={6}>
                    <div>
                      <label htmlFor="Description" className="mb-1">
                        How were the countries zoned?
                      </label>
                      <SelectBox
                        name="manual_zoning_type"
                        value={carrierForm?.manual_zoning_type}
                        options={zoning_options}
                        onChange={handleInput}
                        placeholder="Enter zoning type"
                      />
                    </div>
                  </Col>
                )}

                {carrierForm.calculation_connection_type === "Manual" && (
                  <Col md={6}>
                    <div className="">
                      <div className="d-flex gap-2">
                        <label className="" htmlFor="package img">
                          Upload carrier rates document
                        </label>
                        <span
                          style={{ marginTop: "-3px" }}
                          onClick={openRateCards}
                        >
                          <Tooltip
                            title="Click here to document samples"
                            placement="top"
                          >
                            <span>
                              <IoMdHelpCircle />
                            </span>
                          </Tooltip>
                        </span>
                      </div>
                      <div className="d-flex gap-2 align-items-center">
                        {uploadingRateDoc && (
                          <span className="spinner-border spinner-grow-sm" />
                        )}
                        <div className="w-100">
                          <input
                            style={{
                              visibility: "hidden",
                              position: "fixed",
                              bottom: 0,
                              zIndex: -1,
                            }}
                            type="file"
                            onChange={handleRateDocInput}
                            ref={rateDocInputRef}
                            accept=".xlsx, .xls"
                            name=""
                            id=""
                          />
                          <div
                            style={{ marginTop: 11 }}
                            onClick={triggerRateDocInput}
                            className="change-password-button"
                          >
                            Choose Spreadsheet
                          </div>
                        </div>
                      </div>
                      {carrierForm?.pricing_type?.rate_file_name !== "" && (
                        <small>{rateDocFile}</small>
                      )}
                    </div>
                  </Col>
                )}

                {carrierForm?.calculation_connection_type === "Custom" && (
                  <Col md={12}>
                    <div>
                      <label htmlFor="Description" className="mb-1">
                        Pricing Type
                      </label>
                      <SelectBox
                        name="type"
                        value={carrierForm?.pricing_type?.type}
                        options={pricing_type_options}
                        onChange={handlePricingTypeInput}
                        placeholder="Select pricing type"
                      />
                    </div>
                  </Col>
                )}

                {/* If calculation type is custom */}
                {carrierForm?.pricing_type?.type === "Per KG" &&
                  carrierForm?.calculation_connection_type === "Custom" && (
                    <Col xs={12} className="px-0">
                      <div className="mb-3 px-3">
                        <label className="mb-2" htmlFor="Description">
                          Country
                        </label>
                        <MultiSelectBox
                          name="countries"
                          value={carrierForm?.countries_select}
                          options={country_options}
                          onChange={(value) => handleSelectCountry(value)}
                          placeholder="Select countries"
                        />
                      </div>
                      {carrierForm.per_kg_countries?.map((kg_item, id) => (
                        <div key={id} className="carrier-type-accordion px-3">
                          <div
                            className="carrier-type-header"
                            onClick={() =>
                              handlePerKgCountry(kg_item?.countries)
                            }
                          >
                            <p className="m-0">{kg_item?.countries} </p>
                            {openPerKgCountry === kg_item?.countries ? (
                              <BsChevronUp />
                            ) : (
                              <BsChevronDown />
                            )}
                          </div>
                          <div
                            className={`carrier-type-body ${
                              openPerKgCountry === kg_item?.countries
                                ? "carrier-type-body-open"
                                : "carrier-type-body-close"
                            }`}
                          >
                            <Row className="gy-3 pt-2 pb-3 px-0">
                              <Col xs={12}>
                                <div>
                                  <label className="mb-2" htmlFor="Description">
                                    Country
                                  </label>
                                  {/* <MultiSelectBox
                                name="countries"
                                value={kg_item?.countries_select}
                                options={country_options}
                                onChange={(value) =>
                                  handleSelectCountryInput(id, value)
                                }
                                id={id}
                                placeholder="Select countries"
                              /> */}
                                  <TextBox
                                    value={kg_item?.countries}
                                    disabled
                                  />
                                </div>
                              </Col>
                              <Col sm={12} md={3}>
                                <div>
                                  <label className="mb-1" htmlFor="Description">
                                    Minimum Weight
                                  </label>
                                  <TextBox
                                    name="minimum_weight"
                                    type="text"
                                    value={kg_item?.minimum_weight}
                                    onChange={(e) =>
                                      handleWeightRateInput(e, id)
                                    }
                                    placeholder="KG"
                                    prefix="KG"
                                  />
                                </div>
                              </Col>
                              <Col sm={12} md={3}>
                                <div>
                                  <label className="mb-1" htmlFor="Description">
                                    Maximum Weight
                                  </label>
                                  <TextBox
                                    name="maximum_weight"
                                    type="text"
                                    value={kg_item?.maximum_weight}
                                    onChange={(e) =>
                                      handleWeightRateInput(e, id)
                                    }
                                    placeholder="KG"
                                    prefix="KG"
                                  />
                                </div>
                              </Col>
                              <Col sm={12} md={3}>
                                <div className="">
                                  <label className="mb-1" htmlFor="Description">
                                    Currency
                                  </label>
                                  <SelectBox
                                    name="currency"
                                    value={kg_item?.currency}
                                    onChange={(e) =>
                                      handleWeightRateInput(e, id)
                                    }
                                    placeholder="Select currency"
                                    options={currency_options}
                                  />
                                </div>
                              </Col>
                              <Col sm={12} md={3}>
                                <div className="">
                                  <label className="mb-1" htmlFor="Description">
                                    Price
                                  </label>
                                  <TextBox
                                    name="price"
                                    type="text"
                                    value={kg_item?.price}
                                    onChange={(e) =>
                                      handleWeightRateInput(e, id)
                                    }
                                    prefix={kg_item?.currency ?? ""}
                                    placeholder="Enter fee"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ))}
                    </Col>
                  )}

                {/* Upload logo and provide additional information for non multi shipments */}

                {!checkIfMultiCarriers() && (
                  <Row className="mx-0 my-4 p-0 gy-4">
                    <Col xs={12}>
                      <div className="">
                        <label htmlFor="package img" className="mb-0">
                          Upload Carrier Logo
                        </label>
                        <UploadImgField
                          handleInput={handleUploadLogo}
                          fileName={logoFile}
                          setFileName={setLogoFile}
                          imageUrl={carrierForm?.operator_details?.image_url}
                        />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="mb-2">
                        <label htmlFor="Description" className="mb-1">
                          Additional Information
                        </label>
                        <TextFieldBox
                          name="additional_info"
                          value={carrierForm.operator_details.additional_info}
                          onChange={handleOperatorInput}
                          placeholder="Enter additional information"
                        />
                      </div>
                    </Col>
                  </Row>
                )}

                <Col md={6}>
                  <div>
                    <label htmlFor="Description" className="mb-1">
                      Who should see this pricing?
                    </label>
                    <SelectBox
                      name="visibility_type"
                      value={carrierForm?.visibility_type}
                      options={visibility_options}
                      onChange={handleInput}
                      placeholder="Select options"
                    />
                  </div>
                </Col>

                <Col xs={6}>
                  <div>
                    <label htmlFor="Description" className="mb-1">
                      Which group is eligible to this carrier?
                    </label>
                    <MultiSelectBox
                      name="selected_shipping_options"
                      value={carrierForm?.groups}
                      options={customerGroups}
                      onChange={(value) => handleSelectCustomerGroup(value)}
                      placeholder="Select shipping plan"
                    />
                  </div>
                </Col>

                <Col md={12}>
                  <div>
                    <label htmlFor="Description" className="mb-1">
                      Allow manual shipment status update?
                    </label>
                    <SelectBox
                      name="manual_update"
                      value={carrierForm?.manual_update}
                      options={[
                        { label: "No", value: "No" },
                        { label: "Yes", value: "Yes" },
                      ]}
                      onChange={handleInput}
                      placeholder="Select options"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton onClick={onSubmit} type="submit" className="btn-primary">
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              Proceed
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateInterstateCarrierTypeDialog;
