import React, { useEffect, useState } from "react";
import GlobeIcon from "../../../../assets/settings/shipments/globe.svg";
import axios from "axios";
import { getAllZonesUrl } from "../../../../library/URLs";
import { UserProfileData } from "../../../../library/constants";
import styled from "styled-components";
import { TbPencil, TbTrash } from "react-icons/tb";
import { Tooltip } from "@mui/material";
import AddZoneDialog from "../../../../modals/AddZoneDialog";
import UpdateZoneDialog from "../../../../modals/UpdateZoneDialog";
import DeleteZoneDialog from "../../../../modals/DeleteZoneDialog";

const SubmitButton = styled.button`
  width: 250px;
`;

const Zones = () => {
  const [allZones, setAllZones] = useState({});
  const [showAddZone, setShowAddZone] = useState(false);
  const [showUpdateZone, setShowUpdateZone] = useState(null);
  const [showDeleteZone, setShowDeleteZone] = useState(null);
  // const [statusPending, setStatusPending] = useState(null);

  const fetchAllZones = async () => {
    await axios
      .post(getAllZonesUrl, UserProfileData())
      .then((res) => {
        if (res?.data?.success) {
          let all_zones = res?.data?.data;
          setAllZones(all_zones);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchAllZones();
    // eslint-disable-next-line
  }, []);

  const openShowAddZone = () => {
    setShowAddZone(true);
  };

  const closeShowAddZone = () => {
    setShowAddZone(false);
  };

  const openShowUpdateZone = (id) => {
    setShowUpdateZone(id);
  };

  const closeShowUpdateZone = () => {
    setShowUpdateZone(null);
  };

  const openShowDeleteZone = (id) => {
    setShowDeleteZone(id);
  };

  const closeShowDeleteZone = () => {
    setShowDeleteZone(null);
  };

  return (
    <div>
      <AddZoneDialog 
        isModalVisible={showAddZone}
        handleCloseDialog={closeShowAddZone}
        onSubmit={fetchAllZones}
      />

      <UpdateZoneDialog 
        isModalVisible={showUpdateZone !== null}
        handleCloseDialog={closeShowUpdateZone}
        onSubmit={fetchAllZones}
        zoneID={showUpdateZone}
      />

      <DeleteZoneDialog 
        isModalVisible={showDeleteZone !== null}
        handleCloseDialog={closeShowDeleteZone}
        onSubmit={fetchAllZones}
        zoneID={showDeleteZone}
      />

      <div className="settings_section d-flex flex-xl-row flex-column align-items-start">
        <div className="d-flex align-items-center justify-content-between price-settings-left">
          <div className="d-flex gap-3 align-items-start">
            <img src={GlobeIcon} alt="price" className="mt-2" />
            <div>
              <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>
                Shipment Zones
              </p>
              <span>Customize shipment zones for carrier rates.</span>
            </div>
          </div>
        </div>
        <div className=" price-settings-right">
          <div className=" col-12">
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>
                  Available Zones.
                </span>
              </div>
            </div>
            <div className="py-3">
              {allZones?.length > 0 ? (
                <ul className="list-zones-none ">
                  {allZones?.map((zone) => (
                    <li
                      key={zone?.id}
                      className="d-flex py-3 justify-content-between align-items-center"
                    >
                      <label className="form-check-label">{zone?.name}</label>
                      <div className="d-flex gap-4 align-items-center">
                        <Tooltip title="Update Zone" placement="top">
                          <span className="d-flex align-items-start">
                            <TbPencil
                              style={{ fontSize: 18, cursor: "pointer" }}
                              onClick={() => openShowUpdateZone(zone?.id)}
                            />
                          </span>
                        </Tooltip>
                        <Tooltip title="Delete Zone" placement="top">
                          <span className="d-flex align-items-start">
                            <TbTrash
                              style={{ fontSize: 18, cursor: "pointer" }}
                              onClick={() =>
                                openShowDeleteZone(zone.id)
                              }
                              className="text-danger"
                            />
                          </span>
                        </Tooltip>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="px-4">
                  <p>
                    No Zone available yet! Click the button below to add zone.
                  </p>
                </div>
              )}
            </div>
            <div className="py-4">
              <div className=" pt-4 d-flex justify-content-end">
                <SubmitButton
                  type="submit"
                  onClick={openShowAddZone}
                  className="confirm-button btn-primary"
                >
                  ADD ZONE
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Zones;
