import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { updateShipmentInfoUrl } from "../library/URLs";
import { useEffect, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { PhoneFieldBox, TextBox } from "../components/InputFields";

const SubmitButton = styled.button`
  width: 250px;
`;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const EditShipmentDetailsDialog = ({
    isModalVisible,
    handleCloseDialog,
    sender_name,
    sender_email,
    sender_phone,
    receiver_name,
    receiver_email,
    receiver_phone,
    shipment_number,
    sender_country,
    sender_state,
    sender_city,
    sender_postcode,
    receiver_country,
    receiver_state,
    receiver_city,
    receiver_postcode,
    sender_address_1,
    sender_address_2,
    receiver_address_1,
    receiver_address_2
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [shipmentInfo, setShipmentInfo] = useState({
        sender_name: "",
        sender_email: "",
        sender_phone: "",
        receiver_name: "",
        receiver_email: "",
        receiver_phone: "",
        sender_country: "",
        sender_state: "",
        sender_city: "",
        sender_postcode: "",
        receiver_country: "",
        receiver_state: "",
        receiver_city: "",
        receiver_postcode: "",
        // sender_address_1: "",
        // sender_address_2: "",
        // receiver_address_1: "",
        // receiver_address_2: ""
    });

    useEffect(() => {
        if(shipment_number) {
            setShipmentInfo({
                sender_name: sender_name,
                sender_email: sender_email,
                sender_phone: sender_phone,
                receiver_name: receiver_name,
                receiver_email: receiver_email,
                receiver_phone: receiver_phone,
                shipment_number: shipment_number,
                sender_country: toCapitalized(sender_country),
                sender_state: sender_state,
                sender_city: sender_city,
                sender_postcode: sender_postcode,
                recipient_country: receiver_country,
                recipient_state: receiver_state,
                recipient_city: receiver_city,
                recipient_postcode: receiver_postcode,
                // sender_address_1: sender_address_1,
                // sender_address_2: sender_address_2,
                // receiver_address_1: receiver_address_1,
                // receiver_address_2: receiver_address_2
            });
        }

        //eslint-disable-next-line
    }, [shipment_number])

    const [isError, setErrorVisibility] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [isSuccess, setSuccessVisibility] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const updateShipment = (e) => {
        e.preventDefault();
        const formValues = {
            ...shipmentInfo,
            recipient_country: toCapitalized(shipmentInfo.recipient_country),
            sender_country: toCapitalized(shipmentInfo.sender_country)
        };
        // formValues['customer_id'] = adminID;

        const newData = { ...formValues, ...UserProfileData() };

        setErrorVisibility(false);
        setIsSubmitting(true);

        axios
            .post(updateShipmentInfoUrl, newData)
            .then(function (response) {
                setIsSubmitting(false);
                if (response["data"]["success"] === true) {
                    setSuccessVisibility(true);
                    setSuccessMessage("Shipment updated successfully");

                    setTimeout(() => {
                        
                        handleCloseDialog();
                        window.location.reload();
                    }, 5000);
                } else {
                    setErrorMessage(response["data"]["message"]);
                    setErrorVisibility(true);

                    setTimeout(() => {
                        setErrorVisibility(false);
                    }, 5000);
                }
            })
            .catch(function (error) {
                setIsSubmitting(false);
                console.log(error);
                return error;
            });
    };

    const handleInput = (e) => {
        const { value, name } = e.target;

        setShipmentInfo({
            ...shipmentInfo,
            [name]: value,
        });
    };

    const toCapitalized = (str) => {
        if(str) {
            return str
            .toLowerCase() // Convert entire string to lowercase
            .split(' ') // Split the string into words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
            .join(' '); // Join the words back into a single string
        } else {
            return str;
        }
      }

    return (
        <Modal show={isModalVisible} size="lg" onHide={handleCloseDialog}>
            <Modal.Header>
                <h5 className="header">EDIT SHIPMENT INFO</h5>
                <BiX
                    style={{ fontSize: 25, cursor: "pointer" }}
                    onClick={handleCloseDialog}
                />
            </Modal.Header>
            <Modal.Body>
                {isError === false ? null : (
                    <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
                )}

                {isSuccess === false ? null : (
                    <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
                )}

                <p>Update your shipment information</p>
                <div className="query-result-container">
                    <Row className="gap-3 gap-md-0 my-3 gy-4">
                        <Col className=" col-12 col-md-6">
                            <label htmlFor="Description">Sender Name</label>
                            <TextBox
                                name="sender_name"
                                type="text"
                                className="form-control"
                                value={shipmentInfo.sender_name}
                                onChange={handleInput}
                            />
                        </Col>
                        <Col className=" col-12 col-md-6">
                            <label htmlFor="Description">Sender Email</label>
                            <TextBox
                                name="sender_email"
                                type="email"
                                className="form-control"
                                value={shipmentInfo.sender_email}
                                onChange={handleInput}
                            />
                        </Col>

                        <Col className=" col-12 col-md-6">
                            <label htmlFor="Description">Sender Phone Number</label>
                            <PhoneFieldBox
                                name="sender_phone"
                                type="tel"
                                className="form-control"
                                value={shipmentInfo.sender_phone}
                                onChange={handleInput}
                            />
                        </Col>

                        <Col className=" col-12 col-md-6">
                            <label htmlFor="Description">Sender Country</label>
                            <TextBox
                                name="sender_country"
                                type="text"
                                className="form-control"
                                value={shipmentInfo.sender_country}
                                onChange={handleInput}
                            />
                        </Col>

                        <Col className=" col-12 col-md-6">
                            <label htmlFor="Description">Sender State</label>
                            <TextBox
                                name="sender_state"
                                type="text"
                                className="form-control"
                                value={shipmentInfo.sender_state}
                                onChange={handleInput}
                            />
                        </Col>

                        <Col className=" col-12 col-md-6">
                            <label htmlFor="Description">Sender City</label>
                            <TextBox
                                name="sender_city"
                                type="text"
                                className="form-control"
                                value={shipmentInfo.sender_city}
                                onChange={handleInput}
                            />
                        </Col>

                        <Col className=" col-12 col-md-6">
                            <label htmlFor="Description">Sender Postcode</label>
                            <TextBox
                                name="sender_postcode"
                                type="text"
                                className="form-control"
                                value={shipmentInfo.sender_postcode}
                                onChange={handleInput}
                            />
                        </Col>

                        {/* <Col className=" col-12 col-md-12">
                            <label htmlFor="Description">Sender Address 1</label>
                            <TextBox
                                name="sender_address_1"
                                type="text"
                                className="form-control"
                                value={shipmentInfo.sender_address_1}
                                onChange={handleInput}
                            />
                        </Col>

                        <Col className=" col-12 col-md-12">
                            <label htmlFor="Description">Sender Address 2</label>
                            <TextBox
                                name="sender_address_2"
                                type="text"
                                className="form-control"
                                value={shipmentInfo.sender_address_2}
                                onChange={handleInput}
                            />
                        </Col> */}

                        <Col className=" col-12 col-md-6">
                            <label htmlFor="Description">Receiver Name</label>
                            <TextBox
                                name="receiver_name"
                                type="text"
                                className="form-control"
                                value={shipmentInfo.receiver_name}
                                onChange={handleInput}
                            />
                        </Col>

                        <Col className=" col-12 col-md-6">
                            <label htmlFor="Description">Receiver Email</label>
                            <TextBox
                                name="receiver_email"
                                type="email"
                                className="form-control"
                                value={shipmentInfo.receiver_email}
                                onChange={handleInput}
                            />
                        </Col>

                        <Col className=" col-12 col-md-6">
                            <label htmlFor="Description">Receiver Phone Number</label>
                            <PhoneFieldBox
                                name="receiver_phone"
                                type="tel"
                                className="form-control"
                                value={shipmentInfo.receiver_phone}
                                onChange={handleInput}
                            />
                        </Col>

                        <Col className=" col-12 col-md-6">
                            <label htmlFor="Description">Receiver Country</label>
                            <TextBox
                                name="recipient_country"
                                type="text"
                                className="form-control"
                                value={shipmentInfo.recipient_country}
                                onChange={handleInput}
                            />
                        </Col>

                        <Col className=" col-12 col-md-6">
                            <label htmlFor="Description">Receiver State</label>
                            <TextBox
                                name="recipient_state"
                                type="text"
                                className="form-control"
                                value={shipmentInfo.recipient_state}
                                onChange={handleInput}
                            />
                        </Col>

                        <Col className=" col-12 col-md-6">
                            <label htmlFor="Description">Receiver City</label>
                            <TextBox
                                name="recipient_city"
                                type="text"
                                className="form-control"
                                value={shipmentInfo.recipient_city}
                                onChange={handleInput}
                            />
                        </Col>

                        <Col className=" col-12 col-md-6">
                            <label htmlFor="Description">Receiver Postcode</label>
                            <TextBox
                                name="recipient_postcode"
                                type="text"
                                className="form-control"
                                value={shipmentInfo.recipient_postcode}
                                onChange={handleInput}
                            />
                        </Col>

                        {/* <Col className=" col-12 col-md-12">
                            <label htmlFor="Description">Receiver Address 1</label>
                            <TextBox
                                name="receiver_address_1"
                                type="text"
                                className="form-control"
                                value={shipmentInfo.receiver_address_1}
                                onChange={handleInput}
                            />
                        </Col>

                        <Col className=" col-12 col-md-12">
                            <label htmlFor="Description">Receiver Address 2</label>
                            <TextBox
                                name="receiver_address_2"
                                type="text"
                                className="form-control"
                                value={shipmentInfo.receiver_address_2}
                                onChange={handleInput}
                            />
                        </Col> */}
                    </Row>
                </div>
                <p className="mt-3">Would you like to continue?</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="cancel-button btn-error mx-3" onClick={handleCloseDialog}>
                    CANCEL
                </button>
                <SubmitButton
                    onClick={updateShipment}
                    type="submit"
                    className="confirm-button btn-primary"
                >
                    {isSubmitting === false ? (
                        <span className="button-text">Update Shipment Info</span>
                    ) : (
                        <span className="spinner-border spinner-grow-sm" />
                    )}
                </SubmitButton>
            </Modal.Footer>
        </Modal>
    );
};

export default EditShipmentDetailsDialog;
