import { Col, Modal, Row } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { SelectBox, TextBox, TextFieldBox } from "../components/InputFields";
import categories from "../files/categories.json";
import { BsX } from "react-icons/bs";
import { getCompanyInsuranceUrl, uploadPackageImgUrl } from "../library/URLs";
import { UserProfileData } from "../library/constants";
import axios from "axios";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const AddPackageItemDialog = ({
  isModalVisible,
  handleCloseDialog,
  onSubmit,
  shipment_type = "interstate",
  insurance,
  package_type
}) => {
  const initialData = {
    category: "",
    name: "",
    description: "",
    weight: "",
    quantity: "",
    value: "",
    value_currency: shipment_type === "international" ? "USD" : "NGN",
    hs_code: "",
    image_url: "",
  };

  const [formData, setFormData] = useState(initialData);
  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [uploading, setUploading] = useState(false);
  const [packageImg, setPackageImg] = useState("");
  const [companyInsurance, setCompanyInsurance] = useState();

  const fileInputRef = useRef(null);

  const handleDropdown = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    getCompanyInsurance();
  }, []);

  const getCompanyInsurance = () => {
    const data = {
      ...UserProfileData(),
    };

    axios
      .post(getCompanyInsuranceUrl, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setCompanyInsurance(response["data"]["data"]);
      })
      .catch(function (error) {
        return error;
      });
  };

  const uploadPackageImage = (file) => {
    setErrorVisibility(false);

    const imgFormData = new FormData();
    imgFormData.append("image", file);

    setUploading(true);

    axios
      .post(uploadPackageImgUrl, imgFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        setUploading(false);
        setFormData({
          ...formData,
          image_url: response?.data,
          image_name: file?.name,
        });
      })
      .catch(function (error) {
        return error;
      });
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleFileInput = (e) => {
    const { files } = e.target;
    setPackageImg(files[0]?.name);
    uploadPackageImage(files[0]);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    let formValues = {
      ...formData,
      [name]: value,
    };

    if (name === "name") {
      formValues["hs_code"] =
        categories?.find((cat) => cat?.value === value)?.hs_code ?? "0000";
    }

    setFormData(formValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      formData?.weight === "" ||
      formData?.value === "" ||
      formData?.description === "" ||
      formData?.quantity === ""
    ) {
      setErrorVisibility(true);
      setErrorMessage("Please ensure to fill all fields!");

      setTimeout(() => {
        setErrorVisibility(false);
      }, 5000);
      return;
    }

    if (
      (companyInsurance?.active_interstate === "Yes" &&
        shipment_type === "interstate") ||
      (companyInsurance?.active_international === "Yes" &&
        shipment_type === "international")
    ) {
      if (formData?.image_url === "") {
        setErrorVisibility(true);
        setErrorMessage("Please ensure to upload item image!");

        setTimeout(() => {
          setErrorVisibility(false);
        }, 5000);
        return;
      }
    }

    let update = {
      ...formData,
      value_currency:
        shipment_type === "interstate" ? "NGN" : formData.value_currency,
    };

    if(package_type === "Parcel") {
      update["category"] = formData?.name;
    } else if(package_type === "Document") {
      update["category"] = "Document";
      update["name"] = "Document";
      update["hs_code"] = "0000";
    }
    onSubmit(update);
    setFormData(initialData);
  };

  const currency_options = [
    {label: "USD", value: "USD"},
    {label: "NGN", value: "NGN"}
  ];

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">ADD NEW ITEM</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <Row className="create-shipment-info-form-row w-100 gy-4">
          {
            package_type === "Parcel" && (
              <Col sm={12} className="create-shipment-info-form-row-input">
                <label htmlFor="first_name">Category</label>
                <SelectBox
                  name="name"
                  value={formData.name}
                  onChange={handleInput}
                  placeholder="Select Category"
                  options={categories}
                />
              </Col>
            )
          }
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="first_name">Weight</label>
            <TextBox
              name="weight"
              value={formData.weight}
              onChange={handleInput}
              placeholder="Item Weight"
              prefix="KG"
            />
          </Col>
          <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
            <label htmlFor="first_name">Quantity</label>
            <TextBox
              name="quantity"
              value={formData.quantity}
              onChange={handleInput}
              placeholder="Item Quantity"
            />
          </Col>
          {shipment_type === "international" && (
            <Col 
              sm={4}
              lg={3} 
              className="create-shipment-info-form-row-input"
            >
              <label htmlFor="first_name">Currency</label>
              <SelectBox
                name="value_currency"
                value={formData.value_currency}
                options={currency_options}
                onChange={handleInput}
                placeholder="Select currency"
              />
            </Col>
          )}
          <Col
            sm={shipment_type === "international" ? package_type === "Document" ? 9 : 5 : 12}
            className="create-shipment-info-form-row-input"
          >
            <label htmlFor="first_name">Unit Value</label>
            <TextBox
              name="value"
              value={formData.value}
              onChange={handleInput}
              placeholder="Item Value"
              prefix={shipment_type === "international" ? "" : "NGN"}
            />
          </Col>
          {shipment_type === "international" && package_type === "Parcel" && (
            <Col sm={12} lg={4} className="create-shipment-info-form-row-input">
              <label htmlFor="hs_code">HS Code</label>
              <TextBox
                name="hs_code"
                value={formData.hs_code}
                onChange={handleInput}
                placeholder="Item HS Code"
              />
            </Col>
          )}
          <Col sm={12} className="create-shipment-info-form-row-input">
            <label htmlFor="first_name">Description</label>
            <TextFieldBox
              name="description"
              value={formData.description}
              onChange={handleInput}
              placeholder="Include a description of what you're shipping"
            />
          </Col>
          <Col sm={12}>
            <label htmlFor="package img">
              Upload Package Image{" "}
              {(companyInsurance?.active_interstate !== "Yes" &&
                shipment_type === "interstate") ||
              (companyInsurance?.active_international !== "Yes" &&
                shipment_type === "international")
                ? "(Optional)"
                : ""}
            </label>
            <div className="d-flex gap-2 align-items-center">
              {uploading && <span className="spinner-border spinner-grow-sm" />}
              <div className="w-100">
                <input
                  style={{
                    visibility: "hidden",
                    position: "fixed",
                    bottom: 0,
                    zIndex: -1,
                  }}
                  type="file"
                  onChange={handleFileInput}
                  ref={fileInputRef}
                  accept="image/*"
                  name=""
                  id=""
                />
                <div
                  style={{ marginTop: 20 }}
                  onClick={triggerFileInput}
                  className="change-password-button"
                >
                  Choose Image
                </div>
              </div>
            </div>
            {formData?.image_url !== "" && (
              <small>{formData?.image_name}</small>
            )}
          </Col>
        </Row>
        {
          shipment_type === "international" && (
            <p className="mt-3" >Note: Ensure all package item maintains the same currency.</p>
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton
          onClick={handleSubmit}
          type="submit"
          className="btn-primary"
        >
          Add
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPackageItemDialog;
