import { Col, Modal, Row } from "react-bootstrap";
import axios from "axios";
import {
  addCustomCityUrl,
  getAllCarrierCountriesUrl,
  getStates2Url,
} from "../library/URLs";
import { useEffect, useState } from "react";
import { UserProfileData } from "../library/constants";
import styled from "styled-components";
import { BiX } from "react-icons/bi";
import { SelectBox, TextBox } from "../components/InputFields";
import { BsX } from "react-icons/bs";
// import { toast } from "react-toastify";
import nigeriaStates from "../files/nigeriaStates.json";

const SubmitButton = styled.button``;

const ErrorMessageContainer = styled.div`
  color: #924040;
  background-color: #fde1e1;
  border-color: #fcd2d2;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const SuccessMessageContainer = styled.div`
  color: #1f7556;
  background-color: #d6f3e9;
  border-color: #c2eddd;
  padding: 5px 15px 5px 15px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid;
  margin-bottom: 20px;
  text-align: center;
`;

const AddCustomCityDialog = ({
  isModalVisible,
  handleCloseDialog,
  onSubmit
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    country: "",
    state: "",
    city: "",
  });
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [countriesPending, setCountriesPending] = useState(false);
  const [statesPending, setStatesPending] = useState(false);

  const [isError, setErrorVisibility] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isSuccess, setSuccessVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (isModalVisible) {
      fetchCountries();
    }
    // eslint-disable-next-line
  }, [isModalVisible]);

  useEffect(() => {
    if(formData.country) {
      fetchStates();
    }
    // eslint-disable-next-line
  }, [formData.country])

  const fetchCountries = async () => {
    const newData = { ...UserProfileData() };
    setCountriesPending(true);
    await axios
      .post(getAllCarrierCountriesUrl, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCountriesPending(false);
        if (response["data"]["success"] === false) return;

        let all_countries = response?.data?.data?.map((cntry) => {
          return {
            label: cntry?.country,
            value: cntry?.country,
            code: cntry?.iso_code,
          };
        });
        setCountries(all_countries);
      });
  };

  const fetchStates = async () => {
    const data = {
      country: formData.country,
    };

    const newData = { ...data, ...UserProfileData() };
    let country = formData.country;
    setStatesPending(true);
    await axios
      .post(getStates2Url, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setStatesPending(false);

        if (response["data"]["success"] === false) return;

        let states = [];
        if (country === "Nigeria") {
          states = nigeriaStates?.data?.map((state) => {
            return {
              label: state?.name,
              value: state?.name,
            };
          });
        } else {
          states = response?.data?.data?.map((state) => {
            return {
              label: state?.name,
              value: state?.name,
            };
          });
        }
        setStates(states);
      });
  };

  const addCustomCity = (e) => {
    e.preventDefault();
    const formValues = {
      ...formData,
    };
    // formValues['customer_id'] = adminID;

    const newData = {
      ...formValues,
      ...UserProfileData()
    };

    setErrorVisibility(false);
    setIsSubmitting(true);

    axios
      .post(addCustomCityUrl, newData)
      .then(function (response) {
        setIsSubmitting(false);
        if (response["data"]["success"] === true) {
          setSuccessVisibility(true);
          setSuccessMessage("City added successfully");

          setTimeout(() => {
            setFormData({
              country: "",
              state: "",
              city: "",
            });
            onSubmit();
            handleCloseDialog();
            setSuccessVisibility(false);
          }, 3000);
        } else {
          setErrorMessage(response["data"]["message"]);
          setErrorVisibility(true);

          setTimeout(() => {
            setErrorVisibility(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        setIsSubmitting(false);
        console.log(error);
        return error;
      });
  };

  const handleInput = (e) => {
    const { value, name } = e.target;

    // if(formData.zone_from !== "" || formData.zone_to !== "") {
    //   if(name === "zone_from") {
    //     if(formData.zone_from === formData.zone_to) {
    //         toast.error("Please select a different zone");
    //         return;
    //     }
    //   }

    //   if(name === "zone_to") {
    //       if(formData.zone_to === formData.zone_from) {
    //           toast.error("Please select a different zone");
    //           return;
    //       }
    //   }
    // }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Modal show={isModalVisible} onHide={handleCloseDialog}>
      <Modal.Header>
        <h5 className="header">ADD CUSTOM CITY</h5>
        <BiX
          style={{ fontSize: 25, cursor: "pointer" }}
          onClick={handleCloseDialog}
        />
      </Modal.Header>
      <Modal.Body>
        {isError === false ? null : (
          <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
        )}

        {isSuccess === false ? null : (
          <SuccessMessageContainer>{successMessage}</SuccessMessageContainer>
        )}

        <p>Fill the details below to set zone price.</p>
        <div className="query-result-container">
          <Row className="gap-3 gy-3 gap-md-0 mb-3">
            <Col md={6}>
              <div className="">
                <label htmlFor="Username" className="mb-2">
                  Country
                </label>
                <div className="d-flex gap-1">
                  {countriesPending && (
                    <span className="spinner-border spinner-grow-sm" />
                  )}
                  <SelectBox
                    name="country"
                    value={formData.country}
                    onChange={handleInput}
                    placeholder="Select available country"
                    options={countries}
                  />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <label htmlFor="Username" className="mb-2">
                State
              </label>
              <div className="d-flex gap-1">
                {statesPending && (
                  <span className="spinner-border spinner-grow-sm" />
                )}
                <SelectBox
                  name="state"
                  value={formData.state}
                  onChange={handleInput}
                  placeholder="Select available states"
                  options={states}
                />
              </div>
            </Col>

            <Col md={12}>
              <label htmlFor="Username" className="mb-2">
                City
              </label>
              <TextBox
                value={formData.city}
                name="city"
                onChange={handleInput}
                placeholder="Enter city"
              />
            </Col>
          </Row>
        </div>
        <p className="mt-3">Would you like to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn-secondary mx-3" onClick={handleCloseDialog}>
          <BsX />
          Cancel
        </button>
        <SubmitButton onClick={addCustomCity} type="submit" className="btn-primary">
          {isSubmitting === false ? (
            <span className="button-text d-flex align-items-center gap-2">
              Proceed
            </span>
          ) : (
            <span className="spinner-border spinner-grow-sm" />
          )}
        </SubmitButton>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCustomCityDialog;
