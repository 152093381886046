import React, { useEffect, useState } from "react";
import PickupIcon from "../../../assets/create-shipment/pickup.svg";
import TransporterIcon from "../../../assets/create-shipment/transport.svg";
import CarrierIcon from "../../../assets/create-shipment/carrier.svg";
import { SelectBox, SelectCustomerTextBox, TextBox } from "../../../components/InputFields";
import { Col, Row } from "react-bootstrap";
import { UserProfileData } from "../../../library/constants";
import axios from "axios";
import {
  calculateNewInterShipmentsUrl,
  getAdditionalServiceUrl,
  getPaymentMethodsUrl,
  getStationsURL,
} from "../../../library/URLs";
import { toast } from "react-toastify";
import { generateTransactionID } from "../../../library/utilities";
import { BsCheck, BsCheckCircleFill } from "react-icons/bs";
import { fetchAllPackageItems, fetchPackageTotalValue, fetchPackageTotalWeight } from "../Utilities";

const Payment = ({
  nextPage,
  previousPage,
  formData,
  setFormData,
  setCarriers,
  countries
}) => {
  const [stations, setStations] = useState([]);
  const [currentCarrier, setCurrentCarrier] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let packages = formData.package_type === "Parcel" ? formData?.packages : formData?.documents;

  const getStations = async () => {
    await axios
      .post(getStationsURL, UserProfileData())
      .then(function (response) {
        console.log(response);
        if (response["data"]["success"] === true) {
          const stations = response["data"]["data"]["data"].map((station) => {
            return {
              label: station?.address,
              value: station?.id,
            };
          });

          setStations(stations);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const getPaymentMethods = async () => {
    await axios
      .post(getPaymentMethodsUrl, UserProfileData())
      .then(function (response) {
        console.log(response);
        if (response["data"]["success"] === true) {
          const payments = [];
          if (response["data"]["data"]["cash"] === "Yes") {
            payments.push({ label: "Cash", value: "Cash" });
          }

          if (response["data"]["data"]["bank_transfer"] === "Yes") {
            payments.push({ label: "Bank Transfer", value: "Bank Transfer" });
          }

          if (response["data"]["data"]["card_payment"] === "Yes") {
            payments.push({ label: "Card Payment", value: "Card Payment" });
          }

          if (response["data"]["data"]["wallet"] === "Yes") {
            payments.push({ label: "Wallet", value: "Wallet" });
          }

          setPaymentMethods(payments);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const [additionalServices, setAdditionalServices] = useState([]);
  const getAdditionalServices = async () => {
    await axios
      .post(getAdditionalServiceUrl, UserProfileData())
      .then(function (response) {
        console.log(response);
        if (response["data"]["success"] === true) {
          const services = response["data"]["data"]?.filter(serv => serv.show_international === "Yes");

          setAdditionalServices(services);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  
  useEffect(() => {
    getStations();
    getPaymentMethods();
    getAdditionalServices();
    // eslint-disable-next-line
  }, []);

  const handlePayment = (e) => {
    const { name, value } = e.target;
    if(name === "payment_method" && value === "Wallet") {
      setFormData({
        ...formData,
        [name]: value,
        use_customer_account: "Yes"
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const validatePage = () => {
    if (formData.payment_method === "") {
      return true;
    } else if (formData.pickup_station === "") {
      return true;
    } else if(formData?.payment_method === "Wallet" && formData?.customer_wallet_id === "") {
      return true;
    }

    return false;
  };

  const navPrevious = (e) => {
    e.preventDefault();
    setCurrentCarrier(null);
    previousPage();
  };

  const toggleService = (serv) => {
    let add_serv_copy = [...formData?.additional_services];
    if (formData?.additional_services?.find((sv) => sv?.id === serv?.id)) {
      let filtered = add_serv_copy?.filter((sv) => sv?.id !== serv?.id);
      setFormData({
        ...formData,
        additional_services: filtered,
      });
    } else {
      add_serv_copy?.push(serv);
      setFormData({
        ...formData,
        additional_services: add_serv_copy,
      });
    }
  };

  // const handleSubmit = () => {
    
  //   nextPage();
  // };

  const calculateRates = async () => {
    let packageWeight = fetchPackageTotalWeight(packages);

    const data = {
      ...UserProfileData(),
      length: packages[0]?.package_length,
      height: packages[0]?.package_height,
      breadth: packages[0]?.package_width,
      weight: packageWeight,
      sender_country_code: countries?.find(ctry => ctry?.label === formData?.sender_details?.country)?.code,
      receiver_country_code: countries?.find(ctry => ctry?.label === formData?.receiver_details?.country)?.code,
      sender_address: formData?.sender_details?.address_1,
      sender_address_2: formData?.sender_details?.address_2,
      sender_city: formData?.sender_details?.city,
      sender_state: formData?.sender_details?.state,
      sender_postal_code: formData?.sender_details?.postal_code,
      sender_phone: formData?.sender_details?.phone,
      receiver_address: formData?.receiver_details?.address_1,
      receiver_address_2: formData?.receiver_details?.address_2,
      receiver_city: formData?.receiver_details?.city,
      receiver_county: formData?.receiver_details?.state,
      receiver_postal_code: formData?.receiver_details?.postal_code,
      receiver_phone: formData?.receiver_details?.phone,
      customer_wallet_id: formData?.customer_wallet_id,
      package_type: formData?.package_type,
      packages: fetchAllPackageItems(packages)
    };
    setIsLoading(true);

    await axios
      .post(calculateNewInterShipmentsUrl, data)
      .then(function (response) {
        setIsLoading(false);

        if (response["data"]["success"] === true) {
          setCarriers(response["data"]["data"]["carriers"]);

          if(stations.length > 0) {
            setFormData({
              ...formData,
              stations: stations
            })
          };

          nextPage();
        } else {
          toast.error(response["data"]["message"]);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const handleSuggestedCustomers = (name, value, customers) => {
    setFormData({
      ...formData,
      [name]: value,
      suggestedCustomers: customers
    });
  };

  return (
    <div>

      {additionalServices?.length > 0 && (
        <div className="create-shipment-grid">
          <div className="create-shipment-info">
            <img src={PickupIcon} alt="icon" />
            <div>
              <div className="create-shipment-info-top">
                <p className="create-shipment-info-title">
                  Additional Services
                </p>
                <p className="create-shipment-info-sub">
                  Which service would you like to use?
                </p>
              </div>
            </div>
          </div>
          <div className="create-shipment-info-form">
            <Row className="create-shipment-info-form-row">
              {additionalServices?.map((srv) => (
                <Col sm={12} md={6} className="service-card-wrap" key={srv?.id}>
                  <div
                    onClick={() => toggleService(srv)}
                    className="service-card"
                  >
                    {formData?.additional_services?.find(
                      (sv) => sv?.id === srv?.id
                    ) && <BsCheckCircleFill className="service-check" />}
                    <p>{srv?.service_name} </p>
                    <p>{srv?.description} </p>
                    <p>
                      &#8358; {parseFloat(srv?.fee)?.toLocaleString("en-US")}{" "}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      )}

      <div className="create-shipment-grid">
        <div className="create-shipment-info">
          <img src={PickupIcon} alt="icon" />
          <div>
            <div className="create-shipment-info-top">
              <p className="create-shipment-info-title">Booking Details</p>
              <p className="create-shipment-info-sub">
                What booking details would you prefer?
              </p>
            </div>
          </div>
        </div>
        <div className="create-shipment-info-form">
          <Row className="create-shipment-info-form-row">
            <Col sm={12} md={6} className="create-shipment-info-form-row-input">
              <label htmlFor="payment_method">Payment Method</label>
              <SelectBox
                name="payment_method"
                value={formData.payment_method}
                onChange={handlePayment}
                options={paymentMethods}
                placeholder="Select payment method"
              />
            </Col>

            <Col sm={12} md={6} className="create-shipment-info-form-row-input">
              <label htmlFor="use_customer_account">Are you booking for a customer?</label>
              <SelectBox
                name="use_customer_account"
                value={formData.use_customer_account}
                disabled={formData?.payment_method === "Wallet"}
                onChange={handlePayment}
                options={[{label: "No", value: "No"}, {label: "Yes", value: "Yes"}]}
                placeholder="Select option"
              />
            </Col>

            {
              (formData?.payment_method === "Wallet" || formData?.use_customer_account === "Yes") && (
                <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
                  <label htmlFor="pickup_station">Customer's Account</label>
                  <SelectCustomerTextBox
                    name="customer_wallet_id"
                    value={formData.customer_wallet_id}
                    onChange={handleSuggestedCustomers}
                    suggestions={formData?.suggestedCustomers}
                    placeholder="Select customer wallet account"
                  />
                </Col>
              )
            }
            
            <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
              <label htmlFor="pickup_station">Pickup Stations</label>
              <SelectBox
                name="pickup_station"
                value={formData.pickup_station}
                onChange={handlePayment}
                options={stations}
                placeholder="Select station"
              />
            </Col>
            <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
              <label htmlFor="payment_method">Pickup Date</label>
              <TextBox
                name="shipping_date"
                value={formData.shipping_date}
                onChange={handlePayment}
                placeholder="Enter pickup date"
                type="date"
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end gap-2">
            <button
              className="create-shipment-info-form-btn-secondary"
              onClick={navPrevious}
            >
              Previous
            </button>
            <button
              className="create-shipment-info-form-btn"
              onClick={calculateRates}
              disabled={validatePage()}
            >
              {
                isLoading ? (
                  <span className="spinner-border spinner-grow-sm" />
                ) : "Calculate Cost"
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
