import React, { useEffect, useState } from "react";
import GlobeIcon from "../../../../assets/settings/shipments/globe.svg";
import axios from "axios";
import { getCustomCitiesUrl } from "../../../../library/URLs";
import { UserProfileData } from "../../../../library/constants";
import styled from "styled-components";
import { TbTrash } from "react-icons/tb";
import { Tooltip } from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import AddCustomCityDialog from "../../../../modals/AddCustomCityDialog";
import DeleteCustomCityDialog from "../../../../modals/DeleteCustomCityDialog";

const SubmitButton = styled.button`
  width: 250px;
`;

const CustomCities = () => {
  const [allCustomCities, setAllCustomCities] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [openAddCity, setOpenAddCity] = useState(false);
  const [openDeleteCity, setOpenDeleteCity] = useState(null);

  const fetchAllCustomCities = async () => {
    setIsPending(true);
    await axios
      .post(getCustomCitiesUrl, {
        ...UserProfileData(),
      })
      .then((res) => {
        setIsPending(false);
        if (res?.data?.success) {
          let all_cities = res?.data?.data;
          setAllCustomCities(all_cities);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchAllCustomCities();
    // eslint-disable-next-line
  }, []);

  const handleOpenAddCity = () => {
    setOpenAddCity(true);
  };

  const handleCloseAddCity = () => {
    setOpenAddCity(false);
  };

  const handleOpenDeleteCity = (id) => {
    setOpenDeleteCity(id);
  };

  const handleCloseDeleteCity = () => {
    setOpenDeleteCity(null);
  };

  return (
    <div>
      <AddCustomCityDialog
        isModalVisible={openAddCity}
        handleCloseDialog={handleCloseAddCity}
        onSubmit={fetchAllCustomCities}
      />
      <DeleteCustomCityDialog
        isModalVisible={openDeleteCity !== null}
        handleCloseDialog={handleCloseDeleteCity}
        cityID={openDeleteCity}
        onSubmit={fetchAllCustomCities}
      />
      <div className="settings_section d-flex flex-xl-row flex-column align-items-start">
        <div className="d-flex align-items-center justify-content-between price-settings-left">
          <div className="d-flex gap-3 align-items-start">
            <img src={GlobeIcon} alt="price" className="mt-2" />
            <div>
              <p style={{ fontSize: 22, marginBottom: 8, fontWeight: "bold" }}>
                Custom Cities
              </p>
              <span>Customize your cities.</span>
            </div>
          </div>
        </div>
        <div className=" price-settings-right">
          <div className=" col-12">
            <div className="d-flex flex-row justify-content-between align-items-center py-3 px-4">
              <div className="d-flex flex-column">
                <span style={{ fontSize: 18, color: "#2C3E50" }}>
                  Custom Cities.
                </span>
              </div>
            </div>
            <div className="py-3">
              {isPending && (
                <div className="d-flex justify-content-center py-4">
                  <span className="spinner-border spinner-grow-sm" />
                </div>
              )}
              {!isPending && allCustomCities?.length > 0 ? (
                <table className="w-100 ">
                  <thead>
                    <tr>
                      <th>Country</th>
                      <th>State</th>
                      <th>City</th>
                      <th>
                        <BsThreeDotsVertical />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCustomCities?.map((city) => (
                      <tr key={city?.id} className="">
                        <td>{city.country}</td>
                        <td>{city.state}</td>
                        <td>{city.city}</td>
                        <td>
                          <div className="d-flex gap-4 align-items-center">
                            <Tooltip title="Delete City" placement="top">
                              <span className="d-flex align-items-start">
                                <TbTrash
                                  style={{ fontSize: 18, cursor: "pointer" }}
                                  onClick={() => handleOpenDeleteCity(city.id)}
                                  className="text-danger"
                                />
                              </span>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="px-4">
                  <p>No custom city available.</p>
                </div>
              )}
            </div>
            <div className="py-4">
              <div className=" pt-4 d-flex justify-content-end">
                <SubmitButton
                  type="submit"
                  onClick={handleOpenAddCity}
                  className="confirm-button btn-primary"
                >
                  ADD CITY
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomCities;
