import { useContext } from "react";
import App01 from "../assets/apps/App01.png";
import App02 from "../assets/apps/App02.png";
import App03 from "../assets/apps/App03.png";
import App04 from "../assets/apps/App04.png";
import App05 from "../assets/apps/App05.png";
import App06 from "../assets/apps/App06.png";
import { AppContext } from "../contexts";

export const ExternalAppsPopup = ({ open, setOpen }) => {
  const app_context = useContext(AppContext);
    const { openAiPanel } = app_context;

  return (
    <div>
      {open && (
        <div
          className="toggle-menu-overlay"
          onClick={() => setOpen(false)}
        ></div>
      )}
      <div
        className={`${
          open ? "external-apps-wrapper-open" : "external-apps-wrapper-closed"
        } external-apps-wrapper`}
      >
        <div className="external-apps-container-cover">
          <div className="external-apps-container">
            <div className="app-item">
              <div>
                <div className="d-flex justify-content-center">
                  <img src={App01} alt="App01" />
                </div>
                <p>App 01</p>
              </div>
            </div>

            <div className="app-item">
              <div>
                <div className="d-flex justify-content-center">
                  <img src={App02} alt="App02" />
                </div>
                <p>App 02</p>
              </div>
            </div>

            <div className="app-item">
              <div>
                <div className="d-flex justify-content-center">
                  <img src={App03} alt="App03" />
                </div>

                <p>App 03</p>
              </div>
            </div>

            <div className="app-item" onClick={openAiPanel} >
              <div>
                <div className="d-flex justify-content-center">
                  <img src={App04} alt="App04" />
                </div>

                <p>App 04</p>
              </div>
            </div>

            <div className="app-item">
              <div>
                <div className="d-flex justify-content-center">
                  <img src={App05} alt="App05" />
                </div>

                <p>App 05</p>
              </div>
            </div>

            <div className="app-item">
              <div>
                <div className="d-flex justify-content-center">
                  <img src={App06} alt="App06" />
                </div>

                <p>App 06</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
