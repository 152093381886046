import "../../../styles/home.scss";
import "../../../styles/reports.scss";
import Sidebar from "../../../components/Sidebar";
import Navbar from "../../../components/Navbar";
import * as React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserProfileData } from "../../../library/constants";
import { getTransactionsReportUrl } from "../../../library/URLs";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";
import dataIcon from "../../../images/no-data.png";
import * as ExcelJS from "exceljs";
import { SelectBox, TextBox } from "../../../components/InputFields";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import ExportIcon from "../../../assets/shipments/file_save.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import ReportIcon from "../../../assets/reports/ReportIcon.svg";
import { FilterDropdown } from "../../../components/Button";
import Contract from "../../../assets/customers/workspace_premium.svg";
import Regular from "../../../assets/customers/military_tech.svg";
import Individual from "../../../assets/customers/individual.svg";
import Business from "../../../assets/customers/user-group.svg";
import Logistics from "../../../assets/customers/truck.svg";
import { GoPrimitiveDot } from "react-icons/go";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const TransactionsReport = () => {
  const navigator = useNavigate();

  const [reportTime, setReportTime] = useState("");

  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRetrievingMore, setIsRetrievingMore] = useState(false);

  const [transactionTypeFilter, setTransactionTypeFilter] = useState([]);

  const [transactions, setTransactions] = useState([]);

  const [openExport, setOpenExport] = useState(null);
  const open = Boolean(openExport);
  const handleClickExport = (event) => {
    setOpenExport(event.currentTarget);
  };
  const handleClose = () => {
    setOpenExport(null);
  };

  const fetchTransactionReport = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const reportData = {
      ...UserProfileData(),
      // "type": "Today||Week||Month||Year||Custom",
      start_date: reportStartDate,
      end_date: reportEndDate,
      date_type: reportTime,
      transaction_type: transactionTypeFilter,
      mode: "web",
    };
    
    axios
      .post(getTransactionsReportUrl, reportData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setIsRetrievingMore(false);
        setIsLoading(false);

        if (response["data"]["success"] === false) return;

        const newTranasctions = response["data"]["data"];

        setIsOptionSelected(true);
        setTransactions(newTranasctions);


        // if (currentOffset === 0){
        //   setShipments(newShipments);
        // } else {
        //   setShipments([...shipments, ...newShipments]);
        // }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onChangeReportTime = (event) => {
    setReportTime(event.target.value);
  };

  const handleClick = (transactionID) => {
    navigator("/transaction/" + transactionID);
  };


  const exportToXlsx = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Report");
    sheet.properties.defaultRowHeight = 40;

    sheet.getRow(1).border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      // left: { style: "thick", color: { argb: "074B8A" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      right: { style: "thin", color: { argb: "074B8A" } },
    };

    sheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "008561" },
    };

    sheet.getRow(1).font = {
      // name: "Comic Sans MS",
      // family: 4,
      size: 16,
      bold: true,
      color: { argb: "000000" },
    };

    sheet.columns = [
      {
        header: "S/N",
        key: "sn",
        width: 5,
      },
      {
        header: "SENDER",
        key: "fullname",
        width: 40,
      },
      {
        header: "TRANSACTION TYPE",
        key: "type",
        width: 50,
      },
      {
        header: "DESCRIPTION",
        key: "description",
        width: 70,
      },
      {
        header: "AMOUNT",
        key: "amount",
        width: 40,
      },
      {
        header: "PAYMENT TYPE",
        key: "payment_type",
        width: 40,
      },
      {
        header: "DATE JOINED",
        key: "date_time",
        width: 60,
      },
    ];

    sheet.insertRow(1, [
      "",
      "",
      "",
      `
    TRANSACTIONS REPORT
    `,
    ]);
    const first_row = sheet.getRow(1);
    first_row.height = 120;
    first_row.font = {
      size: 18,
      bold: true,
      color: { argb: "074B8A" },
    };
    first_row.border = {
      // top: { style: "thick", color: { argb: "074B8A" } },
      left: { style: "thick", color: { argb: "FFFFFF" } },
      bottom: { style: "thick", color: { argb: "074B8A" } },
      // right: { style: "thick", color: { argb: "074B8A" } },
    };
    first_row.alignment = { horizontal: "center" };

    // const result = toDataURL(User?.company_logo_url);
    // const result = User?.company_logo_url;

    // worksheet.addImage(imageId2, {
    //   tl: { col: 1.5, row: 1.5 },
    //   br: { col: 3.5, row: 5.5 }
    // });
    transactions?.forEach((transaction, id) => {
      sheet.addRow({
        sn: id + 1,
        fullname: `${transaction.fullname ?? "N/A"}`,
        date_time: `${transaction?.date_time ?? "N/A"}`,
        type: `${transaction?.type ?? "N/A"}`,
        amount: `${transaction?.amount ?? "N/A"}`,
        description: `${transaction?.description ?? "N/A"}`,
        payment_type: `${transaction?.payment_type ?? "N/A"}`
      });
    });
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `Transactions_Report.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const exportToCSV = () => {
    if (transactions.length === 0) return;

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent +=
      "SENDER, TRANSACTION TYPE, DESCRIPTION, AMOUNT, PAYMENT TYPE, TIMESTAMP" +
      "\r\n";

    transactions.forEach(function (transaction) {

      let fullname = transaction.fullname ?? "";
      let transaction_type = transaction?.type ?? "";
      let description = transaction.description ?? "N/A";
      let amount = transaction.amount;
      let payment_type = transaction.payment_type;
      let date_time = transaction.date_time ?? "N/A";

      const row =
        fullname +
        "," +
        transaction_type +
        "," +
        description +
        "," +
        amount +
        "," +
        payment_type +
        "," +
        date_time;

      csvContent += row + "\r\n";
    });

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "transactions-report.csv");
    document.body.appendChild(link);

    link.click();
  };

  const [reportStartDate, setReportStartDate] = useState("");
  const [reportEndDate, setReportEndDate] = useState("");

  const date_options = [
    { label: "All Time", value: "All" },
    { label: "Today", value: "Today" },
    { label: "Yesterday", value: "Yesterday" },
    { label: "This Week", value: "Week" },
    { label: "This Month", value: "Month" },
    { label: "Custom Date", value: "Custom" },
  ];

  const shortenTexts = (text) => {
    if (text) {
      if (text?.length > 32) {
        return `${text?.slice(0, 32)}...`;
      } else {
        return text;
      }
    }
  };

  const transaction_options = [
    { label: "All", value: "All" },
    { label: "Credit", value: "Credit" },
    { label: "Debit", value: "Debit" }
  ];

  const handleTransactionsFilter = (value) => {
    let transaction_type_filter_copy = [...transactionTypeFilter];

    if(value === "All") {
      if(transactionTypeFilter.length < transaction_options.length) {
        transaction_type_filter_copy.push("All", "Credit", "Debit");
        setTransactionTypeFilter(transaction_type_filter_copy);
      } else {
        setTransactionTypeFilter([]);
      }
    } else {
      if(transactionTypeFilter.includes(value)) {
        let update_list = transaction_type_filter_copy.filter(val => val !== value && val !== "All");
        setTransactionTypeFilter(update_list);
      } else {
        transaction_type_filter_copy.push(value);
        if(transaction_type_filter_copy.length >= transaction_options.length - 1) {
          transaction_type_filter_copy.push("All");
        }
        setTransactionTypeFilter(transaction_type_filter_copy);
      }
    }
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <div className="container shipments-container">
            <div
              className="card border-0 p-4 min-vh-100"
              style={{ background: "#FFF" }}
            >
              <div className="d-flex w-100 justify-content-between align-items-center mb-4 ">
                <div className="d-flex align-items-center flex-row gap-2 ">
                  <img src={ReportIcon} alt="group" />
                  <h5 className="m-0">Transactions Report</h5>
                </div>
                <div className="d-flex flex-column flex-md-row gap-3"></div>
              </div>
              <div className="d-flex gap-3 mb-3 align-items-end flex-column flex-md-row justify-content-between">
                <div className="d-flex flex-column flex-md-row align-items-md-end gap-3">
                  <FilterDropdown 
                    filterOptions01={transaction_options}
                    handleSelection01={handleTransactionsFilter}
                    filterSelections01={transactionTypeFilter}
                  />

                  <SelectBox
                    name="pickup_detail_id"
                    onChange={onChangeReportTime}
                    options={date_options}
                    style={{ padding: "8px 20px", width: "190px" }}
                    value={reportTime}
                    placeholder="Select Date"
                  />

                  {reportTime === "Custom" && (
                    <div className="d-md-flex d-grid col gap-3">
                      <div>
                        <label className="form-label" htmlFor="startdate">
                          Start Date
                        </label>
                        <TextBox
                          type="date"
                          style={{ padding: "8px 20px" }}
                          value={reportStartDate}
                          onChange={(e) => setReportStartDate(e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="form-label" htmlFor="startdate">
                          End Date
                        </label>
                        <TextBox
                          type="date"
                          style={{ padding: "8px 20px" }}
                          value={reportEndDate}
                          onChange={(e) => setReportEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                  )}

                  <button
                    onClick={fetchTransactionReport}
                    className=" btn-primary"
                  >
                    {
                      isLoading ? (
                        <LoadingSpinner className="spinner-border spinner-grow-sm" />
                      ) : (
                        "Generate"
                      )
                      // (
                      //   <BiIcons.BiSearch style={{ fontSize: 20 }} />
                      // )
                    }
                  </button>
                </div>

                <div>
                  <button
                    className="feature-btn"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClickExport}
                  >
                    <img src={ExportIcon} alt="export" />
                    Export
                    {open ? <BsChevronUp /> : <BsChevronDown />}
                  </button>
                  <Menu
                    id="basic-menu"
                    anchorEl={openExport}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        exportToCSV();
                        handleClose();
                      }}
                      style={{ fontSize: 12 }}
                    >
                      Export as .csv
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: 12 }}
                      onClick={() => {
                        exportToXlsx();
                        handleClose();
                      }}
                    >
                      Export as .xlsx
                    </MenuItem>
                  </Menu>
                </div>
              </div>

              <div className="table-contain">
                <div
                  className=""
                  style={{
                    width: "100%",
                    overflowX: "auto",
                    overflowY: "hidden",
                  }}
                >
                  <table className="shipments-table w-100">
                    <thead>
                      <tr>
                      <th scope="col">Sender</th>
                      <th scope="col">Type</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Payment Type</th>
                      <th scope="col">Date</th>
                      </tr>
                    </thead>
                    {isLoading === true ? null : (
                      <tbody>
                        {transactions.map((transaction) => {
                          const dateTime = moment(transaction.date_time);
                          const timestamp =
                            dateTime.format("YYYY MMM DD, h:mm A");

                          const color =
                          transaction.payment_type === "Credit"
                            ? "green"
                            : "red";

                          return (
                            <tr
                              key={transaction.id}
                              // onClick={() => handleClick(shipment.id)}
                            >
                              <td
                                onClick={() => handleClick(transaction.id)}
                                // onContextMenu={() => openNewTab(shipment.id)}
                                className="p-0"
                              >
                                <Link
                                  to={`/transaction/${transaction.id}`}
                                  style={{
                                    padding: "20px 10px 20px 10px",
                                    color: "#475367",
                                  }}
                                >
                                  {transaction.fullname}
                                </Link>
                              </td>
                              <td className="p-0">
                                <Link
                                  to={`/transaction/${transaction.id}`}
                                  style={{
                                    padding: "20px 10px 20px 10px",
                                    color: "#475367",
                                  }}
                                >
                                  {transaction.type}
                                </Link>
                              </td>
                              <td
                                onClick={() => handleClick(transaction.id)}
                                // onContextMenu={() => openNewTab(shipment.id)}
                                className="p-0"
                              >
                                <Link
                                  to={`/transaction/${transaction.id}`}
                                  style={{
                                    padding: "20px 10px 20px 10px",
                                    color: "#475367",
                                  }}
                                >
                                  ₦
                                  {parseFloat(transaction.amount).toLocaleString(
                                    "en"
                                  )}
                                </Link>
                              </td>
                              <td className="p-0">
                                <Link
                                  to={`/transaction/${transaction.id}`}
                                  style={{
                                    padding: "20px 10px 20px 10px",
                                    color: "#475367",
                                  }}
                                >
                                  <span
                                    style={{ whiteSpace: "nowrap" }}
                                    className="d-flex align-items-center gap-1"
                                  >
                                    <span className="d-flex align-items-center gap-1">
                                                                    <GoPrimitiveDot style={{ color: color }} />
                                                                    {transaction.payment_type}
                                                                  </span>
                                  </span>
                                </Link>
                              </td>
                              <td className="p-0">
                                <Link
                                  to={`/transaction/${transaction.id}`}
                                  style={{
                                    padding: "20px 10px 20px 10px",
                                    color: "#475367",
                                  }}
                                >
                                  {timestamp}
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              {isRetrievingMore === false ||
              isLoading === true ? null : (
                <div style={{ textAlign: "center", marginTop: 40 }}>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />{" "}
                  Retrieving More..
                </div>
              )}
              {isOptionSelected ? null : (
                <LoadingContainer>
                  <div>
                    <img
                      style={{ width: 50, height: 50, marginBottom: 10 }}
                      src={dataIcon}
                      alt=""
                    />
                    <p>No option has been selected</p>
                  </div>
                </LoadingContainer>
              )}
              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsReport;
