import PaymentIcon from "../../../assets/create-shipment/payment-page.svg";
import { SelectBox, TextBox } from "../../../components/InputFields";
import { Col, Row } from "react-bootstrap";
import { SessionManagement } from "../../../library/SessionManagement";
import CarrierIcon from "../../../assets/create-shipment/carrier.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import { getTransporterByStateUrl } from "../../../library/URLs";
import { UserProfileData } from "../../../library/constants";

const Pricing = ({
  nextPage,
  previousPage,
  formData,
  setFormData,
  carriers,
  pricingMethod,
}) => {
  const companyLogoURL = SessionManagement.getCompanyLogoURL();
  const [transporters, setTransporters] = useState([]);

  useEffect(() => {
    getRiders();
    // eslint-disable-next-line
  }, [])

  const getRiders = async () => {
    await axios
      .post(getTransporterByStateUrl, {state: formData.shipment_state,...UserProfileData()})
      .then(function (response) {
        console.log(response);
        if (response["data"]["success"] === true) {
          const transporters = response["data"]["data"].map((transporter) => {
            return {
              label: transporter?.name,
              value: transporter?.id,
            };
          });

          setTransporters(transporters);
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const handlePayment = (e) => {
    const { name, value } = e.target;

    if (name === "payment_method" && value === "Wallet") {
      setFormData({
        ...formData,
        [name]: value,
        use_customer_account: "Yes",
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // const payment_options = [
  //   { label: "Cash On Delivery", value: "Cash On Delivery" },
  //   { label: "Cash On Pickup", value: "Cash On Pickup" },
  //   { label: "Card Payment", value: "Card Payment" },
  //   { label: "Direct Transfer", value: "Direct Transfer" },
  //   { label: "Wallet", value: "Wallet" },
  // ];

  const validatePage = () => {
    if (formData.payment_method === "") {
      return true;
    } else if(!formData?.amount) {
      return true
    }

    if (
      pricingMethod === "No" ||
      formData?.carrier?.creation_connection_type === "Self" ||
      formData?.carrier?.creation_connection_type === "Custom"
    ) {
      if (formData.transporter === "") {
        return true;
      }
    }

    return false;
  };

  const selectCarrier = (carrier) => {
    setFormData({
      ...formData,
      amount: carrier?.amount,
      shipment_plan: carrier?.name,
      carrier: carrier,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (transporters?.length > 0) {
      setFormData({
        ...formData,
        transporters: transporters
      });
    }

    nextPage();
  };

  return (
    <div>
      <div className="create-shipment-grid">
        <div className="create-shipment-info">
          <img src={PaymentIcon} alt="icon" />
          <div>
            <div className="create-shipment-info-top">
              <p className="create-shipment-info-title">Carriers</p>
              <p className="create-shipment-info-sub">
                Which carrier service would you like to use?
              </p>
            </div>
          </div>
        </div>
        <div className="create-shipment-info-form">
          {pricingMethod === "Yes" ? (
            <ul className="create-shipment-info-form-row carriers-list">
              {carriers?.map((carrier, id) => (
                <li key={id}>
                  <div className="carriers-list-option">
                    <div className="carriers-list-option-first-row">
                      <div className="carriers-list-option-logo">
                        <img src={companyLogoURL} alt="carrier" />
                      </div>
                      <div>
                        <p className="carriers-list-option-carrier-name">
                          {carrier?.name}{" "}
                        </p>
                        <p className="carriers-list-option-transit">
                          Transit Time:{" "}
                          <span className="carriers-list-option-transit-time">
                            {carrier?.transit_time}{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="carriers-list-option-price-label">PRICE</p>
                      <p className="carriers-list-option-price">
                        &#8358;{" "}
                        {parseFloat(carrier?.amount)?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                      </p>
                    </div>
                    <div className="d-flex justify-content-end align-items-center" >
                      <input
                        type="radio"
                        className=""
                        checked={carrier?.name === formData?.shipment_plan}
                        onChange={() => selectCarrier(carrier)}
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <Row className="create-shipment-info-form-row">
              <Col
                sm={12}
                md={6}
                className="create-shipment-info-form-row-input"
              >
                <label htmlFor="payment_method">Shipment Price</label>
                <TextBox
                  name="amount"
                  value={formData.amount}
                  onChange={handlePayment}
                  placeholder="Enter shipment price"
                />
              </Col>
            </Row>
          )}
          {
            pricingMethod === "No" ||
            formData?.carrier?.creation_connection_type === "Self" ||
            formData?.carrier?.creation_connection_type === "Custom" ? null : (
              <div className="d-flex justify-content-end gap-2">
                <button
                  className="create-shipment-info-form-btn-secondary"
                  onClick={previousPage}
                >
                  Previous
                </button>
                <button
                  className="create-shipment-info-form-btn"
                  onClick={handleSubmit}
                  disabled={validatePage()}
                >
                  Proceed
                </button>
              </div>
            )
          }
        </div>
      </div>

      {
        (pricingMethod === "No" ||
        formData?.carrier?.creation_connection_type === "Self" ||
        formData?.carrier?.creation_connection_type === "Custom") && (
          <div className="create-shipment-grid">
            <div className="create-shipment-info">
              <img src={CarrierIcon} alt="icon" />
              <div>
                <div className="create-shipment-info-top">
                  <p className="create-shipment-info-title">Transporter Details</p>
                  <p className="create-shipment-info-sub">
                    Who do you want to deliver your package?
                  </p>
                </div>
              </div>
            </div>
            <div className="create-shipment-info-form">
              <Row className="create-shipment-info-form-row">
                
                <Col sm={12} lg={6} className="create-shipment-info-form-row-input">
                  <label htmlFor="transporter">Transporter</label>
                  <SelectBox
                    name="transporter"
                    value={formData.transporter}
                    onChange={handlePayment}
                    options={transporters}
                    placeholder="Select transporter"
                  />
                </Col>
                
              </Row>
              <div className="d-flex justify-content-end gap-2">
                <button
                  className="create-shipment-info-form-btn-secondary"
                  onClick={previousPage}
                >
                  Previous
                </button>
                <button
                  className="create-shipment-info-form-btn"
                  onClick={handleSubmit}
                  disabled={validatePage()}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        )
      }
      
    </div>
  );
};

export default Pricing;
