import "../../styles/home.scss";
import "../../styles/shipments.scss";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/Navbar";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserProfileData } from "../../library/constants";
import {
  getTransactionsURL,
  getWhatsappTemplatesURL,
} from "../../library/URLs";
import axios from "axios";
import styled from "styled-components";
import * as React from "react";
import moment from "moment";
import * as BiIcons from "react-icons/bi";
import { GoPrimitiveDot } from "react-icons/go";
import CustomDateRangeDialog from "../../modals/CustomDateRangeDialog";
import SubscriptionInactive from "../../components/SubscriptionInactive";
import DeleteTemplateDialog from "../../modals/DeleteWhatsappDialog";
import {
  BsChevronLeft,
  BsChevronRight,
  BsPencil,
  BsThreeDotsVertical,
  BsTrash,
} from "react-icons/bs";
import CheckbookIcon from "../../assets/whatsapp/Checkbook.svg";
import Tooltip from "@mui/material/Tooltip";
import { shortenTexts } from "../../components/reusables";

const LoadingContainer = styled.div`
  height: 350px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = styled.span`
  height: 20px;
  width: 20px;
  margin: auto;
`;

const EditBtn = styled.button`
  width: 44px;
  height: 44px;
  border-radius: 100%;
  border: none;
  color: #98a2b3;
  font-size: 14px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #e3effc;
    color: #0d5eba;
  }
`;

const DeleteBtn = styled.button`
  width: 44px;
  height: 44px;
  border-radius: 100%;
  border: none;
  color: #98a2b3;
  font-size: 14px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #fbeae9;
    color: #cb1a14;
  }
`;

const WhatsappTemplates = () => {
  const navigator = useNavigate();
  const params = useParams();

  const [chosenPaymentStatus, setChosenPaymentStatus] = useState("");
  const [chosenDate, setChosenDate] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [isRetrievingMore, setIsRetrievingMore] = useState(false);
  const [isCustomDateRangeDialogVisible, setIsCustomDateRangeDialogVisible] =
    useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [isOptionSelected, setIsOptionSelected] = useState("");

  const [templates, setTemplates] = useState([]);

  const data = {
    limit: 20,
    offset: currentOffset,
    payment_status: chosenPaymentStatus,
    date: chosenDate,
  };

  const newData = { ...data, ...UserProfileData() };

  const FetchTemplates = () => {
    axios
      .post(getWhatsappTemplatesURL, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (isLoading === false && isRetrievingMore === false) {
          console.log("Should not retrieve");
          return;
        }
        console.log(response["data"]);
        setIsRetrievingMore(false);
        setIsLoading(false);

        const templates = response["data"]["data"];
        setTemplates(templates);
      });
  };
  useEffect(() => {
    FetchTemplates();
  }, [isRetrievingMore, isOptionSelected]);

  const handleClick = (transactionID) => {
    navigator("/transaction/" + transactionID);
  };

  const createTemplateClick = () => {
    navigator("/whatsapp/create-template");
  };

  const setScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      isRetrievingMore === false
    ) {
      setIsRetrievingMore(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", setScroll);
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  }, []);

  const exportToCSV = () => {
    if (templates.length === 0) return;

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent +=
      "Shipment ID,Sender Name,Sender Phone Number,Pickup Address,Receiver Name,Receiver Phone Number,Dropoff Address,Amount,Weight" +
      "\r\n";

    templates.forEach(function (shipment) {
      let id = shipment.id;

      let senderDetails =
        shipment.sender_name +
        "," +
        shipment.sender_phone_no +
        "," +
        shipment.pick_up_location.replaceAll(",", "");
      let receiverDetails =
        shipment.recipient_name +
        "," +
        shipment.recipient_phone_no +
        "," +
        shipment.drop_off_location.replaceAll(",", "");
      let packageDetails =
        shipment.standard_fee.replaceAll(",", "") + "," + shipment.weight;

      const row =
        id + "," + senderDetails + "," + receiverDetails + "," + packageDetails;

      csvContent += row + "\r\n";
    });

    const encodedURI = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedURI);
    link.setAttribute("download", "shipments1.csv");
    document.body.appendChild(link);

    link.click();
  };

  const onChangePaymentStatus = (event) => {
    setChosenPaymentStatus(event.target.value);
  };

  const toggleCustomDateRangeDialog = () => {
    setIsCustomDateRangeDialogVisible(!isCustomDateRangeDialogVisible);
  };

  const onChangeShipmentDate = (event) => {
    if (event.target.value === "custom") {
      toggleCustomDateRangeDialog();
    } else {
      setChosenDate(event.target.value);
    }
  };

  const getReports = () => {
    if (chosenPaymentStatus !== "" && chosenDate !== "") {
      setIsLoading(true);
      setCurrentOffset(0);
      setIsOptionSelected(chosenPaymentStatus + chosenDate);
    }
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="main">
        <Navbar />
        <div className="page pb-5">
          <SubscriptionInactive />

          <div className="container shipments-container">
            <div className="navigation-contain">
              <button className="back-btn">
                <BsChevronLeft />
              </button>
              <ul className="nav-options">
                <li>Whatsapp</li>
                <li className="nav-options-arrow">
                  <BsChevronRight />{" "}
                </li>
                <li className="nav-options-active">Whatsapp Templates</li>
              </ul>
            </div>
            <CustomDateRangeDialog
              isModalVisible={isCustomDateRangeDialogVisible}
              handleCloseDialog={(e) => {
                if (typeof e === "string") {
                  console.log("The chosen date is " + e.toString());
                  setChosenDate(e);
                }
                toggleCustomDateRangeDialog("close");
              }}
            />

            <div
              className="card border-0 p-4 min-vh-100"
              style={{ backgroundColor: "#FFF" }}
            >
              <div className="d-md-flex gap-2 mb-4 d-grid justify-content-between">
                <div className="d-flex gap-2 align-items-center">
                  <img src={CheckbookIcon} alt="checkbook" />
                  <p className="whatsapp-templates-title">Whatsapp Templates</p>
                </div>
                <button
                  className="btn-primary"
                  type="button"
                  onClick={createTemplateClick}
                >
                  CREATE NEW TEMPLATE
                </button>
              </div>

              <div className="table-contain w-100">
                <table className="shipments-table">
                  <thead>
                    <tr>
                      <th scope="col">Message</th>
                      <th scope="col">Name</th>
                      <th scope="col">Status</th>
                      <th scope="col">
                        <BsThreeDotsVertical />
                      </th>
                    </tr>
                  </thead>
                  {isLoading === true ? null : (
                    <tbody>
                      {templates.map((transaction) => {
                        const messageType = transaction.components[0].type;
                        let message = "";
                        if (messageType !== "BODY") {
                          message = transaction.components[1].text;
                        } else {
                          message = transaction.components[0].text;
                        }
                        return (
                          <tr key={transaction.name}>
                            <td>
                              <Tooltip title={`${message}`} placement="top">
                                <span className="whatsapp-templates-msg">
                                  {shortenTexts(message, 200)}
                                </span>
                              </Tooltip>
                            </td>
                            <td>{transaction.name}</td>
                            <td>{transaction.status}</td>
                            <td className="d-flex gap-1">
                              <EditBtn onClick={() => {}}>
                                <BsPencil />
                              </EditBtn>
                              <DeleteBtn
                                onClick={() =>
                                  setSelectedTemplate({
                                    id: transaction.id,
                                    name: transaction.name,
                                  })
                                }
                              >
                                <BsTrash />
                              </DeleteBtn>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
              {isRetrievingMore === false ? null : (
                <div style={{ textAlign: "center", marginTop: 40 }}>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />{" "}
                  Retrieving More..
                </div>
              )}

              {isLoading === false ? null : (
                <LoadingContainer>
                  <LoadingSpinner className="spinner-border spinner-grow-sm" />
                </LoadingContainer>
              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteTemplateDialog
        isModalVisible={selectedTemplate !== null}
        handleCloseDialog={() => setSelectedTemplate(null)}
        updateTemplate={() => {
          FetchTemplates();
          setIsRetrievingMore(true);
        }}
        templateID={selectedTemplate?.id}
        templateName={selectedTemplate?.name}
      />
    </div>
  );
};

export default WhatsappTemplates;
