import { useContext, useEffect, useState } from "react";
import { AppContext } from "../contexts";
import "../styles/ai.scss";
import { FaArrowUp } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import moment from "moment";
import { BsDot } from "react-icons/bs";
import { DotLoader } from "react-spinners";

const AIInterface = () => {
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isPending, setIsPending] = useState(false);
  const [text, setText] = useState("");
  const app_context = useContext(AppContext);
  const { openAi, closeAiPanel } = app_context;

  useEffect(() => {
    if (openAi) {
      const ws = new WebSocket(
        "wss://notifire-backend-d44601e5d45c.herokuapp.com/ws/chat/"
      );

      ws.onopen = () => {
        console.log("WebSocket connected!");
      };

      ws.onmessage = (event) => {
        if (event) {
          let response = extractChatbotResponse(event.data);
          if(response) {
            setIsPending(false);
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                user: "System AI",
                conversation: response,
              },
            ]);
          }
        }
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      ws.onclose = () => {
        console.log("WebSocket disconnected");
      };

      setSocket(ws);

      return () => {
        ws.close();
      };
    } else {
      if (socket) {
        socket.close();
        setSocket(null);
      }
    }
    // eslint-disable-next-line
  }, [openAi]);

  const handleInput = (e) => {
    const { value } = e.target;

    setText(value);
  };

  // const getRandomNumberBetween0And8 = () => {
  //   const randomNumber = Math.random();

  //   const multipliedNumber = randomNumber * 9;
  //   const integerNumber = Math.floor(multipliedNumber);

  //   return integerNumber;
  // };

  const submitText = (e) => {
    e.preventDefault();
    setIsPending(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        user: "Client",
        conversation: text,
      },
    ]);

    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(text);
      setText(""); // Clear input after sending
    } else {
      console.error("WebSocket is not connected");
    }
  };

  const extractChatbotResponse = (response) => {
    let refined_response = {};
    if (response) {
      refined_response = JSON.parse(response);
    }
    if (!refined_response?.status || !refined_response.messages) return "";

    return refined_response.messages
      .filter((msg) => msg.type === "text")
      .map((msg) => msg.content)
      .join("\n\n");
  };

  if (!openAi) return null;

  const handleCloseAiPanel = () => {
    closeAiPanel();
    socket.close();
    setSocket(null);
  };

  return (
    <div>
      {openAi && (
        <div className="toggle-menu-overlay" onClick={handleCloseAiPanel}></div>
      )}
      <div
        className={`${
          openAi ? "ai-wrapper-open" : "ai-wrapper-closed"
        } ai-wrapper`}
      >
        <div className=" h-100 position-relative">
          <button onClick={handleCloseAiPanel} className="ai-close-btn">
            <FiX />
          </button>
          <div className="messages-wrapper">
            <div className="messages-wrapper-body">
              {messages.length > 0 ? (
                <div className="ai-messages-wrapper">
                  <div className="h-100 position-relative">
                    <div className="ai-messages-list">
                      <ul className="ai-messages-list-msgs">
                        {messages.map((feature_msg, id) => {
                          let timestamp = moment(new Date()).format("HH:mm A");

                          if (feature_msg.user === "System AI") {
                            return (
                              <li key={id} className="ai-messages-system">
                                <div>
                                  <div className="ai-messages-info">
                                    <p className="ai-messages-info-user">
                                      System AI
                                    </p>
                                    <BsDot />
                                    <p className="ai-messages-info-timestamp">
                                      {timestamp}{" "}
                                    </p>
                                  </div>
                                  <div className="ai-messages-system-conversation">
                                    {feature_msg.conversation}
                                  </div>
                                </div>
                              </li>
                            );
                          } else {
                            return (
                              <li key={id} className="ai-messages-client">
                                <div className="w-50">
                                  <div className="ai-messages-info">
                                    <p className="ai-messages-info-user">
                                      Client
                                    </p>
                                    <BsDot />
                                    <p className="ai-messages-info-timestamp">
                                      {timestamp}{" "}
                                    </p>
                                  </div>
                                  <div className="ai-messages-client-conversation">
                                    {feature_msg.conversation}
                                  </div>
                                </div>
                              </li>
                            );
                          }
                        })}
                        {isPending && (
                          <li className="ai-messages-system">
                            <div>
                              <div className="ai-messages-system-conversation">
                                <DotLoader
                                  color="#667185"
                                  loading={isPending}
                                  size={36}
                                />
                              </div>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="ai-no-messages-wrapper">
                  <div className="ai-no-messages-container">
                    <h2 className="mb-3">Good Day, Bolu</h2>
                    <div className="ai-no-messages-text-contain">
                      <textarea
                        name="text"
                        value={text}
                        rows={4}
                        onChange={handleInput}
                        placeholder="How can I help you today?"
                        className="ai-no-messages-textarea"
                        id=""
                      ></textarea>
                      <button
                        className="ai-no-messages-btn"
                        onClick={submitText}
                      >
                        <FaArrowUp />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="ai-bottom-section">
            {messages.length > 0 && (
              <div className="ai-messages-textarea-container">
                <div className="ai-messages-textarea position-relative">
                  <textarea
                    name="text"
                    value={text}
                    rows={3}
                    onChange={handleInput}
                    placeholder="Reply to Parcelflow..."
                    className="ai-messages-textarea"
                    id=""
                  ></textarea>
                  <button
                    disabled={text === ""}
                    className="ai-messages-btn"
                    onClick={submitText}
                  >
                    <FaArrowUp />
                  </button>
                </div>
              </div>
            )}
            <div className="w-100 py-3 d-flex justify-content-center">
              <small className="text-center">Powered by Notifire</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIInterface;
